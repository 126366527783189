import React from 'react'

import '../css/buttons.css'


export default class DeleteButton extends React.Component{
    render(){
        return(
            <button type="button" className="btn btn-outline-dark btn-sm btn-delete" onClick={this.props.onBtnClick ? () => this.props.onBtnClick(): () => {}} >
                <i className="bi bi-trash"></i>
                &nbsp; Smazat
            </button>
        )
    }
}
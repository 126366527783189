import {backendRequest} from '../../backend'

export function apiLookupSpecies(callback){
    return backendRequest("GET", "/fish-admin/species/", callback, {})
}

export function apiLookupSpeciesDetail(callback, id){
    return backendRequest("GET", `/fish-admin/species/${id}/`, callback)
}

export function apiCreateSpecies(callback, data){
    return backendRequest("POST", "/fish-admin/species/", callback, data)
}

export function apiPatchSpecies(callback, id, data){
    return backendRequest("PATCH", `/fish-admin/species/${id}/`, callback, data)
}

export function apiDeleteSpecies(callback, id){
    return backendRequest("DELETE", `/fish-admin/species/${id}/`, callback, {})
}

export function apiDeleteMultipleSpecies(callback, data){
    return backendRequest("POST", '/fish-admin/species/delete/', callback, data)
}
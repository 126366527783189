import {CKEditorComponent} from './CKEditorComponent';
import {NewsListV2} from './NewsListV2'; 
import React from 'react';
import {apiNewsLookup, apiNewsPageCount, apiNewsPageLookup, apiNewsDelete, apiNewsUpdate} from './actions'
import {isUserAuth} from '../../auth'
import {PageComponent} from '../../paging/components/PageComponent'
import ModalDialog from '../../modal/components/ModalDialog'
import NewsEditModal from './NewsEditModal'

export default class NewsComponentV3 extends React.Component{

    constructor(props){
        super(props)

        this.newToDelte = null;
        this.newToModify = null;

        this.state = ({
            news: [],
            previousUrl: "",
            nextUrl: "",
            currentUrl: "",
            isAuth: false,
            pageCount: 1,
            pageNum: 1,
            showDeleteModal: false,
            showModifyModal: false,
        })

        this.loadNews = this.loadNews.bind(this)
        this.loadNewsUrl = this.loadNewsUrl.bind(this)
        this.handleNewsLoaded = this.handleNewsLoaded.bind(this)
        this.loadPages = this.loadPages.bind(this)
        this.loadAuth = this.loadAuth.bind(this)
        this.handleSaveCallback = this.handleSaveCallback.bind(this)
        this.deleteButtonClicked = this.deleteButtonClicked.bind(this)
        this.deleteModalOnOk = this.deleteModalOnOk.bind(this)
        this.modifyButtonClicked = this.modifyButtonClicked.bind(this)
        this.handleNextPage = this.handleNextPage.bind(this)
        this.handlePreviousPage = this.handlePreviousPage.bind(this)
        this.modifyConfirm = this.modifyConfirm.bind(this)
        this.modifyCancelled = this.modifyCancelled.bind(this)
    }

    componentDidMount(){
        this.loadAuth()
        this.loadPages()
        this.loadNews()
    }

    handleNewsLoaded(response){
        if(response){
            this.setState({
                news: response.results,
                previousUrl: response.previous,
                nextUrl: response.next
            })
        }     
        document.body.scrollIntoView()
    }

    loadNews(){
        if(this.state.currentUrl === ""){
            apiNewsLookup(this.handleNewsLoaded)
        }
        else{
            this.loadNewsUrl(this.state.currentUrl)
        }
    }

    loadNewsUrl(newsUrl){
        apiNewsPageLookup(this.handleNewsLoaded, newsUrl)
    }

    loadPages(){
        const pageCountRetrieved = (response) => {
            if(response){
                this.setState({
                    pageCount: response.count
                })
            }
        }

        apiNewsPageCount(pageCountRetrieved)
    }

    loadAuth(){
        const authInfoRetrieved = (response) => {
            if(response){
                this.setState({
                    isAuth: response.is_auth === 'yes'
                })
            }
        }    
        isUserAuth(authInfoRetrieved)
    }

    handleSaveCallback = (_response) => {
        this.loadPages()
        this.loadNews()
    }

    deleteButtonClicked = (newToDelete) => {
        this.newToDelte = newToDelete
        this.setState({
            showDeleteModal:true
        })
    }

    deleteModalOnOk = () => {
        this.setState({
            showDeleteModal: false
        })
        const handleNewDidDelete = (_response) => {
            this.loadPages()
            if(this.state.news.length === 1){
                this.handlePreviousPage()
            }else{
                this.loadNews()
            }
        }
        // perform deletion
        apiNewsDelete(handleNewDidDelete, this.newToDelte.id)
    }
    deleteModalHide = () => {
        this.setState({
            showDeleteModal: false
        })
    }

    modifyButtonClicked = (newToModify) => {
        this.newToModify = newToModify
        this.setState({
            showModifyModal:true
        })
    }

    handleNextPage(){
        this.setState({
            currentUrl:this.state.nextUrl,
            pageNum: this.state.pageNum + 1
        })
        this.loadNewsUrl(this.state.nextUrl)
    }

    handlePreviousPage(){
        this.setState({
            currentUrl:this.state.previousUrl,
            pageNum: this.state.pageNum - 1
        })
        this.loadNewsUrl(this.state.previousUrl)
    }

    modifyConfirm(newNewsText){
        // refresh page
        const handlemodifyDone = (_response) => {
           this.loadNews()
        }

        // update action
        apiNewsUpdate(handlemodifyDone, this.newToModify.id, newNewsText)

        // hide modal
        this.setState({
            showModifyModal:false
        })
    }

    modifyCancelled(){
        this.setState({
            showModifyModal:false
        })
    }

    render(){
        return (<div className="news-component">
        <CKEditorComponent 
            handleSaveCallback={this.handleSaveCallback} 
            isAuth={this.state.isAuth}/>
        <NewsListV2 
            news={this.state.news}
            onDelete={this.deleteButtonClicked}
            onModify={this.modifyButtonClicked} 
            isAuth={this.state.isAuth}/>
        <PageComponent 
            onNext={this.handleNextPage} 
            onPrevious={this.handlePreviousPage} 
            pageNum={this.state.pageNum} 
            pageCount={this.state.pageCount}/>
        <ModalDialog
            show={this.state.showDeleteModal}
            message="Opravdu smazat?"
            onBtn1={this.deleteModalOnOk}
            onBtn2={this.deleteModalHide}/>
        <NewsEditModal 
            show={this.state.showModifyModal}
            content={this.newToModify ? this.newToModify.content : ''}
            btn1Lbl="Uložit"
            btn1ClassName='btn btn-success'
            btn2Lbl="Zrušit"
            onBtn1={this.modifyConfirm}
            onBtn2={this.modifyCancelled}/>
    </div>)
    }
}
import React from 'react'
import { apiAnswerDelete } from '../actions'

import '../../css/questions.css'
import ModalDialog from '../../modal/components/ModalDialog'

export default class Answer extends React.Component{

    constructor(props){
        super(props)

        this.state = ({
            answerElement: props.answerElement,
            showDeleteModal: false
        })

        this.handleDeleteCancelled = this.handleDeleteCancelled.bind(this)
        this.handleDeleteConfirmed = this.handleDeleteConfirmed.bind(this)
        this.handleShowDeleteModal = this.handleShowDeleteModal.bind(this)
    }

    handleShowDeleteModal(){
        this.setState({
            showDeleteModal: true
        })
    }

    handleDeleteConfirmed(){
        if(this.props.isAuth === true){
            apiAnswerDelete(this.props.onDelete, this.state.answerElement.id)

            this.setState({
                showDeleteModal: false
            })
        }
    }

    handleDeleteCancelled(){
        if(this.props.isAuth === true){
            this.setState({
                showDeleteModal: false
            })
        }
    }

    render(){
        return(
            <div className="container">
                <div className="row answer-row">
                    <div className="d-flex justify-content-between">
                        <p><b>{this.state.answerElement.author_name + ' ' + this.state.answerElement.author_surname}</b>{' - ' + this.state.answerElement.date}</p>
                        <span/>
                        <div>
                            {this.state.answerElement.is_from_admin && <i className="bi bi-check-circle check-icon" title="Odpověď od správce."></i>}
                            {this.props.isAuth &&
                            <button type="button" className="trash-button margin-left-1" onClick={() => this.handleShowDeleteModal()}>
                                <i className="bi bi-trash" title="Smazat" ></i>
                            </button>
                            }
                        </div>
                    </div>
                    <hr/>
                    <p>{this.state.answerElement.content}</p>
                </div>
                <ModalDialog
                    show={this.state.showDeleteModal}
                    message="Smazat odpověď?"
                    onBtn1={this.handleDeleteConfirmed}
                    onBtn2={this.handleDeleteCancelled}
                    />
            </div>
        )
    }
}
export function monthNumToName(num){
    switch(num){
        case 1:
            return "Leden"
        case 2:
            return "Únor"
        case 3:
            return "Březen"
        case 4:
            return "Duben"
        case 5:
            return "Květen"
        case 6:
            return "Červen"
        case 7:
            return "Červenec"
        case 8:
            return "Srpen"
        case 9:
            return "Září"
        case 10:
            return "Říjen"
        case 11:
            return "Listopad"
        case 12:
            return "Prosinec"
        default:
            return "Neplatný měsíc"
    }
}

export function translateErrorMessage(errorMessage){
  
    let maxLengthStrRegex = 'Ensure this field has no more than \\d* characters.'
    if(errorMessage.match(maxLengthStrRegex)){
        const msgArray = errorMessage.split(' ')
        return `Maximální délka je ${msgArray[7]} znaků`
    }

    switch(errorMessage){
        case "This field may not be blank.":
            return "Toto pole nesmí být prázdné."
        case "This field is required.":
            return "Toto pole je povinné."
        case "Date has wrong format. Use one of these formats instead: YYYY-MM-DD.":
            return "Nesprávný formát."
        case "A valid integer is required.":
            return "Zadejte číslo."
        case "The fields person, year must make a unique set.":
            return "Sezoní informace pro tuto osobu a rok již existují."
        case "\"\" is not a valid choice.":
            return "Neplatná hodnota"
        case "The fields person, license_type, year must make a unique set.":
            return "Povolenka pro tento druh, rok a člena již existuje."
        case "fishing license with this Číslo kupónu already exists.":
            return "Povolenka s tímto číslem kupónu již existuje"
        case "Season summary region with this Povolenka and Revír already exists.":
            return "Tento revír je v aktuáním sumáři úlovků již uveden."
        case "The fields fishing_license, region must make a unique set.":
            return "Tento revír je v aktuáním sumáři úlovků již uveden."
        case "region - This field may not be null.":
            return "Pole revír je povinné."
        case "fishing_license - Invalid pk \"0\" - object does not exist.":
            return "Nesprávně zadaná povolenka!"
        case "This field may not be null.":
            return "Toto pole nesmí být prázdné"
        case "Unable to log in with provided credentials.":
            return "Nesprávné jméno, nebo heslo."
        case "New passwords doesn't match":
            return "Nová hesla nesouhlasí"
        case "This password is too short. It must contain at least 9 characters.":
            return "Heslo musí obsahovat alespoň 9 znaků"
        case "This password is too common.":
            return "Heslo je moc slabé"
        case "This password is entirely numeric.":
            return "Heslo nesmí obsahovt pouze čísla"
        case "species with this Druh already exists.":
            return "Tento druh již existuje"
        case 'Invalid pk "-1" - object does not exist.':
            return "Zadaná hodnota neexistuje"
        case "Not a human beeing!":
            return "Byl jste identifikován jako robot, opakujte akci později."
        default:
            return errorMessage
    }
}
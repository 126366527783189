import { backendRequest } from "../backend";

export function apiLookupRegionSummaryYears(regionId, callback){
    return backendRequest("GET", `/fish-admin/region-summary-years/?region=${regionId}`, callback,{})
}

export function apiLookupRegion(regionId, callback){
    return backendRequest("GET", `/regions/${regionId}/`, callback, {})
}

export function apiLookupRegionYearSummary(regionId, year, callback){
    return backendRequest("GET", `/fish-admin/region-year-summary/?year=${year}&region=${regionId}`, callback, {})
}

export function apiLookupAttendances(region, year, callback){
    return backendRequest("GET", `/fish-admin/region-year-attendances/?year=${year}&region=${region}`, callback, {})
}

import React from 'react'

import '../../css/albumphoto.css'


export default class AlbumPhoto extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            selected:false
        }
        this.imgClassName = "img-fluid img-thumbnail gallery-photo"
        this.imgSelectedClassName = "img-fluid img-thumbnail gallery-photo-selected"

        this.handleImgClick = this.handleImgClick.bind(this)
        this.resetState = this.resetState.bind(this)
        this.thumbnailHref = this.props.selectMode ? '#' : this.props.src
    }

    handleImgClick(e){
        if(this.props.selectMode === true){
            this.props.onClick(this)   
            this.setState({
                selected:!this.state.selected
            })
        }
    }

    resetState(){
        this.setState({
            selected:false
        })
    }

    render(){
        return (
            <div className="thumbnail">
                <a className="d-block mb-4 h-100" href={this.thumbnailHref}>
                <img className={this.state.selected ? this.imgSelectedClassName : this.imgClassName} src={this.props.src} alt="thumbnail" onClick={() => this.handleImgClick()}/>
                </a>
            </div>          
        )
    }

}
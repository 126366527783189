import React from "react";

import ModalDialog from '../../modal/components/ModalDialog'

export default class CollapsablePane extends React.Component{
    constructor(props){
        super(props)

        this.state = ({
            collapsed:this.props.collapsed,
            showDeleteModal:false,
        })
    }


    render(){
        return(
            <div className='collapsable-pane'>
                <div className='d-flex justify-content-between collapsable-header'>
                    <b className="collapse-header-label">{this.props.label ? this.props.label : ''}</b>
                    <span/>
                    <div>
                        {this.props.showDelete &&
                        <button 
                            className='btn btn-outline-danger person-btn collapsable-btn'
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    showDeleteModal:true
                                })
                            }}>
                            <i className="bi bi-trash"></i>
                        </button>}
                        <button
                            className="btn person-btn"
                            type="button"
                            onClick={(e) =>{
                                this.setState({
                                    collapsed:!this.state.collapsed
                                })
                            }}>
                            {this.state.collapsed && <i className="bi bi-chevron-down"></i>}
                            {!this.state.collapsed  && <i className="bi bi-chevron-up"></i>}
                        </button>
                    </div>
                </div>
                {!this.state.collapsed && <hr/>}
                {!this.state.collapsed &&
                <div className="collapsable-body">
                    {this.props.children}
                </div>}
                <ModalDialog
                    show={this.state.showDeleteModal}
                    message={this.props.deleteModalMessage}
                    subMessage={this.props.deleteModalSubMessage}
                    onBtn1={(e) => {
                        if(this.props.onDeleteCallback){
                            this.props.onDeleteCallback()
                        }
                        this.setState({
                            showDeleteModal:false
                        })
                    }}
                    onBtn2={(e) => {
                        this.setState({
                            showDeleteModal:false
                        })
                    }}
                />
            </div>
        )
    }
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import NewsComponentV3 from './news/components/NewsComponentV3'
import GalleryComponent from './photogallery/components/GalleryComponent';
import AlbumDetail from './photogallery/components/AlbumDetail';
import QuestionsComponent from './questions/components/QuestionsComponent';
import LicensesSellComponent from './licenses-sell/components/LicensesSellComponent';
import LicensesSellMaintainComponent from './licenses-sell/components/LicensesSellMaintainComponent';
import PersonDetailComponent from './fish_admin/person/PersonDetailComponent';
import PersonGridViewComponent from './fish_admin/person/PersonGridViewComponent';
import SeasonSummaryDetailComponent from './fish_admin/season_summary/SeasonSummaryDetailComponent';
import SeasonSummaryGridViewComponent from './fish_admin/season_summary/SeasonSummaryGridViewComponent';
import OfficerDetailComponent from './fish_admin/officers/OfficerDetailComponent';
import OfficersGridViewComponent from './fish_admin/officers/OfficersGridViewComponent';
import { SpieciesDetail } from './fish_admin/spiecies/SpieciesDetailComponent';
import SpeciesGridViewComponent from './fish_admin/spiecies/SpeciesGridViewComponent';
import { IssuingOrganizationDetail } from './fish_admin/issuing_org/IssuingOrgDetailComponent';
import IssuingOrganizationsGridViewComponent from './fish_admin/issuing_org/IssuingOrgGridViewComponent';
import RegionsGridViewComponent from './fish_admin/regions/RegionsGridViewComponent';
import { NewAlbum } from './photogallery/components/NewAlbum';
import { RegionYearSummary } from './regions/RegionYearSummary';
import QuestionAsk from './questions/components/QuestionAsk';

const appEl = document.getElementById('root')
if(appEl){
  ReactDOM.render(<App />, appEl);
}

const newsContainerElement = React.createElement
const newsContainer = document.getElementById('news-container')
if(newsContainer){
  ReactDOM.render(newsContainerElement(NewsComponentV3, newsContainer.dataset), newsContainer)
}

const galleryContainerElement = React.createElement
const galleryContainer = document.getElementById('gallery-container')
if(galleryContainer){
  ReactDOM.render(galleryContainerElement(GalleryComponent, galleryContainer.dataset), galleryContainer)
}

const albumContainerElement = React.createElement
const albumContainer = document.getElementById('album-container')
if(albumContainer){
  ReactDOM.render(albumContainerElement(AlbumDetail, albumContainer.dataset), albumContainer)
}

const questionsContainerElement = React.createElement
var questionsContainer = document.getElementById('questions-container')
if(questionsContainer){
  ReactDOM.render(questionsContainerElement(QuestionsComponent, questionsContainer.dataset), questionsContainer)
}

const licensesSellContainerElement = React.createElement
var licensesSellContainer = document.getElementById('licenses-sell-container')
if(licensesSellContainer){
  ReactDOM.render(licensesSellContainerElement(LicensesSellComponent, licensesSellContainer.dataset), licensesSellContainer)
}

const licensesSellMaintainContainerElement = React.createElement
var licensesSellMaintainContainer = document.getElementById('licenses-sell-maintain-container')
if(licensesSellMaintainContainer){
  ReactDOM.render(licensesSellMaintainContainerElement(LicensesSellMaintainComponent, licensesSellMaintainContainer.dataset), licensesSellMaintainContainer)
}

const personDetailContainerElement = React.createElement
var personDetailContainer = document.getElementById('person-detail-container')
if(personDetailContainer){
  ReactDOM.render(personDetailContainerElement(PersonDetailComponent, personDetailContainer.dataset), personDetailContainer)
}

const personGridViewContainerElement = React.createElement
const personGridViewContainer = document.getElementById('person-grid-view')
if(personGridViewContainer){
  ReactDOM.render(personGridViewContainerElement(PersonGridViewComponent, personGridViewContainer.dataset), personGridViewContainer)
}

const seasonSummaryContainerElement = React.createElement
const seasonSummaryContainer = document.getElementById('season_summary_detail')
if(seasonSummaryContainer){
  ReactDOM.render(seasonSummaryContainerElement(SeasonSummaryDetailComponent, seasonSummaryContainer.dataset), seasonSummaryContainer)
}

const seasonSummaryGridViewContainerElement = React.createElement
const seasonSummaryGridViewContainer = document.getElementById('season-summaries-grid-view')
if(seasonSummaryGridViewContainer){
  ReactDOM.render(seasonSummaryGridViewContainerElement(SeasonSummaryGridViewComponent, seasonSummaryGridViewContainer.dataset), seasonSummaryGridViewContainer)
}

const officerDetailContainerElement = React.createElement
const officerDetailContainer = document.getElementById('officer-detail')
if(officerDetailContainer){
  ReactDOM.render(officerDetailContainerElement(OfficerDetailComponent, officerDetailContainer.dataset), officerDetailContainer)
}

const officersGridViewElemet = React.createElement
const officersGridViewElementContrainer = document.getElementById('officers-list')
if(officersGridViewElementContrainer){
  ReactDOM.render(officersGridViewElemet(OfficersGridViewComponent, officersGridViewElementContrainer.dataset), officersGridViewElementContrainer)
}

const spieciesDetailElement = React.createElement
const spieciesDetailElementContainer = document.getElementById('spiecies-detail')
if(spieciesDetailElementContainer){
  ReactDOM.render(spieciesDetailElement(SpieciesDetail, spieciesDetailElementContainer.dataset), spieciesDetailElementContainer)
}

const speciesGridElement = React.createElement
const speciesGridElementContainer = document.getElementById('species-list')
if(speciesGridElementContainer){
  ReactDOM.render(speciesGridElement(SpeciesGridViewComponent, speciesGridElementContainer.dataset), speciesGridElementContainer)
}

const issuingOrgGridElement = React.createElement
const issuingOrgGridElementContainer = document.getElementById('issuing-org-list')
if(issuingOrgGridElementContainer){
  ReactDOM.render(issuingOrgGridElement(IssuingOrganizationsGridViewComponent, issuingOrgGridElementContainer.dataset), issuingOrgGridElementContainer)
}

const issuingOrgDetail = React.createElement
const issuingOrgDetailContainer = document.getElementById('issuing-org-detail')
if(issuingOrgDetailContainer){
  ReactDOM.render(issuingOrgDetail(IssuingOrganizationDetail, issuingOrgDetailContainer.dataset), issuingOrgDetailContainer)
}

const regionsList = React.createElement
const regionsListContainer = document.getElementById('regions-list')
if(regionsListContainer){
  ReactDOM.render(regionsList(RegionsGridViewComponent, regionsListContainer.dataset), regionsListContainer)
}

const newAlbum = React.createElement
const newAlbumContainer = document.getElementById('album-new')
if(newAlbumContainer){
  ReactDOM.render(newAlbum(NewAlbum, newAlbumContainer.dataset), newAlbumContainer)
}

const regionYearSummary = React.createElement
const regionYearSummaryContainer = document.getElementById('region-year-summary')
if(regionYearSummaryContainer){
  ReactDOM.render(regionYearSummary(RegionYearSummary, regionYearSummaryContainer.dataset), regionYearSummaryContainer)
}

const questionAsk = React.createElement
const questionAskContainer = document.getElementById('question-ask')
if(questionAskContainer){
  ReactDOM.render(questionAsk(QuestionAsk, questionAskContainer.dataset), questionAskContainer)
}





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

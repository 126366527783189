import React from 'react'
import DeleteButton from '../../buttons/DeleteButton'
import ModalDialog from '../../modal/components/ModalDialog'

export default class LicensesSellPricesCategoryComponent extends React.Component{

    constructor(props){
        super(props)
        
        this.sellPriceToDeleteId = 0

        this.state = ({
            showDeleteModal: false
        })

        this.deleteBtnClicked   = this.deleteBtnClicked.bind(this)
        this.deleteModalOk      = this.deleteModalOk.bind(this)
        this.deleteModalCancel  = this.deleteModalCancel.bind(this)
    }

    deleteBtnClicked(id){
        this.sellPriceToDeleteId = id
        this.setState({
            showDeleteModal:true
        })
    }

    deleteModalOk(){
        if(this.props.onDelete){
            this.props.onDelete(this.sellPriceToDeleteId)
        }

        this.setState({
            showDeleteModal:false
        })
    }

    deleteModalCancel(){
        this.setState({
            showDeleteModal:false
        })
    }


    render(){
        return(
            this.props.elements &&
            <div className="container mt-2 sell-container">
                {this.props.elements.map((sellPrice, _index) => (
                    <div className="container prices-container" key={sellPrice.key}>
                        <div className="d-flex justify-content-center prices-container">
                            <p className="lic-sell-cate">{sellPrice.format_age_category}</p>
                            <h5 className="lic-sell-price">{sellPrice.format_price}</h5>
                            {this.props.isUserAuth &&
                            <DeleteButton 
                                 onBtnClick={() => this.deleteBtnClicked(sellPrice.id)}
                                />
                            }
                        </div>  
                    </div>
                ))}
                <ModalDialog 
                    show={this.state.showDeleteModal}
                    message="Smazat cenu povolenky?"
                    onBtn1={this.deleteModalOk}
                    onBtn2={this.deleteModalCancel}
                    />
            </div>
        )
    }
}
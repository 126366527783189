import {backendRequest} from '../../backend/'

export function apiNewsCreate(newsData, callback){
    backendRequest("POST", '/news/create/', callback, newsData)
}

export function apiNewsLookup(callback)
{
    backendRequest("GET", '/news/', callback, {})
}

export function apiNewsPageLookup(callback, endpoint)
{
    backendRequest("GET", endpoint.replace('http://127.0.0.1:8000/api', ''), callback, {})
}

export function apiNewsPageCount(callback)
{
    backendRequest("GET", '/news/page_count/', callback, {})
}

export function apiNewsDelete(callback, news_id)
{
    backendRequest("DELETE", `/news/${news_id}/`, callback, {})
}

export function apiNewsUpdate(callback, news_id, content)
{
    backendRequest("PATCH", `/news/${news_id}/`, callback, {'id': news_id, 'content': content})
}
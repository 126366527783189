import React from "react";

import { translateErrorMessage } from "../../functions";

export default class DictErrorListComponent extends React.Component{
    constructor(props){
        super(props)

        this.state = ({
            labels: props.labels ? props.labels : {},
            errorDict: props.errorDict ? props.errorDict : {}
        })
    }

    render(){
        return(
            <div>
                {Object.keys(this.props.errorDict).length > 0 && 
                    <div className="container">
                        {Object.entries(this.props.errorDict).map(([key, errorList]) => (
                        <div className="row" key={`${key}-${errorList.length}`}>
                            <span>
                                {key in this.state.labels ? this.state.labels[key] : key}
                            </span>
                            <ul className="ul-no-bullets person-alert">
                                {errorList.map((errorMsg, _errorIdx) => (
                                    <li key={`${errorMsg}`}>
                                        {translateErrorMessage(errorMsg)}
                                    </li>
                                ))}
                            </ul>
                        </div> 
                        ))}
                    </div>}
            </div>
        )
    }
}
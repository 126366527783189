import GridViewComponent from "../../gridview/GridViewComponent"

import { apiLookupSpecies, apiDeleteMultipleSpecies } from "./actions";

export default class SpeciesGridViewComponent extends GridViewComponent{

    constructor(props){
        super(props)
        this.loadData = this.loadData.bind(this)
        this.dataDidLoad = this.dataDidLoad.bind(this)
    }

    componentDidMount(){
        this.loadData()
    }

    gridNewAction(){
        window.location.href = "/fish-admin/spiecies/create/"
    }

    gridRowHref(row){
        return `/fish-admin/spiecies/${row.id}/`
    }

    gridDeleteAction(){
        
        let dataDidDeleted = (response, status) => {
            if(status === 200){
                this.setState({
                    showDeleteModal:false
                })
                this.loadData()
            }else if(status === 400){
                this.setState({
                    showDeleteModal:false,
                    showDeleteErrorInfoBox:true,
                    deleteErrorInfoSubMessage:response.message
                })
            }else if(status === 500){
                alert("Něco se pokazilo, opakujte později.")
            }
        }
        
        var speciesIds = new Set()
        for(let idx of this.state.selectedDataIdxs){
            speciesIds.add(this.state.data[idx].id)
        }

        const speciesToDelete = [...speciesIds].join(",")

        apiDeleteMultipleSpecies(dataDidDeleted, speciesToDelete)
    }

    /**
     * callback used when season summaries data are retreived from server
     * @param {*} response - response from the serve
     * @param {*} status  - html status code
     */
    dataDidLoad(response, status){
        if(status === 200){
            this.state.selectedDataIdxs.clear()

            this.setState({
                data:response,
                columns: this.state.columns.length > 0 ? this.state.columns :[
                    {
                        "name": "name",
                        "label": "Název druhu",
                        "type": this.SEARCHTYPETEXT
                    },
                ],
                header:`Druhy ryb`,
                hrefColumn: this.state.hrefColumn ? this.state.hrefColumn : 'name',
                searchColumn:this.state.searchColumn === "" ? 0 : this.state.searchColumn,
            })
        }       
    }

    loadData(){
        apiLookupSpecies(this.dataDidLoad)
    }
}
import React, {Component} from 'react';
import ModalDialog from './modal/components/ModalDialog'

class App extends Component {

  state = {
    show:false,
    display: 'none'
  }

  showModal = () => {
      this.setState({
          show:true,
          display: 'block'
      })
  }

  onClose = () => {
    console.log("closing")
    this.setState({
        show:false
    })
  }

  render() {
    return (
      <div className='App'>
        <h1>Hello from app</h1>
        <ModalDialog 
          show={this.state.show} 
          onClose={this.onClose} 
          message='Opravdu smazat?'
          display={this.display}></ModalDialog>
        <button className='btn btn-primary' type='button' onClick={() => {this.showModal()}}>Show modal</button>
      </div>
    );
  }
}

export default App;
import React, { useEffect, useState } from 'react';
import DictErrorListComponent from "../../global/components/error_components/DictErrorListComponent";
import { apiAlbumCreateV2, apiAlbumLookup, apiAlbumPatch,apiAlbumDelete} from './actions';
import { apiLookupRegionsSimple } from './../../fish_admin/season_summary/actions';
import LookupComponent from '../../lookup/LookupComponent';
import ModalDialog from "../../modal/components/ModalDialog";

export function NewAlbum(props){
    const [albumId, setAlbumId] = useState(props.albumId)
    const [errors, setErrors] = useState({})
    const [regions, setRegions] = useState([])
    const [albumObj, setAlbumObj] = useState({'name':'', 'region':''})
    const [albumObjOriginal, setAlbumObjOriginal] = useState({})
    const [regionDefault, setRegionDefault] = useState({})
    const [modified, setModified] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [cancelCounter, setCancelCounter] = useState(1)

    useEffect(() => {
        lookupRegionsSimple()
        if(albumId){
            lookupAlbum()
        }
    }, [albumId])

    const lookupAlbum = () => {
        if(albumId){
            const albumDidLoaded = (response, status) => {
                if(status===200){
                    setAlbumId(response.id)
                    setAlbumObj(response)
                    setAlbumObjOriginal(JSON.parse(JSON.stringify(response)))
                    setRegionDefault({'id':response.region, 'value':response.region_obj_str})
                }
            }
            apiAlbumLookup(albumDidLoaded, albumId)
        }
    }

    const lookupRegionsSimple = () => {
        const regionsDidLoaded = (response, status) => {
            if(status===200){
                const responseToSimpleRegions = response.map((region, _id) => ({id:region.id, value:region.number_name}))
                setRegions(responseToSimpleRegions)
            }
        }
        apiLookupRegionsSimple(regionsDidLoaded)
    }

    const handleSave = () => {
        const albumDidCreated = (response, status) => {
            if(status===201){
                window.location.href = `/photogallery/albums/${response.id}/`
            }else{
                setErrors(response)
            }
        }

        const albumDidPatched = (response, status) => {
            if(status===200){
                setErrors({})
                setModified(false)
                setAlbumObj(response)
                setAlbumObjOriginal(JSON.parse(JSON.stringify(response)))
            }else{
                setErrors(response)
            }
        }

        if(albumId){
            apiAlbumPatch(albumDidPatched, albumId, albumObj)
        }else{
            apiAlbumCreateV2(albumDidCreated, albumObj)
        }  
    }

    const albumValueChanged = (fieldName, value) => {
        const currentAlbumObject = JSON.parse(JSON.stringify(albumObj))
        currentAlbumObject[fieldName] = value
        setAlbumObj(currentAlbumObject)
        setModified(true)
    }

    const handleRegionSelected = (id) => {
        albumValueChanged('region', id)
    }

    const handleCancel = () => {
        setAlbumObj(albumObjOriginal)
        setModified(false)
        setErrors({})
        setCancelCounter(cancelCounter + 1)
    }

    const handleDeleteAction = () => {
        const albumDidDeleted = (_response, status) => {
            if(status === 204){
                setAlbumId(null)
                setAlbumObj({})
                setModified(false)
                setAlbumObjOriginal({})
                window.location.href = '/photogallery/'    
            }else{
                alert("Něco se pokazilo, opakujte později")
            }
        }

        setShowDelete(false)
        apiAlbumDelete(albumDidDeleted, albumId)
    }

    return(
        <div className="container licenses-container-wrapper">
            {albumId && <div className='container mb-3'>
                <a href={`/photogallery/albums/${albumId}/`}>{"< album"}</a>
            </div>}
            <div className="d-flex justify-content-between mb-3">
                <span/>
                {!albumId && <h3>Nové album</h3>}
                {albumId && <h3>Album</h3>}
                <div className="person-header-btn-group">
                    <div className='btn-group'>
                        {albumId && 
                        <button type='button' className='btn btn-outline-danger person-btn'
                            onClick={(_e)=>(setShowDelete(true))}>Smazat</button>}
                        {modified &&
                        <button type='button' className='btn btn-outline-danger person-btn'
                            onClick={(_e)=>(handleCancel())}>Zrušit</button>}
                        {(modified || (modified && !albumId)) &&
                        <button type='button' className='btn btn-outline-dark person-btn'
                            onClick={(_e)=>(handleSave())}>Uložit</button>}
                    </div>
                </div>
            </div>

            <form method="POST">
                <div className="container officers-form">
                    <div className="mb-3">
                        <b>Název</b>
                        <input type="text" className="form-control"
                            value={albumObj.name ? albumObj.name : ''}
                            onChange={(e) => (albumValueChanged('name', e.target.value))}/>
                    </div>

                    <div className="mb-3">
                        <b>Revír</b> - nepovinné (pokud vyplníš zobrazí se album na stránce revíru)
                        <LookupComponent
                            defaultSelected={regionDefault}
                            key={`${regions.length}-${regionDefault.id}-${cancelCounter}`}
                            data={regions}
                            onSelectCallback={handleRegionSelected}
                            />
                    </div>

                    <DictErrorListComponent
                        labels={{
                            'name': "Název",
                            'region': "Revír",
                            "__all__": "Obecné",
                            "non_field_errors": "Obecné",
                        }}
                        errorDict={errors}/>
                </div>
            </form>

            <ModalDialog
                show={showDelete}
                message="Smazat"
                subMessage="Opravdu chcete smazat toto album?"
                onBtn1={handleDeleteAction}
                onBtn2={() => {
                    setShowDelete(false)
                }}
                />
        </div>
    )

}


function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
  
export function backendRequest(method,
    endpoint,
    callback,
    data) {
    let jsonData;
    if (data){
        jsonData = JSON.stringify(data)
    }
    
    const xhr = new XMLHttpRequest()
    const url = `https://mocrs-klasterec.cz/api${endpoint}`
    xhr.responseType = "json"
    const csrftoken = getCookie('csrftoken');
    xhr.open(method, url)
    xhr.setRequestHeader("Content-Type", "application/json")
    if (csrftoken){
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")
        xhr.setRequestHeader("X-CSRFToken", csrftoken)
    }
    
    xhr.onload = function() {
        if (xhr.status === 403) {
            const detail = xhr.response.detail
            if(detail === "Authentication credentials were not provided.")
            {
                window.location.href = "/login?showLoginRequired=true"
            }
        }
        callback(xhr.response, xhr.status)
    }
    
    xhr.onerror = function (e) {
        callback({"message": "The request was an error"}, 400)
    }

    xhr.send(jsonData)
}

export function backendRequestFormData(method, endpoint, callback, data) {
    let jsonData = data;
    const xhr = new XMLHttpRequest()
    const url = `https://mocrs-klasterec.cz/api${endpoint}`
    xhr.responseType = "json"
    const csrftoken = getCookie('csrftoken');
    xhr.open(method, url)
    if (csrftoken){
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")
        xhr.setRequestHeader("X-CSRFToken", csrftoken)
    }
    
    xhr.onload = function() {
        if (xhr.status === 403) {
            const detail = xhr.response.detail
            if(detail === "Authentication credentials were not provided.")
            {
                window.location.href = "/login?showLoginRequired=true"
            }
        }
        callback(xhr.response, xhr.status)
    }
    
    xhr.onerror = function (e) {
        callback({"message": "The request was an error"}, 400)
    }

    xhr.send(jsonData)
}
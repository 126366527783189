import GridViewComponent from "../../gridview/GridViewComponent"

import {
    apiLookupSeasonSummariesForYear,
    apiDeleteFishingLicenseSeasonSummaries,
} from './actions'

export default class SeasonSummaryGridViewComponent extends GridViewComponent{

    constructor(props){
        super(props)
        this.loadData = this.loadData.bind(this)
        this.dataDidLoad = this.dataDidLoad.bind(this)
    }

    componentDidMount(){
        this.loadData()
    }

    yearModifiedAction(newYearValue){
        // redirect to the correct year
        window.location.href = `/fish-admin/season-summaries/year/${newYearValue}/`
    }

    gridNewAction(){
        window.location.href = "/fish-admin/season-summaries/create/"
    }

    gridRowHref(row){
        return `/fish-admin/season-summaries/${row.id}/`
    }

    gridDeleteAction(){
        let dataDidDeleted = (response, status) => {
            if(status === 200){
                this.loadData()
            }else if(status === 400){
                alert(response.message)
            }else if(status === 500){
                alert("Něco se pokazilo, opakujte později.")
            }
        }
        
        var licenseIds = new Set()
        for(let idx of this.state.selectedDataIdxs){
            licenseIds.add(this.state.data[idx].id)
        }

        const fishingLicenseSummariesToDelete = [...licenseIds].join(",")

        apiDeleteFishingLicenseSeasonSummaries(dataDidDeleted, fishingLicenseSummariesToDelete)
    }

    /**
     * callback used when season summaries data are retreived from server
     * @param {*} response - response from the serve
     * @param {*} status  - html status code
     */
    dataDidLoad(response, status){
        if(status === 200){
            this.state.selectedDataIdxs.clear()

            this.setState({
                data:response,
                columns: this.state.columns.length > 0 ? this.state.columns :[
                    {
                        "name": "license_number",
                        "label": "Číslo povolenky",
                        "type": this.SEARCHTYPETEXT
                    },
                    {
                        "name": "license_holder",
                        "label": "Držitel",
                        "type": this.SEARCHTYPETEXT
                    },
                    {
                        "name": "universal_str",
                        "label": "Celosvazová",
                        "type": this.SEARCHTYPETEXT
                    },
                    {
                        "name": "year",
                        "label": "Rok",
                        "type": this.SEARCHTYPEINT
                    },
                ],
                yearSpecific:true,
                header:`Sumáře úlovků pro rok ${this.state.year}`,
                hrefColumn: this.state.hrefColumn ? this.state.hrefColumn : 'license_number',
                searchColumn:this.state.searchColumn === "" ? 0 : this.state.searchColumn,
            })
        }       
    }

    loadData(){
        apiLookupSeasonSummariesForYear(this.dataDidLoad, this.state.year)
    }

}
import React from 'react'
import {monthNumToName} from '../../global/functions'
import ModalDialog from '../../modal/components/ModalDialog'
import {apiLicenseSellDateDelete} from '../actions'
import DeleteButton from '../../buttons/DeleteButton'
 
import '../../css/licenses-sell.css'

export default class LicensesSellDatesComponent extends React.Component{

    constructor(props){
        super(props)
        this.sellDates = this.props.sellDates   
        this.sellDateIdToDelete = 0

        this.state = ({
            showDeleteModal:false 
        })

        this.deleteSellDateClicked = this.deleteSellDateClicked.bind(this)
        this.deleteSellDateConfirmed = this.deleteSellDateConfirmed.bind(this)
        this.deleteSellDateCancelled = this.deleteSellDateCancelled.bind(this)
    }
    

    deleteSellDateClicked(id){
        this.sellDateIdToDelete = id
        this.setState({
            showDeleteModal: true
        })
    }

    deleteSellDateConfirmed(){    
        this.setState({
            showDeleteModal: false
        })
        apiLicenseSellDateDelete(this.props.refreshSellDatesCallback, this.sellDateIdToDelete)
    }

    deleteSellDateCancelled(){
        this.setState({
            showDeleteModal: false
        })
    }

    render(){
        return(
            <div className="col-lg-3 col-md-4 col-6 pt-5">
                <div className="d-flex justify-content-left">
                    <h4>{`${monthNumToName(parseInt(this.props.sellMonth))}`}</h4>
                </div>
                {this.sellDates.map((selldate, index) =>(
                <div className="container justify-content-left py-2" key={`${index}-${selldate.sell_day}-${selldate.from_time}-${selldate.to_time}`}>
                    <div className="row">
                        <div className="container">
                            <div className="d-flex flex-row">
                                <h5 className="sell-date">{selldate.day + '.' + selldate.month}</h5>
                                {this.props.isUserAuth && 
                                    <div className="container">
                                        <DeleteButton onBtnClick={() => this.deleteSellDateClicked(selldate.id)} />
                                    </div>
                                }
                            </div>
                        </div>                
                        <p className="list-subtitle">{selldate.format_from_time + ' - ' + selldate.format_to_time}</p>   
                    </div>
                </div>
                ))}   
                <ModalDialog 
                    message="Smazat?"
                    subMessage="Opravdu chcete smazat termín prodeje?"
                    show={this.state.showDeleteModal}
                    onBtn1={this.deleteSellDateConfirmed}
                    onBtn2={this.deleteSellDateCancelled}
                />         
            </div>
        )
    }


}
import React from "react"
import '../../css/modal.css'

export default class ModalDialog extends React.Component {

    constructor(props){
        super(props)
        this.formData = new FormData()
        this.data = {
            "files": []
        }
        this.getFiles = this.getFiles.bind(this)
    }

    getFiles(e){
        const files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            this.formData.append(`images[${i}]`, files[i])
        }
    }

    submit(){
        const data = this.formData
        this.formData = new FormData()
        this.props.onBtn1(data)
    }

    render() {
        if(!this.props.show){
            return null
        }else{
            return (
                <div className="my-modal">
                    <div className="modal-content-small">
                        <div className='d-flex justify-content-center'>
                            <h3>{this.props.message}</h3>
                        </div>
                        <div className="d-flex justify-content-center'">
                            <input type="file" className="form-control" onChange={this.getFiles} accept=".jpg,.png,.jpeg" multiple/>               
                        </div>
                        <div className='d-flex justify-content-center py-2'>
                            <div className="btn-group" role="group">
                                <button 
                                    className= {this.props.btn1ClassName ? this.props.btn1ClassName : 'btn btn-danger'}
                                    type='button'
                                    onClick={() => {this.submit()}}>{this.props.btn1Lbl ? this.props.btn1Lbl : 'Ano'}</button>
                                <button 
                                    className={this.props.btn2ClassName ? this.props.btn2ClassName : 'btn btn-outline-secondary'}
                                    type='button' 
                                    onClick={() => {this.props.onBtn2()}}>{this.props.btn2Lbl ? this.props.btn2Lbl : 'Ne'}</button>
                            </div>
                        </div>
                    </div>                    
              </div>)
        }
    }
}
import {backendRequest} from '../../backend'

export function apiLookupPersonDetail(callback, personId){
    return backendRequest("GET", `/fish-admin/persons/${personId}/`, callback, {})
}

export function apiLookupPersons(callback){
    return backendRequest("GET", '/fish-admin/persons/', callback, {})
}

export function apiPatchPersonDetail(callback, personId, data){
    return backendRequest("PATCH", `/fish-admin/persons/${personId}/`, callback, data)
}

export function apiCreatePersonDetail(callback, data){
    return backendRequest("POST", '/fish-admin/persons/', callback, data)
}

export function apiDeletePersonDetail(callback, personId){
    return backendRequest("DELETE", `/fish-admin/persons/${personId}/`, callback, {})
}

export function apiDeletePersons(callback, data){
    return backendRequest("POST", '/fish-admin/persons/delete-multiple/', callback, data)
}

export function apiZtpCreate(callback, data){
    return backendRequest("POST", `/fish-admin/ztp-numbers/`, callback, data)
}

export function apiZtpPatch(callback, ztpId, data){
    return backendRequest("PATCH", `/fish-admin/ztp-numbers/${ztpId}/`, callback, data)
}

export function apiZtpDelete(callback, ztpId){
    return backendRequest("DELETE", `/fish-admin/ztp-numbers/${ztpId}/`, callback, {})
}

export function apiLookupIssuingOrganizations(callback){
    return backendRequest("GET", '/fish-admin/issuing-organizations/', callback, {})
}

export function apiCreateFishingTicket(callback, data){
    return backendRequest("POST", '/fish-admin/fishing-tickets/', callback, data)
}

export function apiPatchFishingTicket(callback, id, data){
    return backendRequest("PATCH", `/fish-admin/fishing-tickets/${id}/`, callback, data)
}

export function apiDeleteFishingTicket(callback, id){
    return backendRequest("DELETE", `/fish-admin/fishing-tickets/${id}/`, callback)
}

export function apiPatchPersonHistory(callback, id, data){
    return backendRequest("PATCH", `/fish-admin/person-history/${id}/`, callback, data)
}

export function apiCreatePersonHistory(callback, data){
    return backendRequest("POST", `/fish-admin/person-history/`, callback, data)
}

export function apiDeletePersonHistory(callback, id){
    return backendRequest("DELETE", `/fish-admin/person-history/${id}`, callback, {})
}

export function apiCreateFishingLicense(callback, data){
    return backendRequest("POST", `/fish-admin/fishing-licenses/`, callback, data)
}

export function apiPatchFishingLicense(callback, id, data){
    return backendRequest("PATCH", `/fish-admin/fishing-licenses/${id}/`, callback, data)
}

export function apiDeleteFishingLicense(callback, id){
    return backendRequest("DELETE", `/fish-admin/fishing-licenses/${id}/`, callback, {})
}



import React, { createRef} from 'react'

import '../../css/questions.css'
import ReCAPTCHA from 'react-google-recaptcha'
import { apiQuestionCreate } from '../actions'
import { translateErrorMessage } from '../../global/functions'

export default class QuestionAsk extends React.Component{

    constructor(props){
        super(props)
        this.reRef=createRef();

        this.state=({
            name: "",
            surname: "",
            title: this.props.title ? this.props.title : '',
            content: "",
            errorMessage: "",
            showError: false
        })

        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleContentChange = this.handleContentChange.bind(this)
        this.handleSurnameChange = this.handleSurnameChange.bind(this)
        this.handleTitleChange = this.handleTitleChange.bind(this)
        this.handleContentChange = this.handleContentChange.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.handleFormSubmitQuestion = this.handleFormSubmitQuestion.bind(this)
        this.handleFormSubmitAnswer = this.handleFormSubmitAnswer.bind(this)
        this.validate = this.validate.bind(this)
        this.clearForm = this.clearForm.bind(this)
    }

    handleNameChange(e){
        this.setState({
            name: e.target.value
        })
    }

    handleSurnameChange(e){
        this.setState({
            surname: e.target.value
        })
    }

    handleTitleChange(e){
        this.setState({
            title: e.target.value
        })
    }

    handleContentChange(e){
        this.setState({
            content: e.target.value
        })
    }

    handleFormSubmit(_e){
        if(this.props.isQuestion === true){
            this.handleFormSubmitQuestion()
        }else{
            this.handleFormSubmitAnswer()
        }
    }

    async handleFormSubmitQuestion(){

        const handleQuestionDidCreate = (response, status) =>{
            if(status === 201){
                this.clearForm()
                this.setState({
                    showError:false
                })
                this.props.onSubmitQuestion() 
            }else{
                const errMessage = 'message' in response ? translateErrorMessage(response.message): "Něco se pokazilo, opakujte později."
                this.setState({
                    errorMessage:errMessage,
                    showError:true
                })
            }
        }

        if(this.validate() === true){
            // garther data to post
            const data = {
                "title": this.state.title,
                "author_name": this.state.name,
                "author_surname": this.state.surname,
                "content": this.state.content,
                "token":await this.reRef.current.executeAsync()
            }
            this.reRef.current.reset()
            return apiQuestionCreate(handleQuestionDidCreate, data)
        }        
    }

    handleFormSubmitAnswer(){
        if(this.validate() === true){
            // garther data to post
            const data = {
                "author_name": this.state.name,
                "author_surname": this.state.surname,
                "content": this.state.content,
            }
            this.clearForm()
            this.props.onSubmitAnswer(data)
        }
    }

    clearForm(){
        // clear form
        this.setState({
            title: "",
            name: "",
            surname: "",
            content: "",
            showError: false
        })
    }

    validate(){
        // trim the spaces
        var ret = true;
        const nameTrim = this.state.name.replace(/ /g,'')
        const surnameTrim = this.state.surname.replace(/ /g, '')
        const titleTrim = this.state.title.replace(/ /g, '')
        const contentTrim = this.state.content.replace(/ /g, '')

        if(nameTrim.length === 0 ||
            surnameTrim.length === 0 ||
            titleTrim.length === 0 ||
            contentTrim.length === 0)
        {
           ret = false;
        }

        this.setState({
            showError: !ret,
            errorMessage: "Vyplňte všechna pole označená hvězdičkou!",
        })

        return ret;
    }

    render(){
        if(this.props.show)
        {
            return(
                <div className="container question-ask-container p-2">
                    <div className="d-flex justify-content-between ">
                        <span/>
                        {this.props.isQuestion === true &&
                            <h4>{this.props.heading}</h4>
                        }
                        {this.props.isQuestion === false &&
                            <h5>{this.props.heading}</h5>
                        }
                        {this.props.isQuestion === true &&
                            <span/>
                        }
                        {this.props.isQuestion === false &&
                            <button type="button" className="answer-close-button margin-right-1" onClick={this.props.onAnswerClose ? () => this.props.onAnswerClose() : () => {}}>
                                <i className="bi bi-x" title="Zavřít"></i>
                            </button>
                        } 
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <div>
                                    <div className="d-flex justify-content-left">
                                        <label className="col-form-label">Předmět</label>
                                        <p className="mandatory-star">*</p>
                                    </div>
                                    <input type="text" className="form-control input-control" onChange={this.handleTitleChange} value={this.state.title} readOnly={this.props.isQuestion===false}/>
                                </div>
                                <div>
                                    <div className="d-flex justify-content-left">
                                        <label className="col-form-label">Jméno:</label>
                                        <p className="mandatory-star">*</p>
                                    </div>
                                    <input type="text" className="form-control input-control" onChange={this.handleNameChange} value={this.state.name}/>
                                </div>
                                <div>
                                    <div className="d-flex justify-content-left">
                                        <label className="col-form-label">Příjmení:</label>
                                        <p className="mandatory-star">*</p>
                                    </div>
                                    <input type="text" className="form-control input-control" onChange={this.handleSurnameChange} value={this.state.surname}/>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                <div>
                                    <div className="d-flex justify-content-left">
                                        <label className="col-form-label">{this.props.isQuestion===true ? "Dotaz:" :"Odpověd:"}</label>
                                        <p className="mandatory-star">*</p>
                                    </div>
                                    <textarea className="form-control" rows="8" onChange={this.handleContentChange} value={this.state.content}></textarea>
                                </div>
                            </div>
                        </div>  
                        {this.state.showError && 
                        <div className="d-flex justify-content-center">
                            <p className="alert alert-danger">{this.state.errorMessage}</p>
                        </div>
                        }
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-outline-dark" type="submit" onClick={this.handleFormSubmit}>Odeslat</button>
                        </div>
                    </div>
                    <ReCAPTCHA 
                        sitekey="6LfnhHciAAAAAEzweVpj5_BtiMqV-ED3Z915ZOJI" 
                        size='invisible'
                        ref={this.reRef}/>
                </div>       
            )
        }
        else
        {
            return null
        }
    }

}
import React, { useEffect, useState } from 'react';
import {
    apiCreateIssuingOrganization,
    apiDeleteIssuingOrganization,
    apiLookupIssuingOrganizationDetail,
    apiPatchIssuingOrganization
} from './actions'
import DictErrorListComponent from "../../global/components/error_components/DictErrorListComponent";
import ModalDialog from "../../modal/components/ModalDialog";


export function IssuingOrganizationDetail(props){
    // Setting the state variables
    const [orgId, setOrgId] = useState(props.orgId)
    const [showDelete, setShowDelete] = useState(false)
    const [modified, setModified] = useState(false)
    const [orgObject, setOrgObject] = useState({})
    const [orgObjectOriginal, setOrgObjectOriginal] = useState({})
    const [errors, setErrors] = useState({})

    // useEffect hook - same as component did mount / component did modify
    // I want to this be called when species id is changed
    useEffect(() => {
        if(orgId){
            apiLookupIssuingOrganizationDetail(orgDetailDidLoaded, orgId)
        }
    },[orgId])

    /**
     * Callback function called when response with issuing organization details is retrieved from server
     * @param {*} response 
     * @param {*} status 
     */
    const orgDetailDidLoaded = (response, status) => {
        if(status===200){
            setOrgObject(response)
            setOrgObjectOriginal(JSON.parse(JSON.stringify(response)))
        }else if(status===404){
            setOrgObject({})
            setOrgObjectOriginal({})
            setModified(false)
        }else{
            alert("Něco se pokazilo, opakujte později")
        }
    }

    /**
     * Called when cancell button is clicked
     */
    const handleCancel = () => {
        setOrgObject(orgObjectOriginal)
        setModified(false)
        setErrors({})
    }

    /**
     * Called when save button is clicked
     */
    const handleSave = () => {

        const orgDidPatched = (response, status) => {
            if(status===200){
                setOrgObject(response)
                setModified(false)
                setOrgObjectOriginal(JSON.parse(JSON.stringify(response)))
            }else if(status===400){
                setErrors(response)
            }else{
                alert("Něco se pokazilo, opakujte později")
            }
        }

        const orgDidCreated = (response, status) => {
            if(status===201){
                setOrgObject(response)
                setOrgObjectOriginal(JSON.parse(JSON.stringify(response)))
                setOrgId(response.id)
                setModified(false)
                setErrors({})
            }else{
                setErrors(response)
            }
        }

        if(orgId){
            apiPatchIssuingOrganization(orgDidPatched, orgId, orgObject)
        }else{
            apiCreateIssuingOrganization(orgDidCreated, orgObject)
        }
    }

    /**
     * Callen when some value in the species object is modified
     * @param {*} fieldName - name of the modified field
     * @param {*} value  - new field value
     */
    const orgValueModified = (fieldName, value) => {
        const curOrgObject = JSON.parse(JSON.stringify(orgObject))
        curOrgObject[fieldName] = value
        setOrgObject(curOrgObject)
        setModified(true)
    }

    /**
     * Performed when delete modal dialog is confirmed
     */
    const handleDeleteAction = () => {
        const orgDidDeleted = (_response, status) => {
            if(status === 204){
                setOrgId(null)
                setOrgObject({})
                setModified(false)
                setOrgObjectOriginal({})
                window.location.href = '/fish-admin/issuing-organizations/'    
            }else{
                alert("Něco se pokazilo, opakujte později")
            }
        }

        setShowDelete(false)
        apiDeleteIssuingOrganization(orgDidDeleted, orgId)
    }

    return(
        <div className="container licenses-container-wrapper">
            <div className='container mb-3'>
                <a href='/fish-admin/issuing-organizations/'>{"< všechny úřady"}</a>
            </div>
            <div className="d-flex justify-content-between mb-3">
                <span/>
                {orgId && <h3>Úřad</h3>}
                {!orgId && <h3>Nový úřad</h3>}
                
                <div className="person-header-btn-group"> 
                    <div className='btn-group'>
                        {orgId && 
                        <button type='button' className='btn btn-outline-danger person-btn'
                            onClick={(_e)=>(setShowDelete(true))}>Smazat</button>}
                        {modified &&
                        <button type='button' className='btn btn-outline-danger person-btn'
                            onClick={(_e)=>(handleCancel())}>Zrušit</button>}
                        {(modified || (modified && !orgId)) &&
                        <button type='button' className='btn btn-outline-dark person-btn'
                            onClick={(_e)=>(handleSave())}>Uložit</button>}
                    </div>
                </div>
            </div>

            <form method="POST">
                <div className="container officers-form">
                    <div className="mb-3">
                        <b>Název úřadu</b>
                        <input type="text" className="form-control"
                            value={orgObject.name ? orgObject.name : ''}
                            onChange={(e) => (orgValueModified('name', e.target.value))}/>
                        <DictErrorListComponent
                            labels={{
                                'name': "Název úřadu",
                                "__all__": "Obecné",
                                "non_field_errors": "Obecné",
                            }}
                            errorDict={errors}
                        />
                    </div>
                </div>
            </form>

            <ModalDialog
                show={showDelete}
                message="Smazat"
                subMessage="Opravdu chcete smazat tento úřad?"
                onBtn1={handleDeleteAction}
                onBtn2={() => {
                    setShowDelete(false)
                }}
                />

        </div>
    )
}
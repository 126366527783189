import React from 'react';
import {apiAlbumDelete, apiLookupAlbumsPage, apiLookupAlbumsRegion} from './actions'
import AlbumCard from './AlbumCard'
import '../../css/albumcards.css'
import ModalDialog from '../../modal/components/ModalDialog'
import {isUserAuth} from '../../auth'
import {PageComponent} from '../../paging/components/PageComponent'

export default class GalleryComponent extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            pageNum: props.pageNumber ? parseInt(props.pageNumber) : 1,
            regionId: props.regionId,
            pageCount:1,
            albums: [],
            showNewAlbum: false,
            showDeleteModal: false,
            currentAlbumToDelete: 0,
            isAuth: false,
        }

        // bind this instance to the functions
        this.handleAlbumsDidLoad    = this.handleAlbumsDidLoad.bind(this)
        this.showNewAlbumModal      = this.showNewAlbumModal.bind(this)
        this.deleteAlbumModalShow   = this.deleteAlbumModalShow.bind(this)
        this.handleDeleteAlbumOk    = this.handleDeleteAlbumOk.bind(this)
        this.handleDeleteAlbumCancel= this.handleDeleteAlbumCancel.bind(this)
        this.loadAuth               = this.loadAuth.bind(this)
        this.previousPageClicked    = this.previousPageClicked.bind(this)
        this.lastPageClicked        = this.lastPageClicked.bind(this)
        this.firstPageClicked       = this.firstPageClicked.bind(this)
        this.nextPageClicked        = this.nextPageClicked.bind(this)
    }

    loadAuth(){
        const authInfoRetrieved = (response) => {
            if(response){
                this.setState({
                    isAuth: response.is_auth === 'yes'
                })
            }
        }

        isUserAuth(authInfoRetrieved)
    }

    handleAlbumsDidLoad(response, status){
        if(status===200){
            if(this.state.regionId){
                this.setState({
                    albums:response,
                })
            }else{
                this.setState({
                    albums:response.results,
                    pageCount:parseInt((Math.ceil(response.count / 9))) // 9 is the page size for photogallery
                })
            }
        }else{
            alert("Něco se pokazilo, opakujte později.")
        }
    }

    componentDidMount(){
        this.loadAuth()
        if(this.state.regionId){
            apiLookupAlbumsRegion(this.handleAlbumsDidLoad, this.state.regionId)
        }
        else{
            apiLookupAlbumsPage(this.handleAlbumsDidLoad, this.state.pageNum)
        }
    }

    // album creation
    showNewAlbumModal(){
        window.location.href = '/photogallery/create/'
    }

    // album deletion
    deleteAlbumModalShow(albumId){
        this.setState({
            currentAlbumToDelete: albumId,
            showDeleteModal: true
        })
    }

    handleDeleteAlbumOk(){
        this.setState({
            showDeleteModal: false
        })

        const handleAlbumDidDelete = () => {
            if(this.state.regionId){
                apiLookupAlbumsRegion(this.handleAlbumsDidLoad, this.state.regionId)
            }
            else{
                apiLookupAlbumsPage(this.handleAlbumsDidLoad, this.state.pageNum)
            }
        }

        apiAlbumDelete(handleAlbumDidDelete, this.state.currentAlbumToDelete)
    }

    handleDeleteAlbumCancel(){
        this.setState({
            showDeleteModal: false
        })
    }

    previousPageClicked(){
        window.location.href=`/photogallery/?page=${this.state.pageNum - 1}`
    }

    nextPageClicked(){
        window.location.href=`/photogallery/?page=${this.state.pageNum + 1}`
    }

    firstPageClicked(){
        window.location.href='/photogallery/?page=1'
    }

    lastPageClicked(){
        window.location.href=`/photogallery/?page=${this.state.pageCount}`
    }

    render() {
        return (
                <div>
                    <div className="container gallery-page"> 
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                                <span></span>
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                                {!this.state.regionId && <h2 className='heading-center'>Alba</h2>}
                                {this.state.regionId && <h2 className='heading-center'>Fotky z revíru</h2>}
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                                <div className='d-flex justify-content-center'>
                                    {this.state.isAuth && <button className='btn btn-outline-dark btn' type='button' onClick={() => this.showNewAlbumModal()}>Vytvořit album</button>} 
                                </div>
                                {!this.state.isAuth && <span></span> }
                            </div>
                        </div>

                        <div className="row">
                            {this.state.albums.map((albumElement, index) => (
                                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4" key={`${index}-${albumElement.id}`}>
                                    <AlbumCard
                                        albumId={albumElement.id}
                                        src={albumElement.photos.length > 0 ? albumElement.photos[0].photo : null}
                                        title={albumElement.name}
                                        region={albumElement.region_str ? albumElement.region_str : ''}
                                        btnHref={`/photogallery/albums/${albumElement.id}/`}
                                        btnLabel="Více"
                                        onDelete={this.deleteAlbumModalShow}
                                        isAuth={this.state.isAuth}/>
                                </div>
                            ))}
                        </div>
                        <ModalDialog
                            show={this.state.showDeleteModal}
                            message="Smazat?"
                            onBtn1={this.handleDeleteAlbumOk}
                            onBtn2={this.handleDeleteAlbumCancel}/>
                    </div>
                    
                    {!this.state.regionId && <PageComponent
                            pageNum={this.state.pageNum}
                            pageCount={this.state.pageCount}
                            onPrevious={this.previousPageClicked}
                            onNext={this.nextPageClicked}
                            onFirst={this.firstPageClicked}
                            onLast={this.lastPageClicked}/>}
                </div>
              )
    }
}
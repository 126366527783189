import { backendRequest } from "../../backend";

export function apiLookupFishingLicenseSeasonSummary(callback, fishingLicenseId){
    return backendRequest("GET", `/fish-admin/fishing-license-summaries/${fishingLicenseId}/`, callback, {})
}

export function apiLookupFishingLicensesSimple(callback){
    return backendRequest("GET", '/fish-admin/fishing-license-simple/', callback, {})
}

export function apiLookupRegionsSimple(callback){
    return backendRequest("GET", '/regions/simple/', callback, {})
}

export function apiPatchSeasonSummary(callback, id, data){
    return backendRequest("PATCH", `/fish-admin/season-summaries/${id}/`, callback, data)
}

export function apiCreateSeasonSummary(callback, data){
    return backendRequest("POST", '/fish-admin/season-summaries/', callback, data)
}

export function apiDeleteSeasonSummary(callback, id){
    return backendRequest("DELETE", `/fish-admin/season-summaries/${id}/`, callback, {})
}

export function apiSeasonSummaryRegionPatchFishingLicense(callback, id, data){
    return backendRequest("PATCH", `/fish-admin/season-summaries/${id}/patch-license/`, callback, data)
}

export function apiPatchSeasonSummaryRegionLine(callback, id, data){
    return backendRequest("PATCH", `/fish-admin/season-summary-lines/${id}/`, callback, data)
}

export function apiCreateSeasonSummaryRegionLine(callback, data){
    return backendRequest("POST", `/fish-admin/season-summary-lines/`, callback, data)
}

export function apiDeleteSeasonSummaryRegionLine(callback, id){
    return backendRequest("DELETE", `/fish-admin/season-summary-lines/${id}/`, callback)
}

export function apiLookupSpiecies(callback){
    return backendRequest("GET", '/fish-admin/species/', callback, {})
}

export function apiLookupSeasonSummaries(callback){
    return backendRequest("GET", '/fish-admin/season-summaries/', callback, {})
}

export function apiLookupSeasonSummariesForYear(callback, year){
    return backendRequest("GET", `/fish-admin/season-summaries-simple/${year}/`, callback)
}

export function apiDeleteFishingLicenseSeasonSummaries(callback, data){
    return backendRequest("POST", '/fish-admin/fishing-license-summaries/delete/', callback, data)
}

export function apiLookupOfficersSimple(callback){
    return backendRequest("GET", '/fish-admin/officers-simple/', callback, {})
} 

export function apiCreateOfficerCheck(callback, data){
    return backendRequest("POST", '/fish-admin/officers-checks/', callback, data)
}

export function apiPatchOfficerCheck(callback, id, data){
    return backendRequest("PATCH", `/fish-admin/officers-checks/${id}/`, callback, data)
}

export function apiDeleteOfficerCheck(callback, id){
    return backendRequest("DELETE", `/fish-admin/officers-checks/${id}/`, callback, {})
}
import React from "react";
import ModalDialog from "../modal/components/ModalDialog"
import InfoBox from "../modal/components/InfoBox";


import LicensesSellYearEditModal from '../licenses-sell/components/LicensesSellYearEditModal'

export default class GridViewComponent extends React.Component{
    
    constructor(props){
        super(props)

        // supporded data types for search, in case of date allow to select range
        this.SEARCHTYPETEXT     = "TEXT"
        this.SEARCHTYPEINT      = "INT"
        this.SEARCHTYPEDATE     = "DATE"


        this.state = ({
            data:[],

            // header
            header: "",

            // TO-DO: one collum -> object with type, in case of select possible values
            columns:[],
            hrefColumn: "",

            menuActions:[],

            // data selection
            selectedDataIdxs:new Set(),

            // data searching
            searchColumn:"",
            searchValueExact:"",
            searchValueRangeFrom: "",
            searchValueRangeTo: "",    

            // delete modal
            showDeleteModal: false,

            // is year specific ? is yes gives the option to select year to filter
            yearSpecific:false,
            year: props.year ? parseInt(props.year) : new Date().getFullYear(),
            showEditYearModal:false,
            showDeleteErrorInfoBox:false,
            deleteErrorInfoSubMessage:""
        })

        this.rowSelectOnChange          = this.rowSelectOnChange.bind(this)
        this.searchValueExactChange     = this.searchValueExactChange.bind(this)
        this.lookupValueKey             = this.lookupValueKey.bind(this)
        this.filterCriteriaMetForRow    = this.filterCriteriaMetForRow.bind(this)
        this.gridNewAction              = this.gridNewAction.bind(this)
        this.gridDeleteAction           = this.gridDeleteAction.bind(this)
        this.gridRowHref                = this.gridRowHref.bind(this)
        this.yearModifiedAction         = this.yearModifiedAction.bind(this)
    }

    componentDidMount(){
        // download data and provide the column names here, also provide the menu actions

        // SAMPLE
        /*
        this.setState({
            data:[
                {
                    "Jmeno": "Tomas",
                    "Categorie": "Cat1",
                    "Vek": 25,
                    "Narozen": '1995-07-03'
                },
                {
                    "Jmeno": "Test",
                    "Categorie": "Cat2",
                    "Vek": 35,
                    "Narozen": '1985-07-03'
                }
            ],
            columns:[
                {
                    "name": "Jmeno",
                    "label": "Jmeno",
                    "type": this.SEARCHTYPETEXT
                },
                {
                    "name": "Categorie",
                    "label": "Cetegorie",
                    "type": this.SEARCHTYPETEXT
                },
                {
                    "name": "Vek",
                    "label": "Vek",
                    type: this.SEARCHTYPEINT
                },
                {
                    "name": "Narozen",
                    "label": "Narozen",
                    type: this.SEARCHTYPEDATE
                } 
            ],
            hrefColumn: "Jmeno",
            searchColumn:0,
            
            // Action description: Name, Callback, NeedsRecords, AllowMultiSelecte 
            
            menuActions:[
                {
                    "name": "TestAkce",
                    "callback": (selectedRecords) => (console.log("ja jsem test akce", selectedRecords)),
                    "needsRecord": true,
                    "multiSelect": false,
                }
            ]
        })
        */
    }

    rowSelectOnChange(rowIdx){
        // Handle the records selection

        var currentSelectedDataIdx = this.state.selectedDataIdxs

        if(currentSelectedDataIdx.has(rowIdx)){
            currentSelectedDataIdx.delete(rowIdx)
        }else{
            currentSelectedDataIdx.add(rowIdx)
        }
        this.setState({
            selectedDataIdxs:currentSelectedDataIdx
        })
    }

    searchValueExactChange(value){
        // handle change of search value for types: text, int, select
        this.setState({
            searchValueExact:value
        })
    }

    lookupValueKey(){
        // concatenates all the values specified by the user in the filters to construct key for row listing
        return `${this.state.searchValueExact}-${this.state.searchValueRangeFrom}-${this.state.searchValueRangeTo}-${this.state.searchValueDateFrom}-${this.state.searchValueDateTo}`
    }

    filterCriteriaMetForRow(dataRow){

        const columnInfo = this.state.columns[this.state.searchColumn]

        if(columnInfo && dataRow)
        {
            if(columnInfo.type === this.SEARCHTYPETEXT){
                return dataRow[columnInfo.name].toLowerCase().includes(this.state.searchValueExact.toLowerCase())
            }else if (columnInfo.type === this.SEARCHTYPEINT || columnInfo.type === this.SEARCHTYPEDATE){
                const dataRowValue = columnInfo.type === this.SEARCHTYPEIN ? parseInt(dataRow[columnInfo.name]) : dataRow[columnInfo.name]
                if(this.state.searchValueRangeFrom === "" && this.state.searchValueRangeTo === ""){
                    return true
                }
                else if(this.state.searchValueRangeFrom !== "" && this.state.searchValueRangeTo !== "" && 
                    this.state.searchValueRangeTo >= this.state.searchValueRangeFrom){
                    return(dataRowValue >= this.state.searchValueRangeFrom && dataRowValue <= this.state.searchValueRangeTo)
                }
                else if(this.state.searchValueRangeFrom !== "" && this.state.searchValueRangeTo === ""){
                    return(dataRowValue >= this.state.searchValueRangeFrom)
                }
                else if(this.state.searchValueRangeFrom === "" && this.state.searchValueRangeTo !== ""){
                    return(dataRowValue <= this.state.searchValueRangeTo)
                }
                else{
                    return false;
                }
            }
            
        }
        return false;    
    }

    gridNewAction(){
        // implement new action here
    }

    gridDeleteAction(){ 
        // implement delete action here
    }

    yearModifiedAction(newYearValue){
        // implement to have additional logic on year change
    }

    gridRowHref(row){
        // construct href link to detail view here
        return "#"
    }

    render(){
        return(
            <div className="container licenses-container-wrapper grid-view">
                <div className="d-flex justify-content-between">
                    <span/>
                    <h3>{this.state.header}</h3>
                    {this.state.yearSpecific && 
                        <button className="btn btn-outline-dark person-btn"
                            onClick={(e)=>(this.setState({showEditYearModal:true}))}>Změnit rok</button>}
                    {!this.state.yearSpecific && <span/>}
                </div>
                <div className="row mb-2">
                    <div className="grid-search-label my-auto">
                        <b>Hledat v</b>
                    </div>
                    <div className="grid-search-column">
                        <select 
                            className="d-inline form-select form-control" 
                            value={this.state.searchColumn}
                            onChange={(e) => {
                                this.setState({
                                    searchColumn:parseInt(e.target.value),
                                    searchValueExact:"",
                                    searchValueRangeFrom: "",
                                    searchValueRangeTo: "",
                                })
                            }}>
                                {this.state.columns.map((columnObj, idx) => (
                                    <option value={idx} key={`${columnObj.label}-${idx}`}>{columnObj.label}</option>
                                ))}
                        </select>
                    </div>
                    <div className="grid-search-area">
                        <div className="row">
                            {/* INPUTS */}
                            <div className="col my-auto">
                                {this.state.columns.length > this.state.searchColumn && this.state.columns[this.state.searchColumn].type === this.SEARCHTYPETEXT &&
                                    <div className="row">
                                        <div className="grid-search-label my-auto">
                                            <span>hodnota:</span>
                                        </div>
                                        <div className="grid-search-column">
                                            <input type="text" className="form-control" value={this.state.searchValueExact} onChange={(e) => this.searchValueExactChange(e.target.value)}/>
                                        </div>
                                    </div>
                                }
                                {this.state.columns.length > this.state.searchColumn && (this.state.columns[this.state.searchColumn].type === this.SEARCHTYPEINT ||
                                    this.state.columns[this.state.searchColumn].type === this.SEARCHTYPEDATE) &&
                                    <div className="row">
                                        <div className="grid-search-label-sm my-auto">
                                            <span>od:</span>
                                        </div>
                                        <div className=" grid-search-column">
                                            <input 
                                                type={this.state.columns[this.state.searchColumn].type === this.SEARCHTYPEINT ? "number" : "date"} 
                                                className="form-control" 
                                                value={this.state.searchValueRangeFrom} 
                                                onChange={(e) => {
                                                    this.setState({
                                                        searchValueRangeFrom:e.target.value
                                                    })
                                                }}/>
                                        </div>
                                        <div className="grid-search-label-sm my-auto">
                                            <span>do:</span>
                                        </div>
                                        <div className=" grid-search-column">
                                            <input 
                                                type={this.state.columns[this.state.searchColumn].type === this.SEARCHTYPEINT ? "number" : "date"} 
                                                className="form-control" 
                                                value={this.state.searchValueRangeTo} 
                                                onChange={(e) => {
                                                    this.setState({
                                                        searchValueRangeTo:e.target.value
                                                    })
                                                }}/>
                                        </div>
                                        
                                    </div>
                                }
                            </div>
                        </div>
                       
                    </div>
                </div>

                {/* MENU */}
                <div className="row grid-menu-area mb-2">
                    <button type="button" className="btn btn-outline-dark grid-menu-btn" 
                        onClick={(e) => this.gridNewAction()}><i className="bi bi-plus"></i>&nbsp;Nový </button>
                    <button type="button" className="btn btn-outline-dark grid-menu-btn grid-menu-btn-ml"
                        onClick={(e) => {this.setState({showDeleteModal:true})}}
                        disabled={this.state.selectedDataIdxs.size === 0}><i className="bi bi-trash"></i>&nbsp;Smazat </button>
                    
                    {/* all other menu actions */}
                    {this.state.menuActions.map((menuAction, idx) => (
                        <button 
                            key={`${menuAction.name}-${idx}`} 
                            type="button" 
                            className="btn btn-outline-dark grid-menu-btn grid-menu-btn-ml"
                            onClick={(e) => menuAction.callback(this.state.selectedDataIdxs)} // call the given callback as parameter pass all the selected records  
                            disabled={(this.state.selectedDataIdxs.size > 1 && !menuAction.multiSelect) 
                            || (this.state.selectedDataIdxs.size === 0 && menuAction.needsRecord)}>
                                {menuAction.name}</button>
                    ))}
                </div>

                <table className="table">
                    {/* Table Header */}
                    <thead>
                        <tr>
                        {/* Select column */}
                        <th scope="col">#</th>
                        {this.state.columns.map((columnObj, idx) => (
                            <th key={`header-${idx}-${columnObj.label}`} scope="col">{columnObj.label}</th>
                        ))}
                        </tr>
                    </thead>

                    {/* Table body */}
                    <tbody>
                        {this.state.data.map((dataRow, rowIdx) => (
                            this.filterCriteriaMetForRow(dataRow) &&
                            <tr key={`row-${rowIdx}-${this.lookupValueKey()}`}>
                                <th scope="row">
                                    {/* Select column */}
                                    <input className="form-check-input checkbox-circle" type="checkbox"
                                        checked={this.state.selectedDataIdxs.has(rowIdx)}
                                        onChange={(e) => {this.rowSelectOnChange(rowIdx, e.target.value)}}/>
                                </th>

                                {/* Columns */}
                                {this.state.columns.map((columnObj, colIdx) => (
                                    <td key={`row-${rowIdx}-col-${colIdx}`}>
                                        {this.state.hrefColumn === columnObj.name && <a className="grid-view-href" href={this.gridRowHref(dataRow)}>
                                            {columnObj.alias ? dataRow[columnObj.alias] : dataRow[columnObj.name]}
                                        </a>}
                                        {this.state.hrefColumn !== columnObj.name && 
                                            <span 
                                                className={columnObj.contraintFnc ? columnObj.contraintFnc(dataRow[columnObj.name]) ? "" : "constraint-not-met" : ""}>
                                                {columnObj.alias ? dataRow[columnObj.alias] : dataRow[columnObj.name]}
                                            </span>
                                        }
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Delete modal */}
                <ModalDialog
                    show={this.state.showDeleteModal}
                    message="Smazat"
                    subMessage={this.state.selectedDataIdxs.size > 1 ? `Chcete smazat ${this.state.selectedDataIdxs.size} záznamů ?` : "Chcete smazat záznam?"}
                    onBtn1={(e) => {
                        this.gridDeleteAction()
                    }}
                    onBtn2={(e) => {
                        this.setState({
                            showDeleteModal:false
                        })
                    }}
                />

                {/* Info box to show errors returned by server when  */}
                <InfoBox
                    show={this.state.showDeleteErrorInfoBox}
                    message="Chyba"
                    subMessage={this.state.deleteErrorInfoSubMessage}
                    onBtn1={(e) => {this.setState({showDeleteErrorInfoBox:false})}}
                    />

                {/* change year modal dialog */}
                <LicensesSellYearEditModal
                    show={this.state.showEditYearModal}
                    title="Nový rok"
                    onBtn1={(newYearValue) => {
                        this.setState({
                            showEditYearModal:false,
                            year:newYearValue
                        })

                        this.yearModifiedAction(newYearValue)
                    }}
                    onBtn2={(_e) => {
                        this.setState({
                            showEditYearModal:false
                        })
                    }}
                    sellYear={this.state.year}
                />
            </div>
        )
    }
}
import {backendRequest} from '../../backend'

export function apiLookupOfficers(callback){
    return backendRequest("GET", '/fish-admin/officers/', callback, {})
}

export function apiLookupOfficer(callback, id){
    return backendRequest("GET", `/fish-admin/officers/${id}/`, callback, {})
}

export function apiCreateOfficer(callback, data){
    return backendRequest("POST", '/fish-admin/officers/', callback, data)
}

export function apiPatchOfficer(callback, id, data){
    return backendRequest("PATCH", `/fish-admin/officers/${id}/`, callback, data)
}

export function apiDeleteOfficer(callback, id){
    return backendRequest("DELETE", `/fish-admin/officers/${id}`, callback, {})
}

export function apiDeleteOfficers(callback, data){
    return backendRequest("POST", '/fish-admin/officers/delete/', callback, data)
}
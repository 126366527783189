import React from 'react'
import parse from 'html-react-parser';

export function NewsElement(props){
    const {newsElement, isAuth, onDelete, onModify} = props

    const htmlParsed = parse(newsElement.content)
    return(
        <div className="container py-3 px-3 my-3 px-3">
            {isAuth &&
                <div className="d-flex flex-row-reverse">
                    <button type="button" className='btn btn-sm btn-primary m-1' onClick={() => onModify(newsElement)}>Upravit</button>
                    <button type="button" className='btn btn-sm btn-primary m-1' onClick={() => onDelete(newsElement)}>Smazat</button>
                </div>
            }
            <div className="container border border-primary rounded-3 news-element">
                <div className="row lead" >
                    {htmlParsed}
                </div>
                <div className="row d-flex justify-content-end px-3">
                    {newsElement.author_name}, {newsElement.date}
                </div>
            </div>
        </div>
    )
}


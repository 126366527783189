import React from 'react'
import {apiAlbumLookup, apiAlbumPhotosUpload, apiAlbumPhotosDelete} from './actions'
import AlbumPhoto from './AlbumPhoto'
import AlbumPhotoUploadModal from './AlbumPhotoUploadModal'
import ModalDialog from '../../modal/components/ModalDialog'

import {isUserAuth} from '../../auth'

export default class AlbumDetail extends React.Component {

    constructor(props){
        super(props)
        this.classNameBtnPrimary = "btn btn-dark"
        this.classNameBtnSecondary = "btn btn-outline-dark"
        
        this.state = {
            albumName:  "",
            photos: [],
            showLoadModal: false,
            showDeleteModal: false,
            selectMode: false,
            selectedAlbumPhotos:[],
            isAuth: false,
        }

        // bind local functions to the this instance 
        this.handlePhotosDidLoad = this.handlePhotosDidLoad.bind(this)
        this.handleShowLoadModal = this.handleShowLoadModal.bind(this)
        this.handleCloseLoadModal = this.handleCloseLoadModal.bind(this)
        this.handleUploadPhotos = this.handleUploadPhotos.bind(this)
        this.handleSelectButtonClicked = this.handleSelectButtonClicked.bind(this)
        this.handleDeleteBtnClicked = this.handleDeleteBtnClicked.bind(this)
        this.handleDeleteCancelled = this.handleDeleteCancelled.bind(this)
        this.handleDeleteConfirmed = this.handleDeleteConfirmed.bind(this)
        this.handlePhotoClick = this.handlePhotoClick.bind(this)
        this.loadAuth = this.loadAuth.bind(this)
    }

    loadAuth(){
        const authInfoRetrieved = (response) => {
            if(response){
                this.setState({
                    isAuth: response.is_auth === 'yes'
                })
            }
        }
        isUserAuth(authInfoRetrieved)
    }

    handlePhotosDidLoad(response){
        this.setState({
            albumName: response.name,
            photos: response.photos
        })
    }

    componentDidMount(){
        this.loadAuth()
        apiAlbumLookup(this.handlePhotosDidLoad, this.props.albumId)
    }

    // photos uploading logic
    handleShowLoadModal(){
        this.setState({
            showLoadModal: true
        })
    }

    handleCloseLoadModal(){
        this.setState({
            showLoadModal: false
        })
    }

    handleUploadPhotos(data){
        const handlePhotosDidUpload = () => {
            apiAlbumLookup(this.handlePhotosDidLoad, this.props.albumId)
        }

        apiAlbumPhotosUpload(handlePhotosDidUpload, this.props.albumId, data)
        
        this.setState({
            showLoadModal: false
        })
    }

    // photos selecting logic
    handleSelectButtonClicked(){
        if(this.state.selectMode){
            this.state.selectedAlbumPhotos.forEach(element => {
                element.resetState()
            });
        }

        this.setState({
            selectMode: !this.state.selectMode,
            selectedAlbumPhotos: []
        })
    }

    handlePhotoClick(albumPhoto){
        var selectedPhotos = this.state.selectedAlbumPhotos
        if(!albumPhoto.state.selected){
            selectedPhotos.push(albumPhoto)
        }else{
            const index = selectedPhotos.indexOf(albumPhoto)
            if(index > -1){
                selectedPhotos.splice(index, 1)
            }
        }
        this.setState({
            selectedAlbumPhotos: selectedPhotos
        })
    }

    handleDeleteBtnClicked(){
        this.setState({
            showDeleteModal: true
        })
    }

    handleDeleteConfirmed(){
        var photosIdArray = []
        this.state.selectedAlbumPhotos.forEach(element => {
            element.resetState()
            photosIdArray.push(element.props.id)
        });

        this.setState({
            showDeleteModal: false,
            selectedAlbumPhotos: [],
        })

        const handlePhototsDidDelete = (_response) => {
            apiAlbumLookup(this.handlePhotosDidLoad, this.props.albumId)
        }
        apiAlbumPhotosDelete(handlePhototsDidDelete, {"photos": photosIdArray})
    }

    handleDeleteCancelled(){
        this.setState({
            showDeleteModal: false
        })
    }

    render(){
        return(
            <div className="container-full-h">
                <div className='container album-container'>
                    <div className="d-flex justify-content-around py-2">
                        <span/>
                        <h3>{this.state.albumName}</h3> 
                        {this.state.isAuth &&
                        <div className="btn-toolbar" role="toolbar">
                            <div className="btn-group mr-2 px-2" role="group">
                                <button 
                                    className={this.state.selectMode ? this.classNameBtnSecondary + " disabled" : this.classNameBtnPrimary}
                                    type="button" 
                                    onClick={() => this.handleShowLoadModal()}>Nahrát</button>
                                <button 
                                    className={this.state.selectMode ? this.classNameBtnPrimary : this.classNameBtnSecondary} 
                                    type="button" 
                                    onClick={() => this.handleSelectButtonClicked()}>Vybrat</button> 
                                <button className="btn btn-outline-dark"
                                onClick={() => window.location.href=`/photogallery/edit/${this.props.albumId}/`}>Upravit</button>                     
                            </div>  
                            <div className="btn-group mr-2 px-2" role="group">
                                <button 
                                    className={this.state.selectedAlbumPhotos.length > 0 ? "btn btn-outline-dark" : "d-none"}
                                    type="button" 
                                    onClick={() => this.handleDeleteBtnClicked()}><i className="bi bi-trash">&nbsp; Smazat</i></button>          
                            </div>                    
                        </div>}
                        {!this.state.isAuth && <span></span>}      
                    </div>   
                    <div className="row text-center text-lg-left">
                        {this.state.photos.map((photoElement, index) => (
                            <div className="col-lg-3 col-md-4 col-6" key={`${index}-${photoElement.id}`}>
                                <AlbumPhoto
                                    id={photoElement.id}
                                    src={photoElement.photo}
                                    selectMode={this.state.selectMode}
                                    onClick={this.handlePhotoClick}
                                    />
                            </div>
                        ))}
                    </div>
                    <AlbumPhotoUploadModal
                        show={this.state.showLoadModal}
                        message="Nahrát fotky"
                        albumId={this.props.albumId}
                        btn1ClassName="btn btn-success"
                        btn1Lbl="Nahrát"
                        onBtn1={this.handleUploadPhotos}
                        btn2Lbl="Zrušit"
                        onBtn2={this.handleCloseLoadModal}
                        />    
                    <ModalDialog
                        show={this.state.showDeleteModal}
                        message="Opravdu smazat vybrané?"
                        onBtn1={this.handleDeleteConfirmed}
                        onBtn2={this.handleDeleteCancelled}/>
                </div>
            </div>
        )
    }

}
import React from 'react'
import {apiQuestionsLookup,
    apiQuestionLookup, 
    apiQuestionPatch, 
    apiAnswerCreate, 
    apiQuestionsPageCount,
    apiQuestionsPageLookup} from '../actions'
import Question from './Question'
import QuestionAsk from './QuestionAsk'
import {PageComponent} from '../../paging/components/PageComponent'
import {isUserAuth} from '../../auth'

export default class QuestionsComponent extends React.Component{

    constructor(props){
        super(props)
        this.questionAnswerFormShown = -1;
      
        this.state = ({
            questions: [],
            questionsOrderDict:{},
            questionsHeaderRefs:[],
            questionsAnswersFormRefs:[],
            answersFormShow:{},
            questionsDict:{},
            pageNum:1,
            pageCount:1,
            previousUrl: "",
            nextUrl:"",
            currentUrl: "",
            isAuth: false,
            justAsk: props.justAsk ? props.justAsk === 'True' : false
        })

        // bind
        this.handleQuestionsDidLoad             = this.handleQuestionsDidLoad.bind(this)
        this.loadQuestions                      = this.loadQuestions.bind(this)
        this.loadQuestionUrl                    = this.loadQuestionUrl.bind(this)
        this.handleQuestionAskSubmit            = this.handleQuestionAskSubmit.bind(this)
        this.handleQuestionDidDelete            = this.handleQuestionDidDelete.bind(this)
        this.handleQuestionShowAnswerFormShow   = this.handleQuestionShowAnswerFormShow.bind(this)
        this.handleQuestionShowAnserFormHide    = this.handleQuestionShowAnserFormHide.bind(this)
        this.handleUpdateQuestion               = this.handleUpdateQuestion.bind(this)
        this.handleQuestionLockUnlock           = this.handleQuestionLockUnlock.bind(this)
        this.handleQuestionAnswerSubmit         = this.handleQuestionAnswerSubmit.bind(this)
        this.handleQuestionAnswerDelete         = this.handleQuestionAnswerDelete.bind(this)
        this.nextPageClicked                    = this.nextPageClicked.bind(this)
        this.previousPageClicked                = this.previousPageClicked.bind(this)
        this.loadAuth                           = this.loadAuth.bind(this)
        this.firstPageClicked                   = this.firstPageClicked.bind(this)
        this.lastPageClicked                    = this.lastPageClicked.bind(this)
    }

    componentDidMount(){
        this.loadAuth()
        this.loadPageCount()
        this.loadQuestions()
    }

    handleQuestionsDidLoad(response){
        const questionsResponse = response.results
        var answersFormShowNew = {}
        var currentQuestionsOrderDict = {}
        var newQuestionsHeadersRefs = []
        var newQuestionsAnswersRefs = []

        for(let i=0; i<questionsResponse.length;i++){
            answersFormShowNew[`${questionsResponse[i].id}`]=false
            currentQuestionsOrderDict[`${questionsResponse[i].id}`] = i
            newQuestionsHeadersRefs[i] = React.createRef()
            newQuestionsAnswersRefs[i] = React.createRef()
        }

        this.setState({
            questions:questionsResponse,
            questionsOrderDict: currentQuestionsOrderDict,
            questionsHeaderRefs: newQuestionsHeadersRefs,
            questionsAnswersFormRefs: newQuestionsAnswersRefs,
            answersFormShow:answersFormShowNew,
            nextUrl: response.next,
            previousUrl: response.previous,
        })

        document.body.scrollIntoView()
    }

    loadQuestions(){
        if(this.state.currentUrl === ""){
            apiQuestionsLookup(this.handleQuestionsDidLoad)
        }
        else{
            this.loadQuestionUrl(this.state.currentUrl)
        }
    }

    loadQuestionUrl(url)
    {
        apiQuestionsPageLookup(this.handleQuestionsDidLoad, url)
    }

    loadQuestion(questionId){
        apiQuestionLookup(this.handleUpdateQuestion, questionId)
    }

    loadAuth(){
        const authInfoRetrieved = (response) => {
            if(response){
                this.setState({
                    isAuth: response.is_auth === 'yes'
                })
            }
        }
        isUserAuth(authInfoRetrieved)
    }

    loadPageCount(){
        const handlePageCountRetrieved = (response) => {
            this.setState({
                pageCount:response.count
            })
        }

        apiQuestionsPageCount(handlePageCountRetrieved)
    }

    handleUpdateQuestion(response){
        if(response !== null){
            var newQuestions = this.state.questions
            var newQuestionsHeadersRefs = this.state.questionsHeaderRefs
            var newQuestionsAnswersFormRefs = this.state.questionsAnswersFormRefs
            var newAnswersFormShow = this.state.answersFormShow

            var currQuestionIdx = this.state.questionsOrderDict[`${response.id}`]         
            newQuestions[currQuestionIdx] = response
            newQuestionsHeadersRefs[currQuestionIdx] = React.createRef()
            newQuestionsAnswersFormRefs[currQuestionIdx] = React.createRef()
            newAnswersFormShow[`${response.id}`] = false

            this.setState({
                questions:newQuestions,
                questionsHeaderRefs: newQuestionsHeadersRefs,
                questionsAnswersFormRefs: newQuestionsAnswersFormRefs,
                answersFormShow: newAnswersFormShow
            })
        }
    }

    handleQuestionAskSubmit(){
        if(this.state.justAsk)
        {
            window.location.href = '/questions/'
        }else
        {
            this.loadPageCount()
            this.loadQuestions()
        }
    }

    handleQuestionDidDelete(){
        // there is just one question remaining therefore current page will be switched to previous one
        this.loadPageCount()
        if(this.state.questions.length === 1){
            if(this.state.pageNum === 1)
            {
                this.loadQuestions()
            }
            else
            {
                this.previousPageClicked()
            }
        }else{
            this.loadQuestions()
        }
    }

    handleQuestionShowAnswerFormShow(questionId){
        var answersFormShowNew = this.state.answersFormShow

        // hide previous one if shown
        if(this.questionAnswerFormShown !== -1){
            answersFormShowNew[`${this.questionAnswerFormShown}`] = false
        }

        // show new one
        this.questionAnswerFormShown = questionId
        answersFormShowNew[`${this.questionAnswerFormShown}`] = true

        this.setState({
            answersFormShow:answersFormShowNew
        })
    }

    handleQuestionShowAnserFormHide(questionId, headerQuestionRef){
        // hide answer form
        var answersFormShowNew = this.state.answersFormShow
        answersFormShowNew[`${questionId}`] = false

        // indicate that no answer form is shown
        this.questionAnswerFormShown = -1

        this.setState({
            answersFormShow:answersFormShowNew
        })

        headerQuestionRef.current.scrollIntoView()
    }

    handleQuestionLockUnlock(questionId){

        const data = {
            "is_locked": !this.state.questions[this.state.questionsOrderDict[`${questionId}`]].is_locked
        }
        console.log(data)

        apiQuestionPatch(this.handleUpdateQuestion, questionId, data)
    }

    handleQuestionAnswerSubmit(data, questionId){
        const handleAnswerDidCreate = (_response) => {
            this.loadQuestion(questionId)
            this.state.questionsHeaderRefs[this.state.questionsOrderDict[`${questionId}`]].current.scrollIntoView()
        }

        apiAnswerCreate(handleAnswerDidCreate, data, questionId)
    }

    handleQuestionAnswerDelete(questionId){
       this.loadQuestion(questionId)
    }

    nextPageClicked(){
        if(this.state.pageNum < this.state.pageCount){
            this.setState({
                pageNum: this.state.pageNum + 1,
                currentUrl: this.state.nextUrl
            })
            this.loadQuestionUrl(this.state.nextUrl)
        }
    }

    previousPageClicked(){
        if(this.state.pageNum > 1){
            this.setState({
                pageNum: this.state.pageNum - 1,
                currentUrl: this.state.previousUrl
            })
            this.loadQuestionUrl(this.state.previousUrl)       
        }
    }

    firstPageClicked(){
        const url = this.state.currentUrl ? this.state.currentUrl : (this.state.previousUrl ? this.state.previousUrl : this.state.nextUrl)
        if(url){
            const firstUrl = url.substr(0, url.indexOf("=")) + "=1"
            this.setState({
                pageNum: 1,
                currentUrl:firstUrl,
            })
            this.loadQuestionUrl(firstUrl)
        }
    }

    lastPageClicked(){
        const url = this.state.currentUrl ? this.state.currentUrl : (this.state.previousUrl ? this.state.previousUrl : this.state.nextUrl)
        if(url){
            const lastUrl = url.substr(0, url.indexOf("=")) + "=" + this.state.pageCount
            this.setState({
                pageNum: this.state.pageCount,
                currentUrl:lastUrl,
            })
            this.loadQuestionUrl(lastUrl)
        }
    }
 
    render(){
        return (
            <div className="container-full-h">
                <div className="container questions-container py-2">
                    <QuestionAsk
                        isQuestion={true}
                        show={true}
                        heading="Zeptej se"
                        onSubmitQuestion={this.handleQuestionAskSubmit}/>
                    <div key={`${this.state.questions.length}`}>
                        {this.state.questions.map((questionElement, index) => (
                            !this.state.justAsk && <Question
                            key={`${questionElement.question_key}-${this.state.answersFormShow[`${questionElement.id}`]}`}
                            questionElement={questionElement}
                            answerFormRef={this.state.questionsAnswersFormRefs[index]}
                            questionRef={this.state.questionsHeaderRefs[index]}
                            onDelete={this.handleQuestionDidDelete}
                            showAnswerForm={this.state.answersFormShow[`${questionElement.id}`]}
                            onAnswerFormShow={this.handleQuestionShowAnswerFormShow}
                            onAnswerFormHide={this.handleQuestionShowAnserFormHide}
                            onLockUnlock={this.handleQuestionLockUnlock}
                            onAnswerSubmit={this.handleQuestionAnswerSubmit}
                            onAnswerDelete={this.handleQuestionAnswerDelete}
                            isAuth={this.state.isAuth}/>
                        ))}
                    </div>
                    {!this.state.justAsk && <PageComponent
                        pageNum={this.state.pageNum}
                        pageCount={this.state.pageCount}
                        onPrevious={this.previousPageClicked}
                        onNext={this.nextPageClicked}
                        onFirst={this.firstPageClicked}
                        onLast={this.lastPageClicked}/>}
                </div>
            </div>)
    }
}
import GridViewComponent from '../../gridview/GridViewComponent'
import {apiLookupOfficers, apiDeleteOfficers} from './actions'

export default class OfficersGridViewComponent extends GridViewComponent{
    
    constructor(props){
        super(props)

        this.loadData = this.loadData.bind(this)
        this.dataDidLoad = this.dataDidLoad.bind(this)
    }

    componentDidMount(){
        this.loadData()
    }

    gridNewAction(){
        // implement new action here
        window.location.href = `/fish-admin/officers/create/`
    }

    gridDeleteAction(){ 
        // implement delete action here
        let dataDidDeleted = (response, status) => {
            if(status === 200){
                this.loadData()
            }else if(status === 400){
                alert(response.message)
            }else if(status === 500){
                alert("Něco se pokazilo, opakujte později.")
            }
        }
        
        var officersIds = new Set()
        for(let idx of this.state.selectedDataIdxs){
            officersIds.add(this.state.data[idx].id)
        }
        const officersToDelete = [...officersIds].join(",")

        this.setState({
            showDeleteModal:false,
        })

        apiDeleteOfficers(dataDidDeleted, officersToDelete)
    }

    gridRowHref(row){
        // construct href link to detail view here
        return `/fish-admin/officers/${row.id}`
    }

    /**
     * Callback used when officers are retrieved from server
     * @param {*} response 
     * @param {*} status 
     */
    dataDidLoad(response, status){
        if(status === 200){
           
            this.state.selectedDataIdxs.clear()
            this.setState({
                data:response,
                columns:this.state.columns.length > 0 ? this.state.columns :[
                    {
                        "name": "full_name",
                        "label": "Jméno",
                        "type": this.SEARCHTYPETEXT
                    },
                    {
                        "name": "badge_id",
                        "label": "Číslo odznaku",
                        "type": this.SEARCHTYPETEXT
                    },
                    {
                        "name": "valid_to_formated",
                        "label": "Platnost do",
                        "type": this.SEARCHTYPEDATE
                    },
                    {
                        "name": "issued_by_name",
                        "label": "Vydal",
                        "type": this.SEARCHTYPETEXT
                    },
                ],
                header: this.state.header ? this.state.header : 'Členové rybářské stráže',
                hrefColumn: this.state.hrefColumn ? this.state.hrefColumn :'full_name',
                searchColumn: this.state.searchColumn === "" ? 0 : this.state.searchColumn,
            })
        }else{
            alert("Něco se pokazilo, opakujte později")
        }
    }

    /**
     * Retrieves all the officers from 
     */
    loadData(){
        apiLookupOfficers(this.dataDidLoad)
    }
}
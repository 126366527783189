import React, {useState} from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {apiNewsCreate} from './actions'

// import czech translation
import '@ckeditor/ckeditor5-build-classic/build/translations/cs';

export function CKEditorComponent(props) {
    const {handleSaveCallback, isAuth} = props
    const defaultText = "<p>Text</p>" 
    const [text, setText] = useState(defaultText)
   
    const saveButtonDidClick = () => {
        if(text)
        {
            apiNewsCreate({"content":text}, handleSaveCallback)
            setText(defaultText)
        }
    }

    return(isAuth &&
        <div className="container mx-auto my-3 py-3">
            <CKEditor
                    editor={ ClassicEditor }
                    config={ {
                        language: 'cs',
                    } }
                    data={ text }
                    name='editor'
                    onChange={ ( _event, editor ) => {
                        setText(editor.getData());
                    } }
                />
            <div className="row justify-content-center mx-auto my-3 py-3">
                <div className="col text-center ">
                    <button type="button" className="btn btn-primary" onClick={saveButtonDidClick}>Uložit</button>
                </div>
            </div>
        </div>
    )
}

import React from "react";

import DictErrorListComponent from "./DictErrorListComponent";

export default class MultiLineDictErrorListComponent extends React.Component{
    /**
     * 
     * @param {*} props:
     * props.data contains list of lines displayd in grid [{obj1},...,{objN}]
     * props.keyColumns contains list of column names from which the line description should be composed
     * props.errorDict is dictionary containing all the errors for all the lines in props.data in format:
     * {
     *  id1: {
     *          'colName1':[errorsList for colName1 of object with id id1]
     *          'colName2':[errorsList for colName2 of object with id id1]
     *       }
     * }
     * props.labels contains the dictionary with labels for columns names in format 
     * {
     *  'column1':"Label for Collumn 1"
     * }
     * props.searchMap contains dictionary mapping the obect id to the it's index in the list
     * props.foreignColumns is dictionary where key is column name for which the value should be searched in different place and value is the 
     * dictionare where under "sourceMap" is the dictionary where key is id of object where to search and value is the object itself, under "keys" key is the
     * list of collums which should be concatenated together from the object to give final value
     *  Example:
     * {
     *  "officer":
     *      {
     *          "sourceMap":
     *              [{
     *                  "id":4
     *                  "name": Tomas
     *                  "surname": Vlk
     *              }]
     *          "keys": ['name', 'surname']
     *      }
     * }
     * 
     * 
     * 
     * props.dateColumns is list of columns names which should be formated into date like dd.mm.yyyy
     */
    constructor(props){
        super(props)

        this.lineDescription = this.lineDescription.bind(this)
    }

    lineDescription(rowIdx){
        const currentRow = this.props.data[rowIdx]
        var values = []

        // compose the line description
        for(var i = 0; i < this.props.keyColumns.length; i++){
            const currentCollumn = this.props.keyColumns[i]
            const originalValue = currentRow[currentCollumn]

            if(currentCollumn in this.props.foreignColumns){
                // find original value
                
                // search for column value
                const foreignColumnInfo = this.props.foreignColumns[currentCollumn]

                const sourceMap = foreignColumnInfo["sourceMap"]
                const keys = foreignColumnInfo["keys"]

                const currentObject = sourceMap[originalValue]
                if(currentObject){
                    values = values.concat(keys.map((columnKey, _columnKeyIdx) => ([currentObject[columnKey]])).join(' - '))
                }else{
                    values = values.concat(['Nevyplněno'])
                }
            }
            else{
                if(currentCollumn in this.props.dateColumns){
                    // format date
                    const d = new Date(originalValue)
                    values = values.concat([`${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`])
                }else{
                     // read collumn value from line
                    values = values.concat([originalValue])
                }
            }
        }

        return values.join(', ')
    }

    render(){
        return(
            <div>
                {Object.keys(this.props.errorDict).length > 0 &&
                    <div className="container">
                        {/* Iteration over rows */}
                        {Object.entries(this.props.errorDict).map(([errorObjKey, errorObjDict]) => (
                             <div className="container" key={`errorRow-${errorObjKey}`}>
                                 <b>{this.lineDescription(this.props.searchMap[errorObjKey])}:</b>
                                 <DictErrorListComponent
                                    key={`lineErrList-${errorObjKey}-${Object.entries(errorObjDict).length}`}
                                    errorDict={errorObjDict}
                                    labels={this.props.labels}/>
                            </div>
                        ))}
                    </div>
                }
            </div>
        )
    }
}
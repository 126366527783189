import React from "react";

/**
 * Menu template for various data grids
 * contains 4 pre-defined menu items {save, new, delete, cancel} and from props will recieve the callbacks for such actions
 */
export default class ActionMenu extends React.Component{

    render(){
        return(
            <div className="row grid-menu-area mb-2">
                <button 
                    type="button" 
                    className="btn btn-outline-dark grid-menu-btn grid-menu-btn-ml"
                    onClick={(_e) => this.props.saveCallback()}
                    disabled={!this.props.enabledCallback(this.props.saveBtnName)}>
                        <i className="bi bi-save"></i>&nbsp;Uložit
                </button>
                <button type="button" className="btn btn-outline-dark grid-menu-btn" 
                    onClick={(_e) => this.props.newCallback()}
                    disabled={!this.props.enabledCallback(this.props.newBtnName)}>
                        <i className="bi bi-plus"></i>&nbsp;Nový 
                </button>
                <button 
                    type="button" 
                    className="btn btn-outline-dark grid-menu-btn grid-menu-btn-ml"
                    onClick={(_e) => this.props.deleteCallback()}
                    disabled={!this.props.enabledCallback(this.props.deleteBtnName)}>
                        <i className="bi bi-trash"></i>&nbsp;Smazat 
                </button>
                <button 
                    type="button" 
                    className="btn btn-outline-dark grid-menu-btn grid-menu-btn-ml"
                    onClick={(_e) => this.props.cancelCallback()}
                    disabled={!this.props.enabledCallback(this.props.cancelBtnName)}>
                        <i className="bi bi-x"></i>&nbsp;Zrušit 
                </button>
            </div>
        )
    }
}
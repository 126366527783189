import React from "react";

import {translateErrorMessage} from '../../functions'

/**
 * Components displaying errors in list
 */
export default class ErrorListComponent extends React.Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <ul className="ul-no-bullets person-alert">
                        {this.props.errors.map((error, errorIdx) => (
                            <li key={`${errorIdx}`}>{translateErrorMessage(error)}</li>
                        ))}      
                    </ul> 
                </div>                    
            </div>
        )
    }
} 
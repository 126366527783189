import React from 'react';

export default class LookupComponent extends React.Component{

    constructor(props){
        super(props)
        this.defaultSelected = props.defaultSelected ? props.defaultSelected : []
        this.changedFromDefault = false
        this.data = props.data
        this.rowIdx = props.rowIdx
        this.state = ({
            showDropdown:false,
            lookupListWidth: 0,

            // value to display to the user in the input 
            lookupInputValue: props.defaultSelected ? props.defaultSelected.value : '',

            // valu for the searching
            lookupInputSearchValue: '',
        })

        this.lookupInputRef = React.createRef()
        this.wrapperRef = React.createRef()
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.lookupItemSelected = this.lookupItemSelected.bind(this)
        this.lookupInputOnModify = this.lookupInputOnModify.bind(this)
        this.lookupInputOnClick = this.lookupInputOnClick.bind(this)
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                showDropdown:false
            })
        }
    }

    lookupItemSelected(id, value){
        if(value !== this.state.lookupInputValue){
            this.changedFromDefault = true

            this.setState({
                lookupInputValue:value,
                showDropdown:false
            })

            if(this.props.onSelectCallback){
                if(this.rowIdx || this.rowIdx === 0){
                    this.props.onSelectCallback(id, this.rowIdx)
                }else{
                    this.props.onSelectCallback(id)
                }
            }
        }
        else{
            this.setState({
                lookupInputValue:value,
                showDropdown:false
            })
        }
    }

    lookupInputOnModify(value){
        this.changedFromDefault = true

        this.setState({
            lookupInputSearchValue:value,
            lookupInputValue:value,
        })

        // input is modified - select nothing
        if(this.props.onSelectCallback){
            if(this.rowIdx || this.rowIdx === 0){
                this.props.onSelectCallback(-1, this.rowIdx)
            }else{
                this.props.onSelectCallback(-1)
            }
        }
    }

    lookupInputOnClick(){
        this.changedFromDefault = true
        this.setState({
            showDropdown:!this.state.showDropdown
        })        
    }

    render(){
        return(
            <div className='col lookup-wrapper' ref={this.wrapperRef}>
                <div className="input-group">
                    <input 
                        ref={this.lookupInputRef}
                        type="text" 
                        className={this.props.readOnly ? "form-control" : this.props.error ? "form-select form-control border-danger" : "form-select form-control"}
                        readOnly={this.props.readOnly}
                        disabled={this.props.readOnly}
                        placeholder={this.props.placeholder ? this.props.placeholder : ''}
                        value={this.state.lookupInputValue ? this.state.lookupInputValue : ''}
                        onChange={(e) => {this.lookupInputOnModify(e.target.value)}}
                        onClick={(_e) => {this.lookupInputOnClick()}}/>            
                </div>
                {this.state.showDropdown &&
                <div className='lookup-div' style={{width:`${this.lookupInputRef.current ? this.lookupInputRef.current.offsetWidth : 80}px`}}>
                    <ul className='form-control lookup-ul'>
                        {this.data && this.data.filter(dataRow => dataRow.value.toLowerCase().includes(this.state.lookupInputSearchValue.toLowerCase())).map((dataRow, _idx) => (
                            <li key={`${dataRow.id}-${dataRow.value}-${this.state.lookupInputSearchValue}`}
                                onClick={(_e) => {this.lookupItemSelected(dataRow.id, dataRow.value)}}>{dataRow.value}</li>
                        ))}
                    </ul>
                </div>}
            </div>
        )
    }
}
import React from 'react'
import {
    apiLicensesSellYearLookup,
    apiLicensesSellDatesLookup,
    apiLicensesSellYearPatch,
    apiLicenseSellDateNew,
    apiLicensesSellYearCreate,
} from '../actions'

import {
    apiCurrencyLookup,
} from '../../global/actions'

import {isUserAuth} from '../../auth/components'

import LicensesSellDatesComponent from './LicensesSellDatesComponent'
import LicensesSellMemberTaxesComponent from './LicensesSellMemberTaxesComponent'
import LicensesSellPricesComponent from './LicensesSellPricesComponent'
import LicensesSellYearEditModal from './LicensesSellYearEditModal'

export default class LicensesSellComponent extends React.Component{

    constructor(props){
        super(props)

        this.state = ({
            licensesSellYear: null,
            licensesSellDates: [],
            showEditYearModal: false,
            newSellDateDate: "",
            newSellDateFrom: "",
            newSellDateTo: "",
            newSellDateErrorMsg: "test",
            newSellDateValid: true,
            currency: "",
            isUserAuth: false,
        })

        this.loadSellYear               = this.loadSellYear.bind(this)
        this.loadSellDates              = this.loadSellDates.bind(this)
        this.loadCurrency               = this.loadCurrency.bind(this)
        this.editYearBtnClick           = this.editYearBtnClick.bind(this)
        this.editYearModalConfirmed     = this.editYearModalConfirmed.bind(this)
        this.editYearModalCancelled     = this.editYearModalCancelled.bind(this)
        this.newSellDateSubmit          = this.newSellDateSubmit.bind(this)
        this.loadUserAuth               = this.loadUserAuth.bind(this)
    }

    componentDidMount(){
        this.loadUserAuth()
        this.loadSellYear()
        this.loadSellDates()
    }

    loadSellYear(){
        const sellYearDidLoaded = (response) => {
            if(response !== null){
                // load the 0 idx, as sell year can be just one
                this.setState({
                    licensesSellYear:response[0]
                })
            }
        }
        apiLicensesSellYearLookup(sellYearDidLoaded)
    }
   
    loadSellDates(){
        const sellDatesDidLoaded = (response) => {
            if(response !== null){
                // parse sell dates to group them according the month
                const sellDates = response
                var sellDatesPerMonth = {}
                
                for(var i = 0; i < sellDates.length; i++){
                    var sellDate = sellDates[i]
                    
                    if(sellDate.month in sellDatesPerMonth){
                        sellDatesPerMonth[sellDate.month].push(sellDate)
                    }else{
                        sellDatesPerMonth[sellDate.month] = [sellDate]
                    }
                }
                
                this.setState({
                    licensesSellDates: sellDatesPerMonth
                })
            }
        }
        apiLicensesSellDatesLookup(sellDatesDidLoaded)
    }

    loadCurrency(){
        const currencyDidLoad = (response) => {
            if(response != null){
                this.setState({
                    currency:response.currency
                })
            }
        }

        if(this.isUserAuth)
        {
            apiCurrencyLookup(currencyDidLoad)
        }
    }

    loadUserAuth(){
        const userAuthDidLoad = (response, status) => {
            if(status === 200){
                if(response.is_auth && response.is_auth === "yes"){
                    this.setState({
                        isUserAuth: true,
                    })
                    this.loadCurrency()
                }
            }else{
                this.setState({
                    isUserAuth: false,
                })
            }      
        }

        isUserAuth(userAuthDidLoad) 
    }

    editYearBtnClick(){
        this.setState({
            showEditYearModal:true
        })
    }

    editYearModalConfirmed(value){
       
        if(this.state.licensesSellYear){
            const data = {
                'id': this.state.licensesSellYear.id,
                'year': value
            }
            apiLicensesSellYearPatch(this.loadSellYear, this.state.licensesSellYear.id, data)
        }
        else
        {
            const data = {
                'year': value
            }
            apiLicensesSellYearCreate(this.loadSellYear, data)
        }

        this.setState({
            showEditYearModal:false
        })
    }

    editYearModalCancelled(){

        this.setState({
            showEditYearModal:false
        })
    }

    newSellDateSubmit(){
        var valid = true

        const newSellDateDidCreate = () => {
            // clear form data
            this.setState({
                newSellDateDate:"",
                newSellDateFrom:"",
                newSellDateTo:"",
                newSellDateErrorMsg: "",
                newSellDateValid:true
            })

            this.loadSellDates()
        }

        //validate
        var newSellDateDate = new Date(this.state.newSellDateDate)
        if(this.state.licensesSellYear){
            if(newSellDateDate.getFullYear() !== this.state.licensesSellYear.year){
                valid = false
                this.setState({
                    newSellDateErrorMsg: "Rok nesouhlasí s prodejním rokem",
                    newSellDateValid: false
                })
            }
        }

        if(this.state.newSellDateFrom === "" || this.state.newSellDateTo === "" || this.state.newSellDateDate === ""){
            valid = false
            this.setState({
                newSellDateErrorMsg: "Všechna pole jsou povinná!",
                newSellDateValid: false
            })
        }

        if(valid)
        {
            
            const newSellDateData = {
                "sell_day":this.state.newSellDateDate,
                "from_time":this.state.newSellDateFrom,
                "to_time":this.state.newSellDateTo
            } 

            apiLicenseSellDateNew(newSellDateDidCreate, newSellDateData)    
        }   
    }

    render(){
        return(
            <div className="container-full-h"> 
                <div className="container licenses-container">
                    <div className="container licenses-container-wrapper">
                        <div className="d-flex justify-content-between">
                            <span/>
                            <h3>Termíny prodeje povolenek pro rok {this.state.licensesSellYear ? this.state.licensesSellYear.year : ""}</h3>
                            {this.state.isUserAuth &&
                            <button type="button" className="btn btn-outline-dark btn-sm btn-sell-year-change" onClick={this.editYearBtnClick}>
                                <i className="bi bi-pencil-fill"></i>
                                &nbsp; Upravit rok
                            </button>}
                            {!this.state.isUserAuth && <span/>}
                        </div>
                        <div className="container">
                            <div className = "row">
                            {Object.keys(this.state.licensesSellDates).map((key, _index) => (
                                <LicensesSellDatesComponent 
                                    key={`month-${key}-${this.state.licensesSellDates[key].length}`}
                                    sellMonth={key}
                                    sellDates={this.state.licensesSellDates[key]}
                                    refreshSellDatesCallback={this.loadSellDates}
                                    isUserAuth={this.state.isUserAuth}
                                />
                            ))}
                            </div>
                            {this.state.isUserAuth &&
                            <div className="container">
                                <hr/>
                                <div className="d-flex justify-content-center">
                                    <h5>Nový termín</h5>
                                </div>

                                <form>
                                    <div className="form-group mt-3">
                                        <div className="d-flex justify-content-center">
                                            <label className="sell-date-label">Datum</label>                
                                            <input 
                                                className="form-control sell-date-input" 
                                                type="date" 
                                                value={this.state.newSellDateDate} 
                                                onChange={(e) => (this.setState({newSellDateDate:e.target.value}))}/>    
                                        </div>     
                                    </div>

                                    <div className="form-group mt-3">
                                        <div className="d-flex justify-content-center">
                                            <label className="sell-date-label">Od</label>                
                                            <input 
                                                className="form-control sell-date-input" 
                                                type="time" 
                                                value={this.state.newSellDateFrom}
                                                onChange={(e) => (this.setState({newSellDateFrom:e.target.value}))}/>  
                                        </div>     
                                    </div>

                                    <div className="form-group mt-3">
                                        <div className="d-flex justify-content-center">
                                            <label className="sell-date-label">Do</label>                
                                            <input 
                                                className="form-control sell-date-input" 
                                                type="time" 
                                                value={this.state.newSellDateTo} 
                                                onChange={(e) => (this.setState({newSellDateTo:e.target.value}))}/>  
                                        </div>     
                                    </div>
                                </form>
                                <div className="container mt-3">
                                    {!this.state.newSellDateValid &&
                                        <div className="d-flex justify-content-center">
                                            <p className="alert alert-danger sell-date-error" role="alert">
                                                {this.state.newSellDateErrorMsg}
                                            </p>
                                        </div>
                                    } 
                                    <div className="d-flex justify-content-center mt-1">
                                        <button className="btn btn-secondary" onClick={this.newSellDateSubmit}>Přidat termín</button>
                                    </div>
                                </div>
                            </div>}

                        </div>
                    </div>
                    <LicensesSellMemberTaxesComponent
                        sellYear={this.state.licensesSellYear ? this.state.licensesSellYear.year : ""}
                        currency={this.state.currency}/>
                    <LicensesSellPricesComponent
                        sellYear={this.state.licensesSellYear ? this.state.licensesSellYear.year : ""}
                        currency={this.state.currency}/>
                    <LicensesSellYearEditModal
                        show={this.state.showEditYearModal}
                        onBtn1={this.editYearModalConfirmed}
                        onBtn2={this.editYearModalCancelled}
                        sellYear={this.state.licensesSellYear ? this.state.licensesSellYear.year : 0}
                    />
            </div>
        </div>
        )
    }
}
import React from 'react';

import '../../css/albumcards.css'

export default class AlbumCard extends React.Component {

    render() {
        return (
                <div className="container py-3">
                    <div className="card albumcard">
                        <img className="card-img-top album-img-preview" src={this.props.src} alt=''/>
                        <div className="d-flex justify-content-center">
                            <h5 className="card-title">{this.props.title}</h5>        
                        </div>   
                        <div className="d-flex justify-content-center">
                            {this.props.region ? this.props.region : <span>&nbsp;</span>}
                        </div>   
                        <div className="d-flex justify-content-around p-2">
                            <span/>
                            <a href={this.props.btnHref ? this.props.btnHref : '#'} className="btn btn-outline-dark">{this.props.btnLabel ? this.props.btnLabel : "Detail"}</a> 
                            {this.props.isAuth && <button className="btn btn-outline-dark" onClick={() => this.props.onDelete(this.props.albumId)}><i className="bi bi-trash">&nbsp; Smazat</i></button>}
                            {!this.props.isAuth && <span></span>}      
                        </div>                                  
                    </div>        
                </div>
              )       
    }
}
import React from "react";

import {
    apiCreateOfficer,
    apiPatchOfficer,
    apiDeleteOfficer,
    apiLookupOfficer,
    
} from './actions'

import {
    apiLookupIssuingOrganizations
} from '../../global/actions'

import LookupComponent from '../../lookup/LookupComponent'
import ErrorListComponent from "../../global/components/error_components/ErrorListComponent";
import ModalDialog from "../../modal/components/ModalDialog";

export default class OfficerDetailComponent extends React.Component{

    constructor(props){
        super(props)

        this.state = ({
            // id of current officer, when new one should be created is empty string
            officerId:props.officerId,

            // data for current officer
            officerData:{},

            // issuing organizations data
            issuingOrg:[],
            issuingOrgMap:{},
            issuingOrgRefCount: 0,
            issuingOrgDefault: {},

            // errors recieved from server during update or creation of officer, map where key is field name and value is list with errors
            errors:{},

            // indicates when data was modified
            modified:false,

            // show delete modal
            showDelete:false,
        })

        //method binding
        this.loadIssuingOrganizations = this.loadIssuingOrganizations.bind(this)
        this.officerModifiedField = this.officerModifiedField.bind(this)
        this.save = this.save.bind(this)
        this.cancel = this.cancel.bind(this)
        this.issuingOrgOnSelect = this.issuingOrgOnSelect.bind(this)
        this.loadOfficerDetail = this.loadOfficerDetail.bind(this)
        this.delete = this.delete.bind(this)
    }
 
    componentDidMount(){
        this.loadIssuingOrganizations()

        // in case officer id is provided load related information
        if(this.state.officerId){
            this.loadOfficerDetail()
        }
    }

    /**
     * will retrieve all the issuing organizations from server and convert them for the LookupComponent purposes
     */
    loadIssuingOrganizations(){
        let issuingOrgDidLoaded = (response, status) => {
            if(status === 200){
                this.setState({
                    issuingOrg:response.map((organization, _idx) => ({id:organization.id, value:organization.name})),
                    issuingOrgMap:Object.assign({}, ...response.map((organization) => ({[organization.id]: organization.name}))),
                    issuingOrgRefCount:this.state.issuingOrgRefCount + 1
                })
            }else{
                alert("Něco se pokazilo, opakujte později.")
            }
        }
        apiLookupIssuingOrganizations(issuingOrgDidLoaded)
    }

    /**
     * will retrieve the officer detail information from server
     */
    loadOfficerDetail(){
        let officerDataDidLoad = (response, status) => {
            if(status === 200){
                this.setState({
                    officerData:response,
                    officerDataOriginal:JSON.parse(JSON.stringify(response)),
                    errors:[],
                })
            }else if(status === 404){
                alert("Zaznam nebyl nalezen, opakujte později.")
            }
            else{
                alert("Něco se pokazilo, opakujte později.")
            }
        }

        apiLookupOfficer(officerDataDidLoad, this.state.officerId)
    }

    /**
     * Handles the modification of field value in officer data
     * @param {string} fieldName - name of field which was modified
     * @param {any} value - new value of the field
     */
    officerModifiedField(fieldName, value){
        const currentOfficerData = JSON.parse(JSON.stringify(this.state.officerData))
        currentOfficerData[fieldName] = value

        this.setState({
            officerData:currentOfficerData,
            modified:true
        })
    }

    /**
     * Perform the save/create action
     */
    save(){
        var data = JSON.parse(JSON.stringify(this.state.officerData))

        let dataDidSaved = (response, status) => {
            if(status === 201 || status === 200){
                this.setState({
                    officerId:response.id,
                    officerData:response,
                    officerDataOriginal: JSON.parse(JSON.stringify(response)),
                    errors:{},
                    modified:false
                })
            }else if(status === 400){
                this.setState({
                    errors:response
                })
            }else{
                alert("Něco se pokazilo, opakujte později.")
            }
        }

        if(!this.state.officerId)
        {
            if(!data.issued_by){
                data.issued_by = ''
            }else{
                data.issued_by = data.issued_by.id ? data.issued_by.id : data.issued_by
            }

            apiCreateOfficer(dataDidSaved, data)
        }else{
            if(this.state.modified){
                if(!data.issued_by || data.issued_by === -1){
                    data.issued_by = ''
                }

                apiPatchOfficer(dataDidSaved, this.state.officerId, data)
            }
        }
    }

    /**
     * Perform the cancel action
     */
    cancel(){
        this.setState({
            officerData:this.state.officerDataOriginal,
            modified:false,
            errors:[],
            issuingOrgRefCount:this.state.issuingOrgRefCount + 1,
        })
    }

    /**
     * Perform delete action
     */
    delete(){
        let officerDidDelete = (_response, status) => {
            if(status === 204){
                window.location.href = "/fish-admin/officers/"
            }else{
                alert("Něco se pokazilo, opakujte později")
            }
        }

        this.setState({
            showDelete:false
        })

        if(this.state.officerId){
            apiDeleteOfficer(officerDidDelete, this.state.officerId)
        }
    }

    /**
     * Callback used when Issuing organization is selected
     * @param {int} orgId - unique id of Issuing organization 
     */
    issuingOrgOnSelect(orgId){
        this.officerModifiedField('issued_by', orgId)
    }

    render(){
        return(
            <div className="container licenses-container-wrapper">
                <div className='container mb-3'>
                    <a href='/fish-admin/officers/'>{"< všichni členové stráže"}</a>
                </div>
                <div className="d-flex justify-content-between mb-3">
                    <span/>
                    {this.state.officerId && <h3>Rybářská stráž</h3>}
                    {!this.state.officerId && <h3>Nová rybářská stráž</h3>}
                    
                    <div className="person-header-btn-group">
                        <div className='btn-group'>
                            {this.state.officerId && 
                            <button type='button' className='btn btn-outline-danger person-btn'
                                onClick={(_e)=>(this.setState({showDelete:true}))}>Smazat</button>}
                            {this.state.modified &&
                            <button type='button' className='btn btn-outline-danger person-btn'
                                onClick={(_e)=>(this.cancel())}>Zrušit</button>}
                            {(this.state.modified || !this.state.officerId) &&
                            <button type='button' className='btn btn-outline-dark person-btn'
                                onClick={(_e)=>(this.save())}>Uložit</button>}
                        </div>
                    </div>
                </div>

                <form method="POST">
                    <div className="container officers-form">
                        <div className="mb-3">
                            <b>Příjmení</b>
                            <input type="text" className="form-control"
                                value={this.state.officerData.surrname ? this.state.officerData.surrname : ''}
                                onChange={(e) => (this.officerModifiedField('surrname', e.target.value))}/>
                            <ErrorListComponent
                                key={this.state.errors['surrname']}
                                errors={this.state.errors['surrname'] ? this.state.errors['surrname'] : []}/>
                        </div>
                        <div className="mb-3">
                            <b>Jméno</b>
                            <input type="text" className="form-control"
                                value={this.state.officerData.name ? this.state.officerData.name : ''}
                                onChange={(e) => (this.officerModifiedField('name', e.target.value))}/>
                            <ErrorListComponent
                                key={this.state.errors['name']}
                                errors={this.state.errors['name'] ? this.state.errors['name'] : []}/>
                        </div>
                        <div className="mb-3">
                            <b>Číslo odznaku</b>
                            <input type="text" className="form-control"
                                value={this.state.officerData.badge_id ? this.state.officerData.badge_id : ''}
                                onChange={(e) => (this.officerModifiedField('badge_id', e.target.value))}/>
                            <ErrorListComponent
                                key={this.state.errors['badge_id']}
                                errors={this.state.errors['badge_id'] ? this.state.errors['badge_id'] : []}/>
                        </div>
                        <div className="mb-3">
                            <b>Platnost do</b>
                            <input type="date" className="form-control"
                                value={this.state.officerData.valid_to ? this.state.officerData.valid_to : ''}
                                onChange={(e) => (this.officerModifiedField('valid_to', e.target.value))}/>
                            <ErrorListComponent
                                key={this.state.errors['valid_to']}
                                errors={this.state.errors['valid_to'] ? this.state.errors['valid_to'] : []}/>
                        </div>
                        <div className="mb-3">
                            <b>Vydal</b>
                            <LookupComponent
                                key={`${this.state.issuingOrg.length}-${this.state.issuingOrgRefCount}-${this.state.officerData.issued_by}`}
                                onSelectCallback={this.issuingOrgOnSelect}
                                defaultSelected={{
                                    id:this.state.officerData.issued_by ? this.state.officerData.issued_by : 0,
                                    value:this.state.officerData.issued_by ? this.state.issuingOrgMap[this.state.officerData.issued_by] : ""
                                }}
                                data={this.state.issuingOrg}
                                placeholder="Vydávající úřad"/>
                            <ErrorListComponent
                                key={this.state.errors['issued_by']}
                                errors={this.state.errors['issued_by'] ? this.state.errors['issued_by'] : []}/>
                        </div>
                    </div>
                </form>
                <ModalDialog
                    show={this.state.showDelete}
                    message="Smazat?"
                    subMessage="Opravdu smazat člena rybářské stráže?"
                    onBtn1={(e) => this.delete()}
                    onBtn2={(e) => (this.setState({showDelete:false}))}/>
            </div>
        )
    }

}
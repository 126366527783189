import React from 'react'

export function PageComponent(props){
    const {onNext, onPrevious, pageNum, pageCount} = props
    const classDisabled = "page-item disabled"
    const classEnabled = "page-item"

    return(
        <div className="container">
        <nav>
            <ul className="pagination justify-content-center btn-group m-3">
                <li className={pageNum !== 1 ? classEnabled : classDisabled}>
                    <button type="button" className="page-link page-link-text" onClick={props.onFirst ? props.onFirst : () => {}}>1</button>  
                </li>
                <li className={pageNum !== 1 ? classEnabled : classDisabled}>
                    <button type="button" className="page-link page-link-text" onClick={onPrevious}>Předchozí</button>            
                </li>

                <li className="page-item disabled">
                    <button type="button" className="page-link page-link-text">{pageNum}/{pageCount === 0 ? 1 : pageCount}</button>
                </li>
                <li className={(pageNum !== pageCount && pageCount !== 0) ? classEnabled : classDisabled}>
                    <button type="button" className="page-link page-link-text" onClick={onNext}>Následující</button>
                </li>
                <li className={(pageNum !== pageCount && pageCount !== 0)? classEnabled : classDisabled}>
                    <button type="button" className="page-link page-link-text" onClick={props.onLast ? props.onLast : () => {}}>
                        {pageCount === 0 ? 1 : pageCount}
                    </button>               
                </li>
            </ul>
        </nav>
    </div>
    )
}
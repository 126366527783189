import React, { useEffect, useState } from 'react';

import { 
    apiLookupRegionSummaryYears, 
    apiLookupRegion,
    apiLookupRegionYearSummary,
    apiLookupAttendances,
} from './actions';

export function RegionYearSummary(props){

    const region = props.regionId
    const [years, setYears] = useState([])
    const [regionObj, setRegionObj] = useState({})
    const [catchData, setCatchData] = useState([])
    const [attData, setAttData] = useState([])
    const [sumData, setSumData] = useState({})

    useEffect(() => {
        if(region){
            lookupYearsForRegion() 
            lookupRegion()
        }
    },[])

    const lookupYearsForRegion = () => {
        const yearsForRegionDidFetch = (response, status) => {
            if(status===200){
                setYears(response)
                // recieved years from server are sorted from the newest one
                if(response[0]){
                    loadSummaryForYear(response[0].year)
                }
            }
        }
        apiLookupRegionSummaryYears(region, yearsForRegionDidFetch)
    }

    const lookupRegion = () => {
        const regionDidFetched = (response, status) => {
            if(status===200){
                setRegionObj(response)
            }
        }
        apiLookupRegion(region, regionDidFetched)
    }

    const lookupRegionYearSummary = (year) => {
        const regionYearSummaryDidFetched = (response, status) => {
            if(status === 200){
                const data = response.map(obj => Object.assign(obj, {
                    'average_weight':Number((obj.total_weight / obj.total_amount).toFixed(2))
                }))
                
                let localSumData = {
                    'name': "Celkem",
                    'total_amount':0,
                    'total_weight': 0,
                    'average_weight': 0,
                }

                data.forEach(element => {
                    localSumData.total_amount += element.total_amount
                    localSumData.total_weight += element.total_weight
                });

                localSumData.total_weight = Number(localSumData.total_weight.toFixed(2))
                localSumData.average_weight = Number((localSumData.total_weight / localSumData.total_amount).toFixed(2))

                setCatchData(data)
                setSumData(localSumData)
            }
        }
        apiLookupRegionYearSummary(region, year, regionYearSummaryDidFetched)
    }

    const lookupAttendancesData = (year) => {
        const attendacesDidFetched = (response, status) => {
            if(status===200){
                console.log(response)
                setAttData(response)
            }
        }
        apiLookupAttendances(region, year, attendacesDidFetched)
    }

    const loadSummaryForYear = (year) => {
        lookupRegionYearSummary(year)
        lookupAttendancesData(year)
    }

    if(region && years.length > 0){
        return(
            <div className="container licenses-container-wrapper">
                <div className="d-flex justify-content-center">
                    <h6 className='d-inline pt-2'>Pro rok</h6>
                    <select className="form-select region-year-select" onChange={(e) => loadSummaryForYear(e.target.value)}>
                        {years.map((yearObject, _idx) => (
                             <option value={yearObject.year} key={yearObject.year}>{yearObject.year}</option>
                        ))}
                    </select>
                </div>

                <div className='d-flex justify-content-center mt-3'>
                    <h5>{`${regionObj.region_number} - ${regionObj.name} - MO Klášterec nad Orlicí`}</h5>
                </div>

                <div className='d-flex justify-content-center'>
                    <table className="table table-sm region-summary-table">
                        <thead>
                            <tr>
                                <th scope="col">Druh</th>
                                <th scope="col">Počet kusů</th>
                                <th scope="col">Váha - kg</th>
                                <th scope="col">Prům. váha kusu - kg</th>
                            </tr>
                        </thead>
                        <tbody>
                             {catchData.map((rowInfo, idx) => (
                                <tr key={`row-${idx}`} className={idx===catchData.length-1 ? 'table-sum-row': ''}>
                                  <td>{rowInfo.name}</td>
                                  <td>{rowInfo.total_amount}</td>
                                  <td>{rowInfo.total_weight}</td>
                                  <td>{rowInfo.average_weight}</td>
                                </tr>
                             ))}
                             {sumData.name &&
                                <tr key={`row-sum`}>
                                    <th><i>{sumData.name}</i></th>
                                    <th><i>{sumData.total_amount}</i></th>
                                    <th><i>{sumData.total_weight}</i></th>
                                    <th><i>{sumData.average_weight}</i></th>
                                </tr>
                            }
                            {attData.length === 1 &&
                             <tr key={`row-attendance`}>
                                <th><i>Docházek</i></th>
                                <th colSpan="3"><i>{attData[0].total_attendances}</i></th>
                             </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>)
    }
    else
    {
        return(
            <div className="container licenses-container-wrapper">
                <div className="d-flex justify-content-between">
                    <span/>
                    <h5>Jejda, nejsou tu žádná data</h5>
                    <span/>
                </div>
            </div>)
    }
}
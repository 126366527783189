import {backendRequest} from '../../backend'

export function apiLookupIssuingOrganizations(callback){
    return backendRequest("GET", "/fish-admin/issuing-organizations/", callback, {})
}

export function apiLookupIssuingOrganizationDetail(callback, id){
    return backendRequest("GET", `/fish-admin/issuing-organizations/${id}/`, callback)
}

export function apiCreateIssuingOrganization(callback, data){
    return backendRequest("POST", "/fish-admin/issuing-organizations/", callback, data)
}

export function apiPatchIssuingOrganization(callback, id, data){
    return backendRequest("PATCH", `/fish-admin/issuing-organizations/${id}/`, callback, data)
}

export function apiDeleteIssuingOrganization(callback, id){
    return backendRequest("DELETE", `/fish-admin/issuing-organizations/${id}/`, callback, {})
}

export function apiDeleteMultipleIssuingOrganizations(callback, data){
    return backendRequest("POST", '/fish-admin/issuing-organizations/delete/', callback, data)
}
import React from "react"
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

// import czech translation
import '@ckeditor/ckeditor5-build-classic/build/translations/cs'
import '../../css/modal.css'

export default class NewsEditModal extends React.Component {

    constructor(props){
        super(props)
        this.state = {editorText: ''}
    }

    render() {
        if(!this.props.show){
            return null
        }else{
            return (
                <div className="my-modal">
                    <div className="modal-content">
                        <div className='d-flex justify-content-center'>
                            <h3>Upravit článek</h3>
                        </div>    
                        <div className='container'>
                            <CKEditor
                                editor={ ClassicEditor }
                                config={ {
                                    language: 'cs',
                                } }
                                data={this.props.content}
                                onChange={ ( _event, editor ) => {
                                    this.setState({
                                        editorText:editor.getData()
                                    })
                                    //this.state.editorText=editor.getData()
                                } }
                                name='editor'/>
                                    
                            <div className='d-flex justify-content-center py-2'>
                                <div className="btn-group" role="group">
                                    <button 
                                        className= {this.props.btn1ClassName ? this.props.btn1ClassName : 'btn btn-danger'}
                                        type='button'
                                        onClick={() => {this.props.onBtn1(this.state.editorText)}}>{this.props.btn1Lbl ? this.props.btn1Lbl : 'Uložit'}</button>
                                    <button 
                                        className={this.props.btn2ClassName ? this.props.btn2ClassName : 'btn btn-outline-secondary'}
                                        type='button' 
                                        onClick={() => {this.props.onBtn2()}}>{this.props.btn2Lbl ? this.props.btn2Lbl : 'Zrušit'}</button>             
                                </div>
                            </div>
                        </div>
                    </div>                    
              </div>)
        }
    }
}
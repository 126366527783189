import React from 'react'
import Answer from './Answer'
import ModalDialog from '../../modal/components/ModalDialog'
import QuestionAsk from './QuestionAsk'

import '../../css/questions.css'
import { apiQuestionDelete} from '../actions'

export default class Question extends React.Component{
    constructor(props){
        super(props)
    
        this.lockQuestionTxt = "Uzamknout otázku?"
        this.unlockQuestionTxt = "Odemknout otázku?"
        this.lockQuestionSubTxt = "Po uzamčení nebude možné odpovídat."
        this.unlockQuestionSubTxt = "Umožní opět odpovídat na otázku."
        this.answerFormRef = this.props.answerFormRef
        this.questionHeaderRef = this.props.questionRef
        this.questionId = props.questionElement.id
        this.questionElement = props.questionElement
        this.state={
            showDeleteModal: false,
            showLockUnlockModal: false,
        }

        this.handleDeleteClicked                = this.handleDeleteClicked.bind(this)
        this.handleDeleteConfirm                = this.handleDeleteConfirm.bind(this)
        this.handleDeleteCancelled              = this.handleDeleteCancelled.bind(this)    
        this.handleLockUnlockClicked            = this.handleLockUnlockClicked.bind(this)
        this.handleLockUnlockConfirmed          = this.handleLockUnlockConfirmed.bind(this)
        this.handleLockUnlockCancelled          = this.handleLockUnlockCancelled.bind(this)   
        this.handleAnswerDidDelete              = this.handleAnswerDidDelete.bind(this)
        this.handleQuestionAnswerClicked        = this.handleQuestionAnswerClicked.bind(this)
        this.handleQuestionAnswerCloseClicked   = this.handleQuestionAnswerCloseClicked.bind(this)
        this.handleAnswerSubmit                 = this.handleAnswerSubmit.bind(this)
    }

    componentDidMount(){
        if(this.props.showAnswerForm){
            this.answerFormRef.current.scrollIntoView()
        }
    }

    // delete
    handleDeleteClicked(){
        this.setState({
            showDeleteModal:true
        })
    }

    handleDeleteConfirm(){
        apiQuestionDelete(this.props.onDelete, this.questionElement.id)

        this.setState({
            showDeleteModal:false
        })
    }

    handleDeleteCancelled(){
        this.setState({
            showDeleteModal:false
        })
    }

    // lock unlock
    handleLockUnlockClicked(){
        if(this.props.isAuth === true){
            this.setState({
                showLockUnlockModal:true
            })
        }
    }

    handleLockUnlockConfirmed(){
        if(this.props.isAuth === true){
            this.setState({
                showLockUnlockModal:false
            })
            
            this.props.onLockUnlock(this.questionId)
        }
    }

    handleLockUnlockCancelled(){
        if(this.props.isAuth === true){
            this.setState({
                showLockUnlockModal:false
            })
        }
    }

    // child answer deleted
    handleAnswerDidDelete(){
        this.props.onAnswerDelete(this.questionId)
    }

    handleAnswerSubmit(data){
        this.props.onAnswerSubmit(data, this.questionId)
    }

    // answer to the question
    handleQuestionAnswerClicked(){
        this.props.onAnswerFormShow(this.questionId)
    }

    handleQuestionAnswerCloseClicked(){
        this.props.onAnswerFormHide(this.questionId, this.questionHeaderRef)
    }

    render(){
        return(
            <div className="container question-wrapper" ref={this.questionHeaderRef}>
                <div className="row question-grid">
                    <div className="d-flex justify-content-between">
                        <h4>{this.questionElement.title}</h4>
                        <span/>
                        <div>
                            {this.questionElement.is_locked && 
                            <button type="button" className={this.props.isAuth === true ? "lock-button" : "lock-button-notAuth"} disabled={this.props.isAuth === false} onClick={() => this.handleLockUnlockClicked()}>
                                <i className="bi bi-lock" title="Otázka je uzamčena a nelze na ní odpovídat."></i>
                            </button>}
                            {!this.questionElement.is_locked && this.props.isAuth &&
                            <button type="button" className={this.props.isAuth === true ? "unlock-button margin-left-1" : "unlock-button-notAuth margin-left-1"} disabled={this.props.isAuth === false} onClick={() => this.handleLockUnlockClicked()}>
                                <i className="bi bi-unlock" title="Uzamknout otázku"></i>
                            </button>}
                            {!this.questionElement.is_locked && this.props.isAuth &&
                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.handleQuestionAnswerClicked()}>Odpovědět</button>
                            }
                            {this.props.isAuth &&
                            <button type="button" className="trash-button margin-left-1" onClick={() => this.handleDeleteClicked()}>
                                <i className="bi bi-trash" title="Smazat"></i>
                            </button>
                            }
                        </div>
                    </div>
                    <div className="d-flex justify-content-left">
                        <p><b>{this.questionElement.author_name + ' ' + this.questionElement.author_surname}</b> {' - ' + this.questionElement.date}</p>
                    </div>
                    <hr/>
                    <div className="d-flex justify-content-left">
                    <p>{this.questionElement.content}</p>
                    </div>
                    {this.questionElement.answers.length > 0 &&
                    <div className="container answer-header"> 
                        <div className="row">
                            <h5>Odpovědi:</h5>
                        </div>   
                        {this.questionElement.answers.map((answerElement, index) => (
                            <Answer
                                key={`${index}-${answerElement.id}`}
                                answerElement={answerElement}
                                onDelete={this.handleAnswerDidDelete}
                                isAuth={this.props.isAuth}/>
                        ))}
                    </div>}
                    <div className="container answer-header" ref={this.answerFormRef}> 
                        <div className="row answer-row"> 
                            <QuestionAsk
                                title={this.questionElement.title}
                                questionId={this.questionId}
                                show={this.props.showAnswerForm}
                                isQuestion={false}
                                onAnswerClose={this.handleQuestionAnswerCloseClicked}
                                onSubmitAnswer={this.handleAnswerSubmit}
                                heading="Odpovědět"/>
                        </div>
                    </div>
                </div>
                <ModalDialog
                    key="DeleteDialog"
                    show={this.state.showDeleteModal}
                    message="Smazat otázku?"
                    subMessage="Smazáním otázky smažete i všechny existující odpovědi."
                    onBtn1={this.handleDeleteConfirm}
                    onBtn2={this.handleDeleteCancelled}/>

                <ModalDialog
                    key="LockUnlockDialog"
                    show={this.state.showLockUnlockModal}
                    message={this.questionElement.is_locked ? this.unlockQuestionTxt : this.lockQuestionTxt}
                    subMessage={this.questionElement.is_locked ? this.unlockQuestionSubTxt : this.lockQuestionSubTxt}
                    onBtn1={this.handleLockUnlockConfirmed}
                    onBtn2={this.handleLockUnlockCancelled}/>
            </div>
        )
    }
}
import {backendRequest, backendRequestFormData} from '../../backend'

export function apiAlbumsLookup(callback){
    return backendRequest("GET", '/photogallery/albums/', callback, {})
}

export function apiAlbumLookup(callback, album_id){
    return backendRequest("GET", `/photogallery/albums/${album_id}/`, callback, {})
}

export function apiAlbumCreate(callback, albumName){
    return backendRequest("POST", '/photogallery/albums/create/', callback, {name: albumName})
}

export function apiAlbumCreateV2(callback, data){
    return backendRequest("POST", '/photogallery/albums/create/', callback, data) 
}

export function apiAlbumDelete(callback, album_id){
    return backendRequest("DELETE", `/photogallery/albums/${album_id}/`, callback, {})
}

export function apiAlbumPhotosUpload(callback, album_id, data){
    return backendRequestFormData("POST", `/photogallery/albums/${album_id}/upload/`, callback, data)
}

export function apiAlbumPhotosDelete(callbact, data){
    return backendRequest("DELETE", '/photogallery/photos/delete/', callbact, data)
}

export function apiAlbumPatch(callback, album_id, data){
    return backendRequest("PATCH", `/photogallery/albums/${album_id}/`, callback, data)
}

export function apiLookupAlbumsPage(callback, pageNum){
    return backendRequest("GET", `/photogallery/albums/?page=${pageNum}`,callback, {})
}

export function apiLookupAlbumsRegion(callback, regionId){
    return backendRequest("GET", `/photogallery/albums/region/${regionId}/`, callback, {})
}
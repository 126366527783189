import React from 'react';
import LookupComponent from './../../lookup/LookupComponent'
import CollapsablePane from './CollpsablePane'
import ModalDialog from '../../modal/components/ModalDialog'

import { 
    apiLookupPersonDetail, 
    apiPatchPersonDetail,
    apiCreatePersonDetail,
    apiDeletePersonDetail,
    apiZtpCreate,
    apiZtpDelete,
    apiZtpPatch,
    apiLookupIssuingOrganizations,
    apiPatchFishingTicket,
    apiDeleteFishingTicket,
    apiCreateFishingTicket,
    apiCreatePersonHistory,
    apiPatchPersonHistory,
    apiDeletePersonHistory,
    apiCreateFishingLicense,
    apiPatchFishingLicense,
    apiDeleteFishingLicense,
} from './actions';

import { 
    apiLookupSexOptions,
    apiLookupAgeCategories,
    apiGetFishingLicenseMap,
    apiLicensesTypesLookup,
} from '../../global/actions';

import {
    translateErrorMessage
} from '../../global/functions'

import '../../css/fishAdmin.css'

export default class PersonDetailComponent extends React.Component{

    constructor(props){
        super(props)
        this.personId = props.personId
        this.editBtnOffStyle = 'btn btn-outline-dark person-btn'
        this.editBtnOnStyle = 'btn btn-dark person-edit-on person-btn'
        this.fishingTicketDelete = false

        // dict of modified person histories id's
        this.patchedPersonHistory = new Set()
        this.personHistoryPatchingIdx = -1;
        this.personHistoryPatchingInstance = null;
        
        // dict of modified fishing licenses id's
        this.patchedFishingLicenses = new Set()
        this.fishingLicensePatchingIdx = -1;
        
        this.toEditMode = false;
        this.notSwithingAction = false;

        this.todaysDate = new Date();
        this.todaysDateStr = this.todaysDate.toLocaleDateString('en-CA')
    
        this.state = ({
            // person, original var for ztp, tickets and history is not needed since is stored in person
            personObjectOriginal: [{}],
            personObject: [{}],

            // zpt instance for person
            ztpInstanceOriginal: [{}],
            ztpInstance: [{}],

            // fishing tickets for person
            fishingTicket: [{'issued_by':{}}],
            fishingTicketOriginal: [{'issued_by':{}}],
            fishingTicketBlank: true,
            fishingTicketNew:false,

            // person history - yearly info
            personHistory: [],
            newPersonHistoryInstance:{'stamp_paid':false},
            personHistoryNew:false,
            personHistoryNewBlank: true,
            personHistoryNewErrors: [],
            personHistoryErrors: {},
            personHistoryPatchingIdx:-1,
            personHistoryOriginal:null,

            // fishing licenses 
            fishingLicenses: [],
            newFishingLicenseInstance:{
                'universal':false,
                'returned':false,
                'issue_date': this.todaysDateStr
            },
            fishingLicenseNew:false,
            fishingLicenseNewBlank:true,
            fishingLicenseErrors: {},
            fishingLicenseNewErrors:[],
            fishingLicensePatchingIdx:-1,
            fishingLicenseOriginal:null,
            fishingLicenseMap:{},
            fishingLicenseTypes:{},
            
            // issuing organizations data - for lookup
            issuingOrganizations: [],
            issuingOrganizationsLookup: [],

            // idx of ticket id to show on UI
            ticketIdxToShow: 0,

            // is person detail form in edit mode?
            editMode: this.personId ? false : true,

            // combobox values - sex option / age categories
            sex_options: [],
            age_categories: [],

            // errors occured during person patch
            personPatchErrors: [],
            ztpErrors: [],

            // modal show control
            showDeleteFishingTicketModal: false,
            showDeletePersonModal: false,

            patchPerson: false,
            patchZtp: false,
            patchFishingTicket: false,
            patchPersonHistory:false,
            patchFishingLicense: false,

            // cancell counters
            fishingTicketCC:0,
        })

        this.personDetailDidFetch           = this.personDetailDidFetch.bind(this)
        this.personDataDidPatched           = this.personDataDidPatched.bind(this)
        this.editBtnOnClicked               = this.editBtnOnClicked.bind(this)
        this.savePersonData                 = this.savePersonData.bind(this)
        this.personObjectFieldChanged       = this.personObjectFieldChanged.bind(this)
        this.sexOptionsDidFetch             = this.sexOptionsDidFetch.bind(this)
        this.ageCategoriesDidFetch          = this.ageCategoriesDidFetch.bind(this)
        this.issuingOrganizationsDidFetch   = this.issuingOrganizationsDidFetch.bind(this)
        this.zptInstanceFieldChanged        = this.zptInstanceFieldChanged.bind(this)
        this.discardBrnClicked              = this.discardBrnClicked.bind(this)
        this.createPersonData               = this.createPersonData.bind(this)
        this.fishingTicketFieldValueChanged = this.fishingTicketFieldValueChanged.bind(this)
        this.issuingOrganizationSelected    = this.issuingOrganizationSelected.bind(this)
        this.patchMultiplePersonHistories   = this.patchMultiplePersonHistories.bind(this)
        this.personHistoryDeleteCallback    = this.personHistoryDeleteCallback.bind(this)
        this.personHistoryNewValueChanged   = this.personHistoryNewValueChanged.bind(this)
        this.fishingLicenseNewValueChanged  = this.fishingLicenseNewValueChanged.bind(this)
        this.fishingLicenseDeleteCallback   = this.fishingLicenseDeleteCallback.bind(this)
        this.elementIsReadOnly              = this.elementIsReadOnly.bind(this)
        this.fishingLicenseMapDidFetch      = this.fishingLicenseMapDidFetch.bind(this)
        this.fishingLicenseSetType          = this.fishingLicenseSetType.bind(this)
        this.fishingLicenseTypesDidFetch    = this.fishingLicenseTypesDidFetch.bind(this)
    }

    personDetailDidFetch(response, status){
        if(status === 200){

            this.setState({
                personObject:[response],
                personObjectOriginal: [Object.assign({}, response)],
                ztpInstance: response.ztp_number === null ? [{ztp_number:''}] : [response.ztp_number],
                ztpInstanceOriginal: response.ztp_number === null ? [{ztp_number:''}] : [Object.assign({}, response.ztp_number)],
                fishingTicket: response.fishing_tickets ? [response.fishing_tickets] : [{'issued_by':{}}],
                fishingTicketOriginal: response.fishing_tickets === null ? [{'issued_by':{}}] : [Object.assign({}, JSON.parse(JSON.stringify(response.fishing_tickets)))],
                fishingTicketBlank: response.fishing_tickets ? false : true,
                fishingTicketNew:false,
                personHistory: response.person_history ? response.person_history : [],
                fishingLicenses : response.fishing_licenses ? response.fishing_licenses : [],
                editMode: this.toEditMode,
                personPatchErrors:[],
                patchPerson:false,
                patchZtp:false,
                patchFishingTicket:false,
                patchPersonHistory:false,
                personHistoryErrors:{},
                personHistoryNew: this.notSwithingAction ? this.state.personHistoryNew : false,
                newPersonHistoryInstance: this.notSwithingAction ? this.state.newPersonHistoryInstance : {'stamp_paid':false},
                personHistoryNewBlank: this.notSwithingAction ? this.state.personHistoryNewBlank : false,
                personHistoryNewErrors:[],
                fishingLicenseNew: this.notSwithingAction ? this.state.fishingLicenseNew : false,
                newFishingLicenseInstance: this.notSwithingAction ? this.state.newFishingLicenseInstance : {
                    'universal':false,
                    'returned':false,
                    'issue_date': this.todaysDateStr
                },
                fishingLicenseNewBlank: this.notSwithingAction ? this.state.fishingLicenseNewBlank : false,
                fishingLicenseNewErrors: [],
                fishingLicenseOriginal:null,
                fishingLicensePatchingIdx:-1,
                fishingLicenseErrors:{},
                patchFishingLicense:false,
                personHistoryOriginal:null,
                personHistoryPatchingIdx:-1,
            })
                                                                       
            this.patchedPersonHistory = new Set()
            this.toEditMode = false
            this.notSwithingAction = false
        }
    }

    personDataDidPatched(response, status){
        if(status === 200 || status === 204 || status === 201){
            apiLookupPersonDetail(this.personDetailDidFetch, this.personId)
        }else if(status === 400){
            this.setState({
                personPatchErrors:response
            })
        }
    }

    sexOptionsDidFetch(response, status){
        if(status === 200){
            this.setState({
                sex_options:response.sex_dict
            })
        }
    }

    ageCategoriesDidFetch(response, status){
        if(status === 200){
            this.setState({
                age_categories:response.age_categories_dict
            })
        }
    }

    issuingOrganizationsDidFetch(response, status){
        if(status === 200){
            this.setState({
                issuingOrganizations:response,
                issuingOrganizationsLookup:response.map((organization, id) => ({id:organization.id, value:organization.name}))
            })
        }
    }

    fishingLicenseMapDidFetch(response, status){
        if(status === 200){
            this.setState({
                fishingLicenseMap:response.license_type_map
            })
        }
    }

    fishingLicenseTypesDidFetch(response, status){
        if(status === 200){
            var licenseTypesDict = {}
            
            response.licenses_types.forEach(element => {
                licenseTypesDict[element[0]]=element[1]
            });

            this.setState({
                fishingLicenseTypes:licenseTypesDict
            })
        }
    }

    componentDidMount(){
        if(this.personId){
            apiLookupPersonDetail(this.personDetailDidFetch, this.personId)
        }
        apiLookupAgeCategories(this.ageCategoriesDidFetch)
        apiLookupSexOptions(this.sexOptionsDidFetch)
        apiLookupIssuingOrganizations(this.issuingOrganizationsDidFetch)
        apiGetFishingLicenseMap(this.fishingLicenseMapDidFetch)
        apiLicensesTypesLookup(this.fishingLicenseTypesDidFetch)
    }

    editBtnOnClicked(){
        if(this.state.editMode===true){
            apiLookupPersonDetail(this.personDetailDidFetch, this.personId)
        }else {
        this.setState({
            editMode:true
        })}
    }

    patchMultiplePersonHistories(indices, okPatches, notOkPatches, currentPersonHistoryErrors){
        var newOkPatches = okPatches
        var newNotOkPatches = notOkPatches

        let personHistoryDidPatch = (response, status) => {
            if(status === 200){
                newOkPatches = okPatches + 1
                if(this.personHistoryPatchingIdx in currentPersonHistoryErrors){
                    delete currentPersonHistoryErrors[this.personHistoryPatchingIdx] 
                }

            }else if(status === 400){
                currentPersonHistoryErrors[this.personHistoryPatchingIdx] = response
                newNotOkPatches = notOkPatches + 1
            }

            if(indices.length === 0)
            {
                if(newOkPatches > 0 && notOkPatches === 0 && this.personId){
                    apiLookupPersonDetail(this.personDetailDidFetch, this.personId)
                }
                else
                {
                    this.setState({
                        personHistoryErrors:currentPersonHistoryErrors
                    })
                }
            }
            else
            {
                this.patchMultiplePersonHistories(indices, newOkPatches, newNotOkPatches, currentPersonHistoryErrors)
            }
        }

        if(indices.length > 0)
        {
            this.personHistoryPatchingIdx = indices.pop()
            this.personHistoryPatchingInstance = this.state.personHistory[this.personHistoryPatchingIdx]
            apiPatchPersonHistory(personHistoryDidPatch, this.personHistoryPatchingInstance.id, this.personHistoryPatchingInstance)
        }
    }

    savePersonData(){
        var somethingPatched = false

        // person
        if(this.state.patchPerson === true){
            somethingPatched = true
            this.toEditMode = true
            apiPatchPersonDetail(this.personDataDidPatched, this.state.personObject[0].id, this.state.personObject[0])
        }

        // fishing ticket & ztp
        if(this.state.patchZtp === true){ 
            somethingPatched = true
            // create
            if(this.state.ztpInstanceOriginal[0].ztp_number === '' &&
                this.state.ztpInstance[0].ztp_number !== ''){
                    var created_data = this.state.ztpInstance[0]
                    created_data['person'] = this.personId
                    this.toEditMode = true
                    apiZtpCreate(this.personDataDidPatched, created_data)
            }
            // delete
            else if(this.state.ztpInstanceOriginal[0].ztp_number !== '' &&
                this.state.ztpInstance[0].ztp_number === ''){
                    this.toEditMode = true
                    apiZtpDelete(this.personDataDidPatched, this.state.ztpInstanceOriginal[0].id)
            }
            // patch
            else{
                this.toEditMode = true
                apiZtpPatch(this.personDataDidPatched, this.state.ztpInstance[0].id, this.state.ztpInstance[0])
            }  
        }

        if(somethingPatched === false){
            this.setState({
                editMode:false,
                personHistoryNew:false,
            })
        }
    }

    createPersonData(){
        let personDidCreate = (response, status) => {
            if(status === 201){
                this.personId = response.id
                
                let ztpInstanceDidCreate = (response, status) => {
                    if(status === 201){
                        apiLookupPersonDetail(this.personDetailDidFetch, this.personId)
                    }else if(status === 400){
                        this.setState({
                            ztpErrors:response
                        })
                    }
                }

                if(this.state.ztpInstance[0].ztp_number){
                    var data = this.state.ztpInstance[0]
                    data['person'] = this.personId
                    apiZtpCreate(ztpInstanceDidCreate, data)
                }else{
                    apiLookupPersonDetail(this.personDetailDidFetch, this.personId)
                }

            }
            else if(status === 400){
                this.setState({
                    personPatchErrors: response
                })
            }
        }


        apiCreatePersonDetail(personDidCreate, this.state.personObject[0])
    }

    personObjectFieldChanged(fieldName, value){
        if(this.state.personObject){
            var po = this.state.personObject[0]
            po[fieldName] = value
            this.setState({
                personObject:[po],
                patchPerson:true
            })
        }
    }

    zptInstanceFieldChanged(value){
        if(this.state.ztpInstance){
            var ztpI = this.state.ztpInstance[0]
            ztpI.ztp_number = value
            this.setState({
                ztpInstance:[ztpI],
                patchZtp:true
            })
        }
    }

    discardBrnClicked(){
        if(this.personId){
            this.notSwithingAction = true;
            this.toEditMode = true;
            apiLookupPersonDetail(this.personDetailDidFetch, this.personId)
        }
    }

    fishingTicketFieldValueChanged(fieldName, value){
        var currentFishingTicket = this.state.fishingTicket[0]
        currentFishingTicket[fieldName] = value
        this.setState({
            fishingTicket:[currentFishingTicket],
            patchFishingTicket:true
        })  
    }

    personHistoryValueChanged(idx, fieldName, value){
        var currentPersonHistory = this.state.personHistory
        var currentPersonHistoryInstance = currentPersonHistory[idx]
        var currentPersonHistoryInstanceOriginal = Object.assign({}, currentPersonHistoryInstance)
        currentPersonHistoryInstance[fieldName] = value
        currentPersonHistory[idx] = currentPersonHistoryInstance

        this.setState({
            personHistory:currentPersonHistory,
            patchPersonHistory:true,
            personHistoryPatchingIdx: idx,
            personHistoryOriginal: this.state.personHistoryOriginal === null ? currentPersonHistoryInstanceOriginal : this.state.personHistoryOriginal
        })

        this.patchedPersonHistory.add(idx)
    }

    fishingLicenseValueChanged(idx, fieldName, value){
        var currentFishingLicenses = this.state.fishingLicenses
        var currentFishingLicenseInstance = currentFishingLicenses[idx]
        var currentFishingLicenseInstanceOriginal = Object.assign({}, currentFishingLicenses[idx])
        currentFishingLicenseInstance[fieldName] = value
        currentFishingLicenses[idx] = currentFishingLicenseInstance

        if(fieldName === 'license_number')
        {   
            this.fishingLicenseSetType(currentFishingLicenseInstance, value)
        }

        this.setState({
            fishingLicenses:currentFishingLicenses,
            patchFishingLicense:true,
            fishingLicensePatchingIdx: idx,
            fishingLicenseOriginal: this.state.fishingLicenseOriginal === null ? currentFishingLicenseInstanceOriginal : this.state.fishingLicenseOriginal
        })

        this.patchedFishingLicenses.add(idx)
    }

    personHistoryNewValueChanged(fieldName, value){
        var currentnewPersonHistoryInstance = this.state.newPersonHistoryInstance
        currentnewPersonHistoryInstance[fieldName] = value

        this.setState({
            personHistoryNewBlank:false,
            newPersonHistoryInstance:currentnewPersonHistoryInstance
        })
    }

    issuingOrganizationSelected(id){
        const findIssuingOrganizationFromId = (id) => {
            const orgFindResult = this.state.issuingOrganizations.filter((organization) => organization.id === id)
            return orgFindResult
        }

        const newIssuingOrg = findIssuingOrganizationFromId(id)
        if(newIssuingOrg.length > 0){
            this.fishingTicketFieldValueChanged('issued_by', newIssuingOrg[0])
        }else{
            this.fishingTicketFieldValueChanged('issued_by', {}) 
        }
    }

    personHistoryDeleteCallback(personHistoryId){
        let personHistoryDidDelete = (_response, status) => {
            if(status === 204){
                this.toEditMode = true;
                this.notSwithingAction = true;
                apiLookupPersonDetail(this.personDetailDidFetch, this.personId)
            }
        }
        apiDeletePersonHistory(personHistoryDidDelete, personHistoryId)
    }

    fishingLicenseDeleteCallback(fishingLicenseId){
        let fishingLicenseDidDelete = (_response, status) => {
            if(status === 204){
                this.toEditMode = true
                this.notSwithingAction = true
                apiLookupPersonDetail(this.personDetailDidFetch, this.personId)
            }
        }
        apiDeleteFishingLicense(fishingLicenseDidDelete, fishingLicenseId)
    }

    fishingLicenseNewValueChanged(fieldName, value){
        var currentNewFishingLicenseInstance = this.state.newFishingLicenseInstance
        currentNewFishingLicenseInstance[fieldName] = value

        // set the fishing license type from license number
        if(fieldName === 'license_number')
        {   
            this.fishingLicenseSetType(currentNewFishingLicenseInstance, value)
        }

        this.setState({
            newFishingLicenseInstance:currentNewFishingLicenseInstance,
            fishingLicenseNewBlank:false,
        })
    }

    fishingLicenseSetType(fishingLicenseObj, licenseNumber){
        const licenseTypeKey = licenseNumber.substring(0,5)
        const licenseTypeMapInfo = this.state.fishingLicenseMap[licenseTypeKey]

        if(licenseTypeMapInfo){
            fishingLicenseObj['universal'] = licenseTypeMapInfo['universal']
            fishingLicenseObj['license_type'] = licenseTypeMapInfo['type']
            fishingLicenseObj['license_type_str'] = this.state.fishingLicenseTypes[licenseTypeMapInfo['type']]
        }
    }

    elementIsReadOnly(areaId){
        // Indicates whenever input element is in read only state
        // areId in {"person", "ticket", "license", "history"}
        
        switch(areaId){
            case "person":
                return (!this.state.editMode || this.state.fishingTicketNew || this.state.patchFishingTicket ||
                    this.state.fishingLicenseNew || this.state.patchFishingLicense || 
                    this.state.personHistoryNew || this.state.patchPersonHistory)

            case "ticket":
                return (!this.state.editMode || this.state.patchPerson ||
                    this.state.fishingLicenseNew || this.state.patchFishingLicense || 
                    this.state.personHistoryNew || this.state.patchPersonHistory)
            
            case "license":
                return (!this.state.editMode || this.state.patchPerson ||
                    this.state.fishingTicketNew || this.state.patchFishingTicket ||
                    this.state.personHistoryNew || this.state.patchPersonHistory)
            
            case "history":
                return (!this.state.editMode || this.state.patchPerson ||
                    this.state.fishingTicketNew || this.state.patchFishingTicket ||
                    this.state.fishingLicenseNew || this.state.patchFishingLicense)

            default:
                return false;
        }
    }

    render(){
        if(this.state.personObject.length === 1){
            return(
                <div className="container licenses-container-wrapper">
                    <form method="POST">
                        <div className='container mb-3'>
                            <a href='/fish-admin/persons/'>{"< všichni členové"}</a>
                        </div>
                        <div className="d-flex justify-content-between">
                            <h3>{!this.state.editMode && this.state.personObject[0].full_name}</h3>
                            {this.state.editMode && 
                                <div className='input-group'>
                                    <div className='container'>
                                        <div className="row">
                                            <div className="col-3">
                                                <b>Příjmení &nbsp;</b>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    value={this.state.personObject[0].surrname ? this.state.personObject[0].surrname : ''}
                                                    onChange={(e) => {this.personObjectFieldChanged('surrname', e.target.value)}}
                                                    readOnly={this.elementIsReadOnly("person")}/>
                                                {'surrname' in this.state.personPatchErrors &&
                                                    <div className='container person-alert mb-2'>
                                                        {this.state.personPatchErrors.surrname.map((error, i) => (
                                                            <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                {translateErrorMessage(error)}
                                                            </div>
                                                        ))}  
                                                    </div>
                                                }
                                             </div>
                                             <div className="col-3">
                                                <b>Jméno &nbsp;</b>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    value={this.state.personObject[0].name ? this.state.personObject[0].name : ''}
                                                    onChange={(e) => {this.personObjectFieldChanged('name', e.target.value)}}
                                                    readOnly={this.elementIsReadOnly("person")}/>
                                                {'name' in this.state.personPatchErrors &&
                                                    <div className='container person-alert mb-2'>
                                                        {this.state.personPatchErrors.name.map((error, i) => (
                                                            <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                {translateErrorMessage(error)}
                                                            </div>
                                                        ))}  
                                                    </div>
                                                }
                                            </div>
                               
                                        </div>
                                    </div>
                                </div>
                            }
                            
                            <span/>

                            <div className="person-header-btn-group">
                                <div className='btn-group'>
                                
                                    {((this.state.patchPerson || this.state.patchZtp) && this.personId) && 
                                        <button type='button' className='btn btn-outline-dark person-btn' onClick={this.savePersonData}>Uložit</button>
                                    } 
                                   
                                    <button 
                                        type="button" 
                                        className={this.state.editMode === true ? this.editBtnOnStyle : this.editBtnOffStyle}
                                        onClick={this.editBtnOnClicked}>
                                            <i className="bi bi-pencil"></i>
                                    </button> 

                                    {this.personId && 
                                        <button type="button" className="btn btn-outline-danger person-btn" onClick={(e) => {
                                            this.setState({
                                                showDeletePersonModal:true
                                            })
                                        }
                                        }>Smazat</button>
                                    } 
                                </div>
                            </div>
                            
                        </div>
                    
                    <hr/>

                    
                        <div className="row">
                        {/* primary info */}
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5">
            
                                {/* one info box */}
                                <div className="row mb-2">
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <b>Adresa:</b>
                                    </div>
                                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            value={this.state.personObject[0].address ? this.state.personObject[0].address : ''}
                                            onChange={(e) => {this.personObjectFieldChanged('address', e.target.value)}}
                                            readOnly={this.elementIsReadOnly("person")}/> 
                                       
                                        {'address' in this.state.personPatchErrors &&
                                            <div className='container person-alert mb-2'>
                                                {this.state.personPatchErrors.address.map((error, i) => (
                                                    <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                        {translateErrorMessage(error)}
                                                    </div>
                                                ))}  
                                            </div>
                                        }

                                    </div>  
                                </div>
            
                                <div className="row mb-2">
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <b>Narozen:</b>
                                    </div>
                                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                        <input 
                                            type="date" 
                                            className="form-control" 
                                            value={this.state.personObject[0].born ? this.state.personObject[0].born : ''}
                                            onChange={(e) => {this.personObjectFieldChanged('born', e.target.value)}}
                                            readOnly={this.elementIsReadOnly("person")}/> 

                                        {'born' in this.state.personPatchErrors &&
                                            <div className='container person-alert mb-2'>
                                                {this.state.personPatchErrors.born.map((error, i) => (
                                                    <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                        {translateErrorMessage(error)}
                                                    </div>
                                                ))}  
                                            </div>
                                        }
                                    </div>  
                                </div>
            
                                <div className="row mb-2">
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <b>Pohlaví:</b>
                                    </div>
                                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                        <select 
                                            className="form-select form-control" 
                                            value={this.state.personObject[0].sex}
                                            disabled={this.elementIsReadOnly("person")}
                                            onChange={(e) => {this.personObjectFieldChanged('sex', e.target.value)}}>
                                                {this.state.sex_options.map((sex_option, i) => (
                                                    <option value={sex_option[0]} key={`${sex_option[0]}`}>{sex_option[1]}</option>
                                                ))}
                                        </select>

                                        {'sex' in this.state.personPatchErrors &&
                                            <div className='container person-alert mb-2'>
                                                {this.state.personPatchErrors.sex.map((error, i) => (
                                                    <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                        {translateErrorMessage(error)}
                                                    </div>
                                                ))}  
                                            </div>
                                        }
                                    </div>  
                                </div>
            
                                <div className="row mb-2">
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <b>Věková kategorie:</b>
                                    </div>
                                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                        <select 
                                            className="form-select form-control" 
                                            value={this.state.personObject[0].age_category}
                                            disabled={this.elementIsReadOnly("person")}
                                            onChange={(e) => {this.personObjectFieldChanged('age_category', e.target.value)}}>
                                                {this.state.age_categories.map((category, i) => (
                                                    <option value={category[0]} key={`${category[0]}`}>{category[1]}</option>
                                                ))}
                                        </select>
                                        {'age_category' in this.state.personPatchErrors &&
                                            <div className='container person-alert mb-2'>
                                                {this.state.personPatchErrors.age_category.map((error, i) => (
                                                    <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                        {translateErrorMessage(error)}
                                                    </div>
                                                ))}  
                                            </div>
                                        }
                                    </div>  
                                </div>
            
                                <div className="row mb-2">
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <b>Kontakt:</b>
                                    </div>
                                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                        
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1"><i className="bi bi bi-telephone"></i></span>
                                            </div>
                                            <input 
                                                type="tel"
                                                className="form-control"
                                                placeholder="Tel. číslo"
                                                value={this.state.personObject[0].phone ? this.state.personObject[0].phone : ''} 
                                                readOnly={this.elementIsReadOnly("person")}
                                                onChange={(e) => {this.personObjectFieldChanged('phone', e.target.value)}}/>
                                            
                                            {'phone' in this.state.personPatchErrors &&
                                                <div className='container person-alert mb-2'>
                                                    {this.state.personPatchErrors.phone.map((error, i) => (
                                                        <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                            {translateErrorMessage(error)}
                                                        </div>
                                                    ))}  
                                                </div>
                                            }
                                        </div>

                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1"><i className="bi bi bi-envelope"></i></span>
                                            </div>
                                            <input 
                                                type="email"
                                                className="form-control"
                                                placeholder="Email"
                                                value={this.state.personObject[0].email ? this.state.personObject[0].email : ''}
                                                readOnly={this.elementIsReadOnly("person")}
                                                onChange={(e) => {this.personObjectFieldChanged('email', e.target.value)}}/>
                                            
                                            {'email' in this.state.personPatchErrors &&
                                                <div className='container person-alert mb-2'>
                                                    {this.state.personPatchErrors.email.map((error, i) => (
                                                        <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                            {translateErrorMessage(error)}
                                                        </div>
                                                    ))}  
                                                </div>
                                            }
                                        </div>
                                    </div>  
                                </div>
            
                                <div className="row mb-2">
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <b>Číslo ZTP:</b>
                                    </div>
                                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                        <input 
                                            type="text"
                                            className="form-control"
                                            placeholder="číslo ZTP"
                                            value={this.state.ztpInstance[0].ztp_number ? this.state.ztpInstance[0].ztp_number : ''}
                                            readOnly={this.elementIsReadOnly("person")}
                                            onChange={(e) => {this.zptInstanceFieldChanged(e.target.value)}}/>
                                    </div>  
                                </div>
                            </div>
            
                            {/*secondary info */}
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <b>Poznámky:</b><br/>
                                            <textarea 
                                                className='person-note-wrapper'
                                                placeholder="Poznámka"
                                                value={this.state.personObject[0].note ? this.state.personObject[0].note : ''}
                                                disabled={this.elementIsReadOnly("person")}
                                                onChange={(e) => {this.personObjectFieldChanged('note', e.target.value)}}/>
                                        
                                        {'note' in this.state.personPatchErrors &&
                                            <div className='container person-alert mb-2'>
                                                {this.state.personPatchErrors.note.map((error, i) => (
                                                    <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                        {translateErrorMessage(error)}
                                                    </div>
                                                ))}  
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    {/* persion additional information - only when user exists already*/}
                    <hr/>
                    <form>
                        {this.personId &&
                            <div className="row mt-2">
                                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <div className="d-flex justify-content-between">
                                        <h5>Rybářský lístek:</h5>

                                        <div className='d-flex justify-content-center mb-2'>
                                            <button 
                                                className='btn btn-outline-dark person-btn'
                                                disabled={!(!this.elementIsReadOnly('ticket') && !this.state.fishingTicketNew && this.state.fishingTicketBlank)}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        fishingTicketNew:true,
                                                    })
                                                }}>
                                                Nový lístek
                                                <i className="bi bi-plus"></i>
                                            </button>
                                        </div>

                                        <span/>                
                                    </div>

                                    <div className="container ticket-wrapper">
                                        <div className="row mb-2">
                                            {(this.state.fishingTicketNew || !this.state.fishingTicketBlank) &&
                                                <CollapsablePane
                                                    label={this.state.fishingTicket[0].ticket_id ? this.state.fishingTicket[0].ticket_id : ''}
                                                    collapsed={false}
                                                    showDelete={!this.elementIsReadOnly('ticket')}
                                                    deleteModalMessage={"Odstranit"}
                                                    deleteModalSubMessage={`Opravdu odstranit rybářský lístek ${this.state.fishingTicket[0].ticket_id ? this.state.fishingTicket[0].ticket_id : ''}?`}
                                                    onDeleteCallback={(e) => {
                                                        let fishingTicketDidDeleted = (_response, status) => {
                                                            if(status === 204){
                                                                this.setState({
                                                                    fishingTicket: [{'issued_by':{}}],
                                                                    fishingTicketBlank: true,
                                                                    fishingTicketNew:false,
                                                                })
                                                            }
                                                        }
                            
                                                        apiDeleteFishingTicket(fishingTicketDidDeleted, this.state.fishingTicket[0].id)
                                                    }}>

                                                    <div className="container ticket-wrapper">
                                                        <div className="row mb-2">
                                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 mb-2"> 
                                                                <b>Ev. číslo:</b>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 mb-2">
                                                                <input 
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="ev. číslo"
                                                                    value={this.state.fishingTicket[0].ticket_id ? this.state.fishingTicket[0].ticket_id : ''} 
                                                                    readOnly={this.elementIsReadOnly('ticket')}
                                                                    onChange={(e) => {this.fishingTicketFieldValueChanged('ticket_id', e.target.value)}}/>

                                                                    {'ticket_id' in this.state.personPatchErrors &&
                                                                        <div className='container person-alert mb-2'>
                                                                            {this.state.personPatchErrors.ticket_id.map((error, i) => (
                                                                                <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                                    {translateErrorMessage(error)}
                                                                                </div>
                                                                            ))}  
                                                                        </div>
                                                                    }
                                                            </div>

                                                            
                                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 mb-2">     
                                                            {!this.state.editMode &&                                           
                                                                <b>Platnost:</b>    
                                                            }      
                                                            {this.state.editMode &&
                                                            <b>Od:</b>  
                                                            }                                     
                                                            </div>
                                                                                                                                                                                    
                                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 mb-2">
                                                                {!this.state.editMode &&
                                                                    <div>
                                                                        <input 
                                                                            type="text"
                                                                            className="form-control"
                                                                            readOnly={this.elementIsReadOnly('ticket')}
                                                                            value={this.state.fishingTicket[0].valid_range_formated ? this.state.fishingTicket[0].valid_range_formated : ''} />
                                                                    </div>
                                                                }
                                                                {this.state.editMode &&
                                                                    <div>
                                                                        <input 
                                                                            type="date" 
                                                                            className="form-control" 
                                                                            value={this.state.fishingTicket[0].from_date ? this.state.fishingTicket[0].from_date : ''}
                                                                            onChange={(e) => {this.fishingTicketFieldValueChanged('from_date', e.target.value)}}
                                                                            readOnly={this.elementIsReadOnly('ticket')}/>   
                                                                        
                                                                        {'from_date' in this.state.personPatchErrors &&
                                                                        <div className='container person-alert mb-2'>
                                                                            {this.state.personPatchErrors.from_date.map((error, i) => (
                                                                                <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                                    {translateErrorMessage(error)}
                                                                                </div>
                                                                            ))}  
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            {this.state.editMode && !this.state.fishingTicket[0].unlimited &&
                                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 mb-2">                        
                                                                <b>Do:</b>                                                                                             
                                                            </div>}

                                                            {this.state.editMode && !this.state.fishingTicket[0].unlimited &&     
                                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 mb-2">                                                                                                
                                                                <input 
                                                                    type="date" 
                                                                    className="form-control" 
                                                                    value={this.state.fishingTicket[0].to_date ? this.state.fishingTicket[0].to_date : ''}
                                                                    onChange={(e) => {this.fishingTicketFieldValueChanged('to_date', e.target.value)}}
                                                                    readOnly={this.elementIsReadOnly('ticket')}/>      

                                                                {'to_date' in this.state.personPatchErrors &&
                                                                <div className='container person-alert mb-2'>
                                                                    {this.state.personPatchErrors.to_date.map((error, i) => (
                                                                        <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                            {translateErrorMessage(error)}
                                                                        </div>
                                                                    ))}  
                                                                </div>
                                                                }

                                                            </div> }
                                                            
                                                            {this.state.editMode &&
                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 mb-2"> 
                                                                    <b>Neomezený:</b>
                                                                </div>
                                                            }

                                                            {this.state.editMode &&
                                                             <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 mb-2">
                                                                <input 
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    key={`${this.state.fishingTicketCC}`}
                                                                    checked={this.state.fishingTicket[0].unlimited}
                                                                    disabled={!this.state.editMode}
                                                                    onChange={(e) => (this.fishingTicketFieldValueChanged('unlimited', e.target.checked))}/>
                                                            </div>
                                                            }
                                                                                                                                            
                                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 mb-2"> 
                                                                <b>Vydal:</b>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 mb-2">
                                                                {this.state.editMode &&
                                                                <LookupComponent
                                                                    key={`${this.state.issuingOrganizationsLookup.length}-${this.state.fishingTicketCC}`}
                                                                    onSelectCallback={this.issuingOrganizationSelected}
                                                                    defaultSelected={this.state.fishingTicket[0].issued_by ? 
                                                                        {id:this.state.fishingTicket[0].issued_by, value:this.state.fishingTicket[0].issued_by.name}:[]}
                                                                    data={this.state.issuingOrganizationsLookup}
                                                                    placeholder={"Vydávající úřad"}
                                                                    readOnly={this.elementIsReadOnly('ticket')}/>}
                                                                {!this.state.editMode &&
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control" 
                                                                        value={this.state.fishingTicket[0].issued_by.name ? this.state.fishingTicket[0].issued_by.name : ''}
                                                                        readOnly={this.elementIsReadOnly('ticket')}/>        
                                                                }
                                                                {'issued_by' in this.state.personPatchErrors &&
                                                                <div className='container person-alert mb-2'>
                                                                    {this.state.personPatchErrors.issued_by.name.map((error, i) => (
                                                                        <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                            {translateErrorMessage(error)}
                                                                        </div>
                                                                    ))}  
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>

                                                        {this.state.fishingTicketNew && 
                                                            <div className="row mb-2">
                                                                <div className="d-flex justify-content-center">
                                                                    <div className="btn-group person-btn-group">
                                                                        <button 
                                                                            type="button" 
                                                                            className="btn btn-outline-dark person-text-btn"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if(this.state.fishingTicketBlank && this.state.fishingTicketNew){
                                                                                    var data = this.state.fishingTicket[0]
                                                                                    data['person'] = this.personId
                                                                                    this.notSwithingAction = true
                                                                                    this.toEditMode = true
                                                                                    apiCreateFishingTicket(this.personDataDidPatched, data)
                                                                                }
                                                                            }}>Přidat</button>

                                                                        <button 
                                                                            type="button"
                                                                            className="btn btn-outline-danger person-text-btn"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.setState({
                                                                                    fishingTicket: [{'issued_by':{}}],
                                                                                    fishingTicketBlank: true,
                                                                                    fishingTicketNew:false,
                                                                                })
                                                                            }}>Zrušit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.patchFishingTicket && !this.state.fishingTicketNew &&
                                                            <div className="row mb-2">
                                                                <div className="d-flex justify-content-center">
                                                                    <div className="btn-group person-btn-group">
                                                                        <button 
                                                                            type="button" 
                                                                            className="btn btn-outline-dark person-text-btn"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if(this.state.patchFishingTicket === true && !this.state.fishingTicketBlank && !this.state.fishingTicketNew){
                                                                                    this.notSwithingAction = true;
                                                                                    this.toEditMode = true;
                                                                                    apiPatchFishingTicket(this.personDataDidPatched, this.state.fishingTicket[0].id, this.state.fishingTicket[0])
                                                                                }
                                                                            }}>Uložit</button>
                                                                        
                                                                        <button 
                                                                            type="button"
                                                                            className="btn btn-outline-danger person-text-btn"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();                                                       
                                                                                this.setState({
                                                                                    fishingTicket: JSON.parse(JSON.stringify(this.state.fishingTicketOriginal)),
                                                                                    fishingTicketCC: this.state.fishingTicketCC + 1,
                                                                                    patchFishingTicket: false,
                                                                                })               
                                                                            }}>Zrušit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </CollapsablePane>
                                            }
                                        </div>       
                                    </div>                             
                                </div>
                                {/** -----------------  POVOLENKY --------------------- */}
                                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <div className="d-flex justify-content-between ">
                                        <h5>Povolenky:</h5>  
                                                                     
                                        <div className='d-flex justify-content-center mb-2'>
                                            <button 
                                                className='btn btn-outline-dark person-btn'
                                                disabled={!(!this.elementIsReadOnly('license') && !this.state.patchFishingLicense)}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.setState({
                                                        fishingLicenseNew:true
                                                    })
                                                }}>
                                                Nová povolenka
                                                <i className="bi bi-plus"></i>
                                            </button>
                                        </div>
                                        <span/>       
                                    </div>  

                                    <div className="container ticket-wrapper">
                                       
                                        
                                        {/** ---------- NEW FISHING LICENSE ------------- */}
                                        {this.state.fishingLicenseNew &&
                                            <div className="row mb-2 fishing-license-new">
                                                <div className='d-flex justify-content-center'>
                                                    <b className="collapse-header-label">Nová povolenka</b>
                                                </div>

                                                <div className="collapsable-body">
                                                    <div className="row mb-2">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Rok:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                            <input 
                                                                type="number" 
                                                                className="form-control" 
                                                                value={this.state.newFishingLicenseInstance.year ? this.state.newFishingLicenseInstance.year : 0}
                                                                readOnly={!this.state.editMode}
                                                                onChange={(e) => {this.fishingLicenseNewValueChanged('year', e.target.value)}}
                                                                /> 
                                                            {'year' in this.state.fishingLicenseNewErrors &&
                                                                <div className='container person-alert mb-2'>
                                                                    {this.state.fishingLicenseNewErrors.year.map((error, i) => (
                                                                        <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                            {translateErrorMessage(error)}
                                                                        </div>
                                                                    ))}  
                                                                </div>
                                                            }
                                                        </div>                                                        
                                                    </div>

                                                    <div className="row mb-2">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Číslo kupónu:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={this.state.newFishingLicenseInstance.license_number ? this.state.newFishingLicenseInstance.license_number : ''}
                                                                readOnly={!this.state.editMode}
                                                                onChange={(e) => {this.fishingLicenseNewValueChanged('license_number', e.target.value)}}
                                                                /> 
                                                            {'license_number' in this.state.fishingLicenseNewErrors &&
                                                                <div className='container person-alert mb-2'>
                                                                    {this.state.fishingLicenseNewErrors.license_number.map((error, i) => (
                                                                        <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                            {translateErrorMessage(error)}
                                                                        </div>
                                                                    ))}  
                                                                </div>
                                                            }
                                                        </div>                                                        
                                                    </div>

                                                    <div className="row mb-2">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Druh:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={this.state.newFishingLicenseInstance.license_type_str ? this.state.newFishingLicenseInstance.license_type_str : ''}
                                                                readOnly={true}
                                                                /> 
                                                            {'license_type' in this.state.fishingLicenseNewErrors &&
                                                                <div className='container person-alert mb-2'>
                                                                    {this.state.fishingLicenseNewErrors.license_type.map((error, i) => (
                                                                        <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                            {translateErrorMessage(error)}
                                                                        </div>
                                                                    ))}  
                                                                </div>
                                                            }
                                                        </div>                                                        
                                                    </div>

                                                    <div className="row mb-2">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Celosvazová:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2">
                                                            <input 
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={this.state.newFishingLicenseInstance.universal}
                                                                disabled={true}
                                                                onChange={(e) => (this.fishingLicenseNewValueChanged('universal', e.target.checked))}/>
                                                        </div>
                                                        {'universal' in this.state.fishingLicenseNewErrors &&
                                                            <div className='container person-alert mb-2'>
                                                                {this.state.fishingLicenseNewErrors.universal.map((error, i) => (
                                                                    <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                        {translateErrorMessage(error)}
                                                                    </div>
                                                                ))}  
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="row mb-2">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Vydáno dne:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                            <input 
                                                                type="date" 
                                                                className="form-control" 
                                                                value={this.state.newFishingLicenseInstance.issue_date ? this.state.newFishingLicenseInstance.issue_date : ''}
                                                                readOnly={!this.state.editMode}
                                                                onChange={(e) => {this.fishingLicenseNewValueChanged('issue_date', e.target.value)}}
                                                                /> 
                                                            {'issue_date' in this.state.fishingLicenseNewErrors &&
                                                                <div className='container person-alert mb-2'>
                                                                    {this.state.fishingLicenseNewErrors.issue_date.map((error, i) => (
                                                                        <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                            {translateErrorMessage(error)}
                                                                        </div>
                                                                    ))}  
                                                                </div>
                                                            }
                                                        </div>                                                        
                                                    </div>

                                                    {'non_field_errors' in this.state.fishingLicenseNewErrors &&
                                                        <div className="container person-alert mb-2">
                                                            {this.state.fishingLicenseNewErrors.non_field_errors.map((error, i) => (
                                                                <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                    {translateErrorMessage(error)}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }

                                                    <div className="row mb-2">
                                                        <div className="d-flex justify-content-center">
                                                            <div className="btn-group person-btn-group">
                                                                <button 
                                                                    type="button" 
                                                                    className="btn btn-outline-dark person-text-btn"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();

                                                                        let fishingLicenseDidCreate = (response, status) => {
                                                                            if(status === 201){
                                                                                this.toEditMode=true                                                                               
                                                                                apiLookupPersonDetail(this.personDetailDidFetch, this.personId)
                                                                            }else if(status === 400){
                                                                                this.setState({
                                                                                    fishingLicenseNewErrors:response
                                                                                })
                                                                            }
                                                                        }

                                                                        var data = this.state.newFishingLicenseInstance
                                                                        data['person'] = this.personId
                                                                        apiCreateFishingLicense(fishingLicenseDidCreate, data)
                                                                    }}>Přidat</button>
                                                                <button 
                                                                    type="button"
                                                                    className="btn btn-outline-danger person-text-btn"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.setState({
                                                                            fishingLicenseNew:false,
                                                                            fishingLicenseNewBlank:true,
                                                                            newFishingLicenseInstance:{
                                                                                'universal':false,
                                                                                'returned':false,
                                                                                'issue_date': this.todaysDateStr
                                                                            },
                                                                            fishingLicenseNewErrors:[]
                                                                        })
                                                                    }}>Zrušit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        
                                        }

                                        {/** ---------- NEW FISHING LICENSE END ------------- */}

                                        {this.state.fishingLicenses.map((fishing_license, idx) => (
                                            <div className='row mb-2' key={`${idx}`}>
                                                <CollapsablePane
                                                    label={`${fishing_license.year} - ${fishing_license.license_type_str}`}
                                                    collapsed={idx > 0}
                                                    showDelete={!this.elementIsReadOnly('license') && (this.state.fishingLicensePatchingIdx === idx || this.state.fishingLicensePatchingIdx === -1)}
                                                    deleteModalMessage={"Odstranit"}
                                                    deleteModalSubMessage={
                                                        `Opravdu odstranit ${fishing_license.license_type === "TRT" ? "pstruhovou" : "mimopstruhovou"} povolenku pro rok ${fishing_license.year}? ${fishing_license.returned ? 
                                                            "Sumář úlovků a kontroly rybářskou stráží pro tuto povolenku budou taktéž smazány." : ""}`
                                                    }
                                                    onDeleteCallback={(e) => (this.fishingLicenseDeleteCallback(fishing_license.id))}>

                                                    {/** POVOLENKY ELEMENTS */}
                                                    <div className="row">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Rok:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2">
                                                            <input 
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="rok"
                                                                value={fishing_license.year} 
                                                                readOnly={this.elementIsReadOnly('license') || (this.state.fishingLicensePatchingIdx !== -1 && this.state.fishingLicensePatchingIdx !== idx)}
                                                                onChange={(e) => {this.fishingLicenseValueChanged(idx, 'year', e.target.value)}}/>
                                                        </div>
                                                        {idx in this.state.fishingLicenseErrors && 'year' in this.state.fishingLicenseErrors[idx] &&
                                                            <div className='container person-alert mb-2'>
                                                                {this.state.fishingLicenseErrors[idx].year.map((error, i) => (
                                                                    <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                        {translateErrorMessage(error)}
                                                                    </div>
                                                                ))}  
                                                            </div>
                                                        }  
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Číslo kupónu:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2">
                                                            <input 
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="číslo kupoónu"
                                                                value={fishing_license.license_number} 
                                                                readOnly={this.elementIsReadOnly('license') || (this.state.fishingLicensePatchingIdx !== -1 && this.state.fishingLicensePatchingIdx !== idx)}
                                                                onChange={(e) => {this.fishingLicenseValueChanged(idx, 'license_number', e.target.value)}}/>
                                                        </div>
                                                        {idx in this.state.fishingLicenseErrors && 'license_number' in this.state.fishingLicenseErrors[idx] &&
                                                            <div className='container person-alert mb-2'>
                                                                {this.state.fishingLicenseErrors[idx].license_number.map((error, i) => (
                                                                    <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                        {translateErrorMessage(error)}
                                                                    </div>
                                                                ))}  
                                                            </div>
                                                        }  
                                                    </div>

                                                    <div className="row mb-2">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Vydáno dne:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2">
                                                            <input 
                                                                type="date"
                                                                className="form-control"
                                                                value={fishing_license.issue_date} 
                                                                readOnly={this.elementIsReadOnly('license') || (this.state.fishingLicensePatchingIdx !== -1 && this.state.fishingLicensePatchingIdx !== idx)}
                                                                onChange={(e) => {this.fishingLicenseValueChanged(idx, 'issue_date', e.target.value)}}/>
                                                        </div>
                                                        {idx in this.state.fishingLicenseErrors && 'issue_date' in this.state.fishingLicenseErrors[idx] &&
                                                            <div className='container person-alert mb-2'>
                                                                {this.state.fishingLicenseErrors[idx].issue_date.map((error, i) => (
                                                                    <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                        {translateErrorMessage(error)}
                                                                    </div>
                                                                ))}  
                                                            </div>
                                                        }    
                                                    </div>  

                                                    <div className="row mb-2">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Celosvazová:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2">
                                                            <input 
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={fishing_license.universal}
                                                                disabled={true}
                                                                onChange={(e) => (this.fishingLicenseValueChanged(idx, 'universal', e.target.checked))}/>
                                                        </div>
                                                        {idx in this.state.fishingLicenseErrors && 'universal' in this.state.fishingLicenseErrors[idx] &&
                                                            <div className='container person-alert mb-2'>
                                                                {this.state.fishingLicenseErrors[idx].universal.map((error, i) => (
                                                                    <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                        {translateErrorMessage(error)}
                                                                    </div>
                                                                ))}  
                                                            </div>
                                                        }    
                                                    </div>  

                                                    <div className="row mb-2">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Vrácena:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2">
                                                            <input 
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={fishing_license.returned}
                                                                disabled={true}
                                                                onChange={(e) => (this.fishingLicenseValueChanged(idx, 'returned', e.target.checked))}/>
                                                        </div>
                                                        {idx in this.state.fishingLicenseErrors && 'returned' in this.state.fishingLicenseErrors[idx] &&
                                                            <div className='container person-alert mb-2'>
                                                                {this.state.fishingLicenseErrors[idx].returned.map((error, i) => (
                                                                    <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                        {translateErrorMessage(error)}
                                                                    </div>
                                                                ))}  
                                                            </div>
                                                        }    
                                                    </div>  

                                                    <div className="row mb-2">
                                                        <div className='d-flex justify-content-center'> 
                                                            <a type='button' className='btn btn-outline-dark medium-btn'
                                                                href={`/fish-admin/season-summaries/${fishing_license.id}/`}>
                                                                {fishing_license.returned ? "Sumář úlovků": "Vrátit povolenku"}
                                                            </a>
                                                        </div>
                                                    </div>

                                                    {idx in this.state.fishingLicenseErrors && 'non_field_errors' in this.state.fishingLicenseErrors[idx] &&
                                                        <div className="container person-alert mb-2">
                                                            {this.state.fishingLicenseErrors[idx].non_field_errors.map((error, i) => (
                                                                <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                    {translateErrorMessage(error)}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }

                                                    {this.state.fishingLicensePatchingIdx === idx &&
                                                    <div className="row mb-2">
                                                        <div className="d-flex justify-content-center">
                                                            <div className="btn-group person-btn-group">
                                                                <button 
                                                                    type="button" 
                                                                    className="btn btn-outline-dark person-text-btn"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        
                                                                        let fishingLicenseDidPatch = (response, status) => {
                                                                            if(status === 400){
                                                                                var currentFishingLicenseErrors = this.state.fishingLicenseErrors
                                                                                currentFishingLicenseErrors[this.state.fishingLicensePatchingIdx] = response

                                                                                this.setState({
                                                                                    fishingLicenseErrors:currentFishingLicenseErrors
                                                                                })
                                                                            }else if(status === 200){
                                                                                this.notSwithingAction = true
                                                                                this.toEditMode = true
                                                                                apiLookupPersonDetail(this.personDetailDidFetch, this.personId)
                                                                            }
                                                                        }
                                                                        
                                                                        const data = this.state.fishingLicenses[this.state.fishingLicensePatchingIdx]
                                                                        apiPatchFishingLicense(fishingLicenseDidPatch, data.id, data)
                                                                    }}>Uložit</button>
                                                                
                                                                <button 
                                                                    type="button"
                                                                    className="btn btn-outline-danger person-text-btn"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        var currentFishingLicenses = this.state.fishingLicenses
                                                                        currentFishingLicenses[this.state.fishingLicensePatchingIdx] = this.state.fishingLicenseOriginal
                                                                        
                                                                        this.setState({
                                                                            fishingLicenses:currentFishingLicenses,
                                                                            fishingLicenseOriginal:null,
                                                                            fishingLicensePatchingIdx: -1,
                                                                            fishingLicenseErrors:[],
                                                                            patchFishingLicense:false,
                                                                        })
                                                                    }}>Zrušit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                    {/** POVOLENKY ELEMENTS END */}
                                                </CollapsablePane>
                                            </div>
                                        ))}
                                    </div>
                                    
                                       
                                </div>
                                {/** -----------------  POVOLENKY KONEC --------------------- */}
                                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">                               
                                    <div className="d-flex justify-content-between">
                                        <h5>Sezoní informace:</h5>
                
                                        <div className='d-flex justify-content-center mb-2'>
                                            <button 
                                                className='btn btn-outline-dark person-btn'
                                                disabled={!(!this.elementIsReadOnly('history') && !this.state.patchPersonHistory)}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.setState({
                                                        personHistoryNew:true
                                                    })
                                                }}>
                                                    Nový záznam
                                                <i className="bi bi-plus"></i>
                                            </button>
                                        </div>

                                        <span/>        
                                    </div>
                                    <div className="container ticket-wrapper">
                                        {/* New person history */}
                                        
                                        {this.state.personHistoryNew && 
                                            <div className="row mb-2 new-person-history-pane">
                                                <div className='d-flex justify-content-center'>
                                                    <b className="collapse-header-label">Nové sezoní informace</b>
                                                </div>
                                                <div className="collapsable-body">
                                                    <div className="row mb-2">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Rok:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                            <input 
                                                                type="number" 
                                                                className="form-control" 
                                                                value={this.state.newPersonHistoryInstance.year ? this.state.newPersonHistoryInstance.year : 0}
                                                                readOnly={!this.state.editMode}
                                                                onChange={(e) => {this.personHistoryNewValueChanged('year', e.target.value)}}
                                                                /> 
                                                            {'year' in this.state.personHistoryNewErrors &&
                                                                <div className='container person-alert mb-2'>
                                                                    {this.state.personHistoryNewErrors.year.map((error, i) => (
                                                                        <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                            {translateErrorMessage(error)}
                                                                        </div>
                                                                    ))}  
                                                                </div>
                                                            }
                                                        </div>                                                        
                                                    </div>

                                                    <div className='row mb-2'>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Zaplatil známku:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                            <input 
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={this.state.newPersonHistoryInstance.stamp_paid ? this.state.newPersonHistoryInstance.stamp_paid : false}
                                                                disabled={!this.state.editMode}
                                                                onChange={(e) => {this.personHistoryNewValueChanged('stamp_paid', e.target.checked)}}
                                                                />
                                                            {'stamp_paid' in this.state.personHistoryNewErrors &&
                                                                <div className='container person-alert mb-2'>
                                                                    {this.state.personHistoryNewErrors.stamp_paid.map((error, i) => (
                                                                        <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                            {translateErrorMessage(error)}
                                                                        </div>
                                                                    ))}  
                                                                </div>
                                                            }
                                                        </div>                                                        
                                                    </div>

                                                    <div className='row mb-2'>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Brigády - odpracováno:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                            <div className="input-group">
                                                                <input 
                                                                    type="number" 
                                                                    className="form-control" 
                                                                    value={this.state.newPersonHistoryInstance.worked_hours ? this.state.newPersonHistoryInstance.worked_hours : 0}
                                                                    readOnly={!this.state.editMode}
                                                                    onChange={(e) => {this.personHistoryNewValueChanged('worked_hours', e.target.value)}}
                                                                    /> 
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text" id="basic-addon1">h.&nbsp;</span>
                                                                </div>
                                                                {'worked_hours' in this.state.personHistoryNewErrors &&
                                                                    <div className='container person-alert mb-2'>
                                                                        {this.state.personHistoryNewErrors.worked_hours.map((error, i) => (
                                                                            <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                                {translateErrorMessage(error)}
                                                                            </div>
                                                                        ))}  
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>                                                        
                                                    </div>

                                                    <div className='row mb-2'>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Brigády - zaplaceno:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                            <div className="input-group">
                                                                <input 
                                                                    type="number" 
                                                                    className="form-control" 
                                                                    value={this.state.newPersonHistoryInstance.paid_hours ? this.state.newPersonHistoryInstance.paid_hours : 0}
                                                                    readOnly={!this.state.editMode}
                                                                    onChange={(e) => {this.personHistoryNewValueChanged('paid_hours', e.target.value)}}
                                                                    /> 
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text" id="basic-addon1">h.&nbsp;</span>
                                                                </div>
                                                                {'paid_hours' in this.state.personHistoryNewErrors &&
                                                                    <div className='container person-alert mb-2'>
                                                                        {this.state.personHistoryNewErrors.paid_hours.map((error, i) => (
                                                                            <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                                {translateErrorMessage(error)}
                                                                            </div>
                                                                        ))}  
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>                                                        
                                                    </div>

                                                    <div className='row mb-2'>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Brigády - doplatek:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                            <div className="input-group">
                                                                <input 
                                                                    type="number" 
                                                                    className="form-control" 
                                                                    value={this.state.newPersonHistoryInstance.paid_amount ? this.state.newPersonHistoryInstance.paid_amount : 0}
                                                                    readOnly={!this.state.editMode}
                                                                    onChange={(e) => {this.personHistoryNewValueChanged('paid_amount', e.target.value)}}
                                                                    /> 
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text" id="basic-addon1">Kč</span>
                                                                </div>
                                                                {'paid_amount' in this.state.personHistoryNewErrors &&
                                                                    <div className='container person-alert mb-2'>
                                                                        {this.state.personHistoryNewErrors.paid_amount.map((error, i) => (
                                                                            <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                                {translateErrorMessage(error)}
                                                                            </div>
                                                                        ))}  
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>                                                        
                                                    </div>

                                                    <div className='row mb-2'>
                                                        {'non_field_errors' in this.state.personHistoryNewErrors &&
                                                            <div className='container person-alert mb-2'>
                                                                {this.state.personHistoryNewErrors.non_field_errors.map((error, i) => (
                                                                    <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                        {translateErrorMessage(error)}
                                                                    </div>
                                                                ))}  
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="row mb-2">
                                                        <div className="d-flex justify-content-center">
                                                            <div className="btn-group person-btn-group">
                                                                <button 
                                                                    type="button" 
                                                                    className="btn btn-outline-dark person-text-btn"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                    
                                                                        let personHistoryDidCreate = (response, status) => {
                                                                            if(status === 201){
                                                                                this.toEditMode=true
                                                                                apiLookupPersonDetail(this.personDetailDidFetch, this.personId)
                                                                            }else if(status === 400){
                                                                                this.setState({
                                                                                    personHistoryNewErrors:response
                                                                                })
                                                                            }
                                                                        }
                                                                        var newPersonHistoryInstance = this.state.newPersonHistoryInstance
                                                                        newPersonHistoryInstance['person'] = this.personId

                                                                        apiCreatePersonHistory(personHistoryDidCreate, newPersonHistoryInstance)
                                                                    }}>Přidat</button>
                                                                <button 
                                                                    type="button"
                                                                    className="btn btn-outline-danger person-text-btn"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.setState({
                                                                            personHistoryNew:false,
                                                                            personHistoryNewBlank:true, 
                                                                            newPersonHistoryInstance:{'stamp_paid':false},
                                                                            personHistoryNewErrors:[]
                                                                        })
                                                                    }}>Zrušit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        }
                                        
                                        {/* End new person history */}

                                        {this.state.personHistory.map((historyInfo, idx) => (
                                            <div className='row mb-2' 
                                                key={idx}>
                                                <CollapsablePane
                                                    label={historyInfo.year}
                                                    collapsed={idx > 0}
                                                    showDelete={!this.elementIsReadOnly('history') && (this.state.personHistoryPatchingIdx === idx || this.state.personHistoryPatchingIdx === -1)}
                                                    deleteModalMessage={"Odstranit"}
                                                    deleteModalSubMessage={`Opravdu odstranit záznamy pro rok ${historyInfo.year}?`}
                                                    onDeleteCallback={(e) => (this.personHistoryDeleteCallback(historyInfo.id))}>
                                                    <div className="row mb-2">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Zaplatil známku:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2">
                                                            <input 
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={historyInfo.stamp_paid}
                                                                disabled={this.elementIsReadOnly('history')}
                                                                onChange={(e) => (this.personHistoryValueChanged(idx, 'stamp_paid', e.target.checked))}/>
                                                        </div>
                                                        {idx in this.state.personHistoryErrors && 'stamp_paid' in this.state.personHistoryErrors[idx] &&
                                                            <div className='container person-alert mb-2'>
                                                                {this.state.personHistoryErrors[idx].stamp_paid.map((error, i) => (
                                                                    <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                        {translateErrorMessage(error)}
                                                                    </div>
                                                                ))}  
                                                            </div>
                                                        }  
                                                    </div> 

                                                    <div className="row mb-2">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Brigády - odpracováno:</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                            <div className="input-group">
                                                                <input 
                                                                    type="number" 
                                                                    className="form-control" 
                                                                    value={historyInfo.worked_hours}
                                                                    readOnly={this.elementIsReadOnly('history') || (this.state.personHistoryPatchingIdx !== -1 && this.state.personHistoryPatchingIdx !== idx)}
                                                                    onChange={(e) => (this.personHistoryValueChanged(idx, 'worked_hours', e.target.value))}/> 
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text" id="basic-addon1">h.&nbsp;</span>
                                                                </div>
                                                            </div>
                                                            {idx in this.state.personHistoryErrors && 'worked_hours' in this.state.personHistoryErrors[idx] &&
                                                                <div className='container person-alert mb-2'>
                                                                    {this.state.personHistoryErrors[idx].worked_hours.map((error, i) => (
                                                                        <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                            {translateErrorMessage(error)}
                                                                        </div>
                                                                    ))}  
                                                                </div>
                                                            }
                                                        </div>  
                                                    </div> 

                                                    <div className="row mb-2">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Brigády - zaplaceno</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                            <div className="input-group">
                                                                <input 
                                                                    type="number" 
                                                                    className="form-control" 
                                                                    value={historyInfo.paid_hours}
                                                                    readOnly={this.elementIsReadOnly('history') || (this.state.personHistoryPatchingIdx !== -1 && this.state.personHistoryPatchingIdx !== idx)}
                                                                    onChange={(e) => (this.personHistoryValueChanged(idx, 'paid_hours', e.target.value))}/> 
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text" id="basic-addon1">h.&nbsp;</span>
                                                                </div>
                                                            </div>
                                                            {idx in this.state.personHistoryErrors && 'paid_hours' in this.state.personHistoryErrors[idx] &&
                                                                <div className='container person-alert mb-2'>
                                                                    {this.state.personHistoryErrors[idx].paid_hours.map((error, i) => (
                                                                        <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                            {translateErrorMessage(error)}
                                                                        </div>
                                                                    ))}  
                                                                </div>
                                                            }
                                                        </div>  
                                                    </div> 

                                                    <div className="row mb-2">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-2"> 
                                                            <b>Brigády - doplatek</b>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                                            <div className="input-group">
                                                                <input 
                                                                    type="number" 
                                                                    className="form-control" 
                                                                    value={historyInfo.paid_amount}
                                                                    readOnly={this.elementIsReadOnly('history') || (this.state.personHistoryPatchingIdx !== -1 && this.state.personHistoryPatchingIdx !== idx)}
                                                                    onChange={(e) => (this.personHistoryValueChanged(idx, 'paid_amount', e.target.value))}/> 
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text" id="basic-addon1">Kč</span>
                                                                </div>
                                                            </div>
                                                            {idx in this.state.personHistoryErrors && 'paid_amount' in this.state.personHistoryErrors[idx] &&
                                                                <div className='container person-alert mb-2'>
                                                                    {this.state.personHistoryErrors[idx].paid_amount.map((error, i) => (
                                                                        <div className="d-flex justify-content-center" key={`${i}-${error}`}>
                                                                            {translateErrorMessage(error)}
                                                                        </div>
                                                                    ))}  
                                                                </div>
                                                            }
                                                        </div>  
                                                    </div> 

                                                    {this.state.personHistoryPatchingIdx === idx &&
                                                    <div className="row mb-2">
                                                        <div className="d-flex justify-content-center">
                                                            <div className="btn-group person-btn-group">
                                                                <button 
                                                                    type="button" 
                                                                    className="btn btn-outline-dark person-text-btn"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        
                                                                        let personHistoryDidPatch = (response, status) => {
                                                                            if(status === 400){
                                                                                var currentPersonHistoryErrors = this.state.personHistoryErrors
                                                                                currentPersonHistoryErrors[this.state.personHistoryPatchingIdx] = response

                                                                                this.setState({
                                                                                    personHistoryErrors:currentPersonHistoryErrors
                                                                                })
                                                                            }else if(status === 200){
                                                                                this.notSwithingAction = true
                                                                                this.toEditMode = true
                                                                                apiLookupPersonDetail(this.personDetailDidFetch, this.personId)
                                                                            }
                                                                        }
                                                                        
                                                                        const data = this.state.personHistory[this.state.personHistoryPatchingIdx]
                                                                        apiPatchPersonHistory(personHistoryDidPatch, data.id, data)
                                                                    }}>Uložit</button>
                                                                
                                                                <button 
                                                                    type="button"
                                                                    className="btn btn-outline-danger person-text-btn"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        var currentPersonHistory = this.state.personHistory
                                                                        currentPersonHistory[this.state.personHistoryPatchingIdx] = this.state.personHistoryOriginal
                                                                        
                                                                        this.setState({
                                                                            personHistory:currentPersonHistory,
                                                                            personHistoryOriginal:null,
                                                                            personHistoryPatchingIdx: -1,
                                                                            personHistoryErrors:[],
                                                                            patchPersonHistory:false,
                                                                        })
                                                                    }}>Zrušit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                </CollapsablePane>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }
                    </form>

                    {(this.state.editMode && !this.personId) && <div className="d-flex justify-content-center">
                        <button type='button' className='btn btn-outline-dark' onClick={this.createPersonData}>Vytvořit</button>
                    </div>}
                        
                    <ModalDialog
                        show={this.state.showDeletePersonModal}
                        message="Odstranit"
                        subMessage={`Určitě chcete odstranit člena ${this.state.personObject[0].full_name} a všechny související data?`}
                        onBtn1={(e) => {

                            let personDetailDidDeleted = (_response, status) => {
                                if(status === 204){
                                    window.location.href = '/fish-admin/persons/'                              
                                }
                                else{
                                    alert("Něco se pokazilo, opakujte prosím později.")
                                }
                            }

                            apiDeletePersonDetail(personDetailDidDeleted, this.personId)

                            this.setState({
                                showDeletePersonModal:false
                            })
                        }}
                        onBtn2={(e) => {
                            this.setState({
                                showDeletePersonModal:false
                            })
                        }}/>
                </div>
            )
        }
        else{
            return null;
        }
    }
}


import GridViewComponent from "../../gridview/GridViewComponent"

import {
    apiDeleteMultipleRegions,
    apiLookupRegions,
    apiLookupRegionsNonTrout,
    apiLookupRegionsTrout,
} from "./actions";

export default class IssuingOrganizationsGridViewComponent extends GridViewComponent{

    constructor(props){
        super(props)
        this.title = ""
        this.queryTypeAll = 'ALL'
        this.queryTypeTrout = 'TRT'
        this.queryTypeNTrout = 'NTRT'
        this.queryType = props.queryType ? props.queryType : this.queryTypeAll
        this.loadData = this.loadData.bind(this)
        this.dataDidLoad = this.dataDidLoad.bind(this)
    }

    componentDidMount(){
        this.loadData()
    }

    gridNewAction(){
        window.location.href = "/regions/create/"
    }

    gridRowHref(row){
        return `/regions/update/${row.id}/`
    }

    gridDeleteAction(){
        
        let dataDidDeleted = (response, status) => {
            if(status === 200){
                this.setState({
                    showDeleteModal:false
                })
                this.loadData()
            }else if(status === 400){
                this.setState({
                    showDeleteModal:false,
                    showDeleteErrorInfoBox:true,
                    deleteErrorInfoSubMessage:response.message
                })
            }else if(status === 500){
                alert("Něco se pokazilo, opakujte později.")
            }
        }
        
        var regionIds = new Set()
        for(let idx of this.state.selectedDataIdxs){
            regionIds.add(this.state.data[idx].id)
        }

        const regionsToDelete = [...regionIds].join(",")

        apiDeleteMultipleRegions(dataDidDeleted, regionsToDelete)
    }

    /**
     * callback used when season summaries data are retreived from server
     * @param {*} response - response from the serve
     * @param {*} status  - html status code
     */
    dataDidLoad(response, status){
        if(status === 200){
            this.state.selectedDataIdxs.clear()
            this.setState({
                data:response,
                columns: this.state.columns.length > 0 ? this.state.columns :[
                    {
                        "name": "name",
                        "label": "Název",
                        "type": this.SEARCHTYPETEXT
                    },
                    {
                        "name": "region_number",
                        "label": "Číslo revíru",
                        "type": this.SEARCHTYPETEXT
                    },
                    {
                        "name": "type_str_simple",
                        "label": "Typ",
                        "type": this.SEARCHTYPETEXT
                    },
                ],
                header:this.title,
                hrefColumn: this.state.hrefColumn ? this.state.hrefColumn : 'name',
                searchColumn:this.state.searchColumn === "" ? 0 : this.state.searchColumn,
            })
        }       
    }

    loadData(){
        switch(this.queryType){
            case this.queryTypeTrout:
                this.title = "Pstruhové revíry"
                apiLookupRegionsTrout(this.dataDidLoad)
                break;
            case this.queryTypeNTrout:
                this.title = "Mimopstruhové revíry"
                apiLookupRegionsNonTrout(this.dataDidLoad)
                break;
            default:
                this.title = "Revíry"
                apiLookupRegions(this.dataDidLoad)
                break;
        }
    }
}
import React from "react";

import {
    apiLookupFishingLicenseSeasonSummary,
    apiLookupFishingLicensesSimple,
    apiLookupRegionsSimple,
    apiPatchSeasonSummary,
    apiCreateSeasonSummary,
    apiDeleteSeasonSummary,
    apiSeasonSummaryRegionPatchFishingLicense,
    apiDeleteFishingLicenseSeasonSummaries,

    // CRUD for Season summary region lines
    apiCreateSeasonSummaryRegionLine,
    apiDeleteSeasonSummaryRegionLine,
    apiPatchSeasonSummaryRegionLine,

    // Lookup Fish Spiecies
    apiLookupSpiecies,
} from './actions'

import LookupComponent from "../../lookup/LookupComponent";
import ModalDialog from '../../modal/components/ModalDialog';
import DictErrorListComponent from "../../global/components/error_components/DictErrorListComponent";
import MultiLineDictErrorListComponent from "../../global/components/error_components/MultiLineDictErrorListComponent";
import ErrorListComponent from "../../global/components/error_components/ErrorListComponent";
import ActionMenu from "../../global/components/menu_components/ActionMenu";

export default class SeasonSummaryDetailComponent extends React.Component{

    constructor(props){
        super(props)
        this.pythonTrue     = "True"
        
        this.seasonSummaryRegionsToPatch = new Set()
        this.seasonSummaryRegionLinesToPatch = new Set()
        this.seasonSummaryRegionLinesNotCreated = []

        // indicates whenever to keep position selected by users
        this.keepPosition = true

        this.keepPositionOfficers = true

        this.state = ({
            /*
            Is component in create mode
            -   create mode allows to pick the license, create mode should be yes only if the fishing license 
                doesn't have any season summary related lines created 
            */
            createMode:props.createMode === this.pythonTrue,

            // contains all the data about season summary for given fishing license
            data:{},

            // only season summaries data for given fishing license
            seasonSummaries:[],

            // original value of seasonSummaries variable, used when discard button is used
            seasonSummariesOriginal:[],

            /*
            Variable corresponding to the new line for Season summary region
            -   will be submited to server whenever user is trying to create new line (header / line)
            */
            newSeasonSummaryRegionCreated:false,

            /*
            Variable of new record for Season summary region line
            -   will be submitted to server whenever user is trying to create new line (header / line)
            */
            newSeasonSummaryRegionLine:{},

            // fishing licenses for the lookup
            fishingLicensesSimple:[],

            // default selected fishing license for the lookup
            fishingLicenseDefaultSelected:{},

            // license if passed from props
            licenseId: props.licenseId ? parseInt(props.licenseId) : 0,

            // was license id changed?
            licenseIdModified: false,

            // original value of license id
            licenseIdOriginal: props.licenseId ? parseInt(props.licenseId) : 0,

            // use as a key on license id lookup, if discard button is clicked this variable is incemented to refreh the control to the original state
            licenseIdLookupRefreshCounter: 0,

            // idx of currently selected record of Seson summary region grid
            currentSummaryRegionIdx:0,

            // idx of currently selected record of Season summary region lines
            currentSummaryRegionLineIdx:0,

            // regions for the lookup
            regionsSimple:[],

            // map of regions for fast search from id
            regionsSimpleMap: {},

            // every time that simple regions are donwloaded from server this veriable is incretmented, to refresh controls
            regionsSimpleLoadedCount: 0,

            // errors received from server during patch action of season summary regions
            seasonSummaryPathErrors:{},

            // errors recieved from server during creation of new season summary region record
            seasonSummaryCreateErrors:{},

            // indicates if something on season summary region was modified
            seasonSummaryRegionModified:false,

            // errors recieved from server during path action of season summary region lines
            seasonSummaryLinePatchErrors:{},

            // errors recieved from server during creation of new Season summary region line
            seasonSummaryLineCreateErrors:[],

            // indicates if something on season summary region line was modified
            seasonSummaryRegionLineModified:false,

            // show delete modal for season summary region
            showDeleteSeasonSummaryRegionModal:false,

            // hold all the spieces available to enter in the season summaries
            spiecies:[],

            // map of spiecies for fast search from id
            spieciesMap: {},

            // stores count of newly created records in season summary region lines
            seasonSummaryRegionLineNewRecords:0,

            // indicates whenever during attempt of creation multiple region lines something was not created ok
            seasonSummaryRegionLineCreationFailed:false,

            // is the delete dialog for season summary region line visible
            showDeleteSeasonSummaryRegionLineModal:false,

            deleteModalShow:false,
        })

        this.loadLicenseDetails = this.loadLicenseDetails.bind(this)
        this.summaryFishingLicenseSelected = this.summaryFishingLicenseSelected.bind(this)
        this.lookupRegionsSimple = this.lookupRegionsSimple.bind(this)
        this.seasonSummaryRegionSelected = this.seasonSummaryRegionSelected.bind(this)
        this.seasonSummaryRegionFieldChange = this.seasonSummaryRegionFieldChange.bind(this)
        this.patchSeasonSummaryRegions = this.patchSeasonSummaryRegions.bind(this)
        this.saveBtnClicked = this.saveBtnClicked.bind(this)
        this.seasonSummaryRegionNewOnClick = this.seasonSummaryRegionNewOnClick.bind(this)
        this.seasonSummaryRegionCreateOnClick = this.seasonSummaryRegionCreateOnClick.bind(this)
        this.patchFishingLicenseId = this.patchFishingLicenseId.bind(this)
        this.discardButtonClicked = this.discardButtonClicked.bind(this)
        this.seasonSummaryRegionLineFieldChange = this.seasonSummaryRegionLineFieldChange.bind(this)
        this.lookupSpiecies = this.lookupSpiecies.bind(this)
        this.seasonSummaryRegionLineSpieciesSelected = this.seasonSummaryRegionLineSpieciesSelected.bind(this)
        this.patchSeasonSummaryRegionLines = this.patchSeasonSummaryRegionLines.bind(this)
        this.seasonSummaryRegionLineNewOnClick = this.seasonSummaryRegionLineNewOnClick.bind(this)
        this.validateNewSeasonSummaryRegionLines = this.validateNewSeasonSummaryRegionLines.bind(this)
        this.createNewSeasonSummaryRegionLines = this.createNewSeasonSummaryRegionLines.bind(this)
        this.seasonSummaryRegionSaveAction = this.seasonSummaryRegionSaveAction.bind(this)
        this.seasonSummaryRegionCancelAction = this.seasonSummaryRegionCancelAction.bind(this)
        this.isAreEditable_SeasonSummaryRegion = this.isAreEditable_SeasonSummaryRegion.bind(this)
        this.isAreaEditable_SeasonSummaryRegionLine = this.isAreaEditable_SeasonSummaryRegionLine.bind(this)
        this.seasonSummaryRegionLineSaveAction = this.seasonSummaryRegionLineSaveAction.bind(this)
        this.seasonSummaryRegionLineCancelAction = this.seasonSummaryRegionLineCancelAction.bind(this)
        this.deleteSeasonSummary = this.deleteSeasonSummary.bind(this)
    }

    loadLicenseDetails(){
        let licenseDetailsDidFetch = (response, status) => {
            if(status === 200){   
                var recievedSeasonSummaries =  JSON.parse(JSON.stringify(response.season_summaries))

                if(this.state.currentSummaryRegionIdx >= 0){
                    const currentSeasonSummary = recievedSeasonSummaries[this.state.currentSummaryRegionIdx]
                    var currentSeasonSummaryLines = []
                    
                    if(currentSeasonSummary && currentSeasonSummary.season_summary_region_line.length > 0){
                        currentSeasonSummaryLines =  recievedSeasonSummaries[this.state.currentSummaryRegionIdx].season_summary_region_line
                    }

                    if(this.seasonSummaryRegionLinesNotCreated.length > 0){
                        recievedSeasonSummaries[this.state.currentSummaryRegionIdx].season_summary_region_line = this.seasonSummaryRegionLinesNotCreated.concat(currentSeasonSummaryLines)
                    }  
                }
                
                this.setState({
                    data:response,
                    seasonSummaries: recievedSeasonSummaries,
                    seasonSummariesOriginal: response.season_summaries,
                    createMode:response.season_summaries.length === 0,
                    officerChecks:response.license_officer_checks,
                    officerChecksOriginal:JSON.parse(JSON.stringify(response.license_officer_checks)),
                    newSeasonSummaryRegionCreated:false,
                    seasonSummaryCreateErrors:{},
                    seasonSummaryPathErrors:{},
                    seasonSummaryLineCreateErrors: this.seasonSummaryRegionLinesNotCreated.length > 0 ? ["Něco se pokazilo, zkontrolujte vkládaná data a opakujte později."] : [],
                    licenseIdModified: false,
                    seasonSummaryRegionModified: false,
                    seasonSummaryRegionLineModified: false,
                    licenseIdOriginal: this.state.licenseId,
                    currentSummaryRegionIdx: this.keepPosition ? this.state.currentSummaryRegionIdx : 0,
                    currentSummaryRegionLineIdx: this.keepPosition ? this.state.currentSummaryRegionLineIdx : 0,
                    officersChecksCurrIdx: this.keepPositionOfficers ? this.state.officersChecksCurrIdx : 0,
                    seasonSummaryRegionLineNewRecords:this.seasonSummaryRegionLinesNotCreated.length,
                    seasonSummaryRegionLineCreationFailed: this.seasonSummaryRegionLinesNotCreated.length > 0,
                    officerChecksModified:false,
                    officersChecksNew:false,
                    officersChecksErrors:{},
                    officersChecksPatchErrors:{}             
                })
                this.seasonSummaryRegionLinesNotCreated = []
                this.keepPosition = true
                this.keepPositionOfficers = true
            }else{
                alert("Něco se pokazilo, opakujte později.")
            }
        }

        apiLookupFishingLicenseSeasonSummary(licenseDetailsDidFetch, this.state.licenseId)
    }

    lookupFishingLicenses(){
        let fishingLicensesDidFetch = (response, status) => {
            if(status === 200){

                const licensesSimpleAll = response.map((fishingLicense, id) => ({
                    id:fishingLicense.id, 
                    value:fishingLicense.license_number_person, 
                    returned:fishingLicense.returned
                }))

                // for create mode allow to pick from anything
                const licensesSimpleNotReturned = licensesSimpleAll.filter((fishingLicense) => !fishingLicense.returned)
                const defaultLicenseSelected = licensesSimpleAll.filter((fishingLicense) => fishingLicense.id === this.state.licenseId)

                this.setState({
                    fishingLicensesSimple:licensesSimpleNotReturned,
                    fishingLicenseDefaultSelected: defaultLicenseSelected.length > 0 ? defaultLicenseSelected[0]: ""
                })
            }else{
                alert("Něco se pokazilo, opakujte později.")
            }
        }

        apiLookupFishingLicensesSimple(fishingLicensesDidFetch)
    }

    lookupRegionsSimple(){
        let regionsSimpleDidFetched = (response, status) => {
            if(status===200){
                const responseToSimpleRegions = response.map((region, id) => ({id:region.id, value:region.number_name}))
                var responseToSimpleRegionsMap = {}
            
                for(const simpleRegion of response){
                    responseToSimpleRegionsMap[simpleRegion.id] = simpleRegion
                }
                       
                this.setState({
                    regionsSimple:responseToSimpleRegions,
                    regionsSimpleMap: responseToSimpleRegionsMap,
                    regionsSimpleLoadedCount: this.state.regionsSimpleLoadedCount + 1,
                })
            }else{
                alert("Něco se pokazilo, opakujte později.")
            }
        }

        apiLookupRegionsSimple(regionsSimpleDidFetched)
    }

    /**
     * Retrieves all the Fish Spiecies from the server, set the state variables with retrieved values
     * Constructs also map mapping the id to the object for fast searching
     */
    lookupSpiecies(){
        let spieciesDidLookup = (response, status) => {
            if(status===200){
                const responseToSpieces = response.map((spieces, id) => ({id:spieces.id, value:spieces.name}))
                var responseToSpieciesMap = {}
            
                for(const spiecies of response){
                    responseToSpieciesMap[spiecies.id] = spiecies
                }

                this.setState({
                    spiecies:responseToSpieces,
                    spieciesMap:responseToSpieciesMap
                })
            }else{
                alert("Něco se pokazilo, opakujte později.")
            }
        }

        apiLookupSpiecies(spieciesDidLookup)
    }

    componentDidMount(){
        if(this.state.licenseId){
            this.loadLicenseDetails()
        }
        this.lookupFishingLicenses()
        this.lookupRegionsSimple()
        this.lookupSpiecies()
    }

    summaryFishingLicenseSelected(id){
        if(this.state.newSeasonSummaryRegionCreated && this.state.createMode){
            var currentSeasonSummaryNewRecord = this.state.seasonSummaries[0]

            currentSeasonSummaryNewRecord.fishing_license = id

            this.setState({
                seasonSummaries:[currentSeasonSummaryNewRecord]
            })
        }

        this.setState({
            licenseIdOriginal: this.state.licenseIdOriginal === 0 ? this.state.licenseId : this.state.licenseIdOriginal, 
            licenseId:id,
            licenseIdModified:true,
        })
    }

    seasonSummaryRegionSelected(id, rowIdx){
        this.seasonSummaryRegionFieldChange(rowIdx, 'region', id)
    }

    // call when some field in season summary is modified
    seasonSummaryRegionFieldChange(idx, fieldName, value){        
        var currentSeasonSummaryRegions = JSON.parse(JSON.stringify(this.state.seasonSummaries)); 
        
        currentSeasonSummaryRegions[idx][fieldName] = value
        
        // indicate that given Season summary is ready for patch
        this.seasonSummaryRegionsToPatch.add(idx)
        
        this.setState({
            seasonSummaries:currentSeasonSummaryRegions,
            seasonSummaryRegionModified: true,
        })        
    }

    /** call when some field in season summary region line is modified
     * @param {number} idx, the index of seasonSummaryRegionLine object in it's paret, Season Summary Region        
     * @param {name} fieldName, field name of the field that has been modified
     * @param {var} value, new valu of modified field
     */
    seasonSummaryRegionLineFieldChange(idx, fieldName, value){
        var currentSeasonSummaryRegions = JSON.parse(JSON.stringify(this.state.seasonSummaries)); 
        var currentSeasonSummaryRegionLine = currentSeasonSummaryRegions[this.state.currentSummaryRegionIdx].season_summary_region_line

        currentSeasonSummaryRegionLine[idx][fieldName] = value

        // new records will be just created, not patched
        if(idx >= this.state.seasonSummaryRegionLineNewRecords){
            this.seasonSummaryRegionLinesToPatch.add(idx)
        }

        this.setState({
            seasonSummaries: currentSeasonSummaryRegions,
            seasonSummaryRegionLineModified:true,
        })
    }

    /** Called when Spiecies is selected from the dropdown in Season Summary Region Lines
     * @param {*} id, the id of the Spiecies in the DB
     * @param {*} rowIdx , the id of the Season Summary Region Line row, where the spiecies was selected
     */
    seasonSummaryRegionLineSpieciesSelected(id, rowIdx){
        this.seasonSummaryRegionLineFieldChange(rowIdx, 'spiecies', id)
    }

    // perform the patch of all the season summary regions, if one wrong is skipped, the rest are performed
    patchSeasonSummaryRegions(){
        var currentPatchIdx = 0;
        var ret = true
        var patchErrors = {}

        // convert the set into the list
        var seasonSummaryRegionsToPatchList = [...this.seasonSummaryRegionsToPatch]

        // to store records which has been updated with errors
        var seasonSummaryPatchedWithErrors = new Set();

        // get data to update to the right form and to the list
        const seasonSummaryToPatchList = seasonSummaryRegionsToPatchList.map((seasonSummaryIdx, idx) => ({
            id:this.state.seasonSummaries[seasonSummaryIdx].id,
            region:this.state.seasonSummaries[seasonSummaryIdx].region === -1 ? '' : this.state.seasonSummaries[seasonSummaryIdx].region,
            attendances:this.state.seasonSummaries[seasonSummaryIdx].attendances
        }))

        const seasonSummaryToPatchListLength = seasonSummaryToPatchList.length

        // patch callback
        let seasonSummaryDidPatched = (response, status) => {

            // gather all the errors returned from server to one array
            if(status !== 200){
                ret = false
                patchErrors[seasonSummaryToPatchList[currentPatchIdx].id] = response
                seasonSummaryPatchedWithErrors.add(seasonSummaryRegionsToPatchList[currentPatchIdx])
            }

            //refresh
            if(seasonSummaryToPatchListLength === currentPatchIdx + 1){
                this.seasonSummaryRegionsToPatch = seasonSummaryPatchedWithErrors

                // refresh only if everything was fine
                if(ret === true){
                    this.loadLicenseDetails()
                }else{
                    this.setState({
                        seasonSummaryPathErrors:patchErrors
                    })
                }
            }else{
                currentPatchIdx += 1
                apiPatchSeasonSummary(seasonSummaryDidPatched, seasonSummaryToPatchList[currentPatchIdx].id, seasonSummaryToPatchList[currentPatchIdx])
            }
        }
        
        if(seasonSummaryToPatchList.length > 0)
        {
            apiPatchSeasonSummary(seasonSummaryDidPatched, seasonSummaryToPatchList[currentPatchIdx].id, seasonSummaryToPatchList[currentPatchIdx])
        }
    }

    /**
     * Patch all the modified Season Summary Region Lines in the sequence
     */
    patchSeasonSummaryRegionLines(){
        var currentPatchIdx = 0;
        var ret = true

        const currentSeasonSummary = this.state.seasonSummaries[this.state.currentSummaryRegionIdx]

        // construct list of season summary lines to patch
        const seasonSummaryLinesPatchList = [...this.seasonSummaryRegionLinesToPatch].map((seasonSummaryRegionLineIdx, idx) => ({
            id:currentSeasonSummary.season_summary_region_line[seasonSummaryRegionLineIdx].id,
            season_summary_region:currentSeasonSummary.season_summary_region_line[seasonSummaryRegionLineIdx].season_summary_region,
            spiecies:currentSeasonSummary.season_summary_region_line[seasonSummaryRegionLineIdx].spiecies === -1 ? "" : currentSeasonSummary.season_summary_region_line[seasonSummaryRegionLineIdx].spiecies,
            amount:currentSeasonSummary.season_summary_region_line[seasonSummaryRegionLineIdx].amount,
            weight:currentSeasonSummary.season_summary_region_line[seasonSummaryRegionLineIdx].weight,
        }))

        // length of list to update
        const seasonSummaryLinesPatchListLength = seasonSummaryLinesPatchList.length

        let seasonSummaryLineDidPatch = (response, status) => {

            // gather all the error messages
            if(status !== 200){
                ret = false;

                // gether all the errors for various spiecies
            }

            // refresh all 
            if(currentPatchIdx + 1 === seasonSummaryLinesPatchListLength){

                // refresh only if everything was pathched successfully
                if(ret){
                    this.loadLicenseDetails()
                }
            }else{
                currentPatchIdx += 1
                apiPatchSeasonSummaryRegionLine(seasonSummaryLineDidPatch, seasonSummaryLinesPatchList[currentPatchIdx].id, seasonSummaryLinesPatchList[currentPatchIdx])
            }
        }

        const currentLines = this.state.seasonSummaries[this.state.currentSummaryRegionIdx].season_summary_region_line
        if(seasonSummaryLinesPatchList.length > 0)
        {
            if(this.validateNewSeasonSummaryRegionLines([], currentLines))
            {      
                apiPatchSeasonSummaryRegionLine(seasonSummaryLineDidPatch, seasonSummaryLinesPatchList[currentPatchIdx].id, seasonSummaryLinesPatchList[currentPatchIdx])   
            }
        }
    }

    // Determines whenever discard changes button in right hand corner is visible
    isDiscardButtonVisible(){
        return (this.state.licenseIdModified || this.state.seasonSummaryRegionModified || this.state.seasonSummaryRegionLineModified ||
            this.state.seasonSummaryRegionLineNewRecords > 0)
    }

    patchFishingLicenseId(){
        const data = {
            "license_id":this.state.licenseId
        }
        
        let fishingLicenseDidPatch = (response, status) => {
            if(status === 200){
                this.loadLicenseDetails()
            }else{
                alert("Něco se pokazilo, opakujte později.")
            }
        }

        apiSeasonSummaryRegionPatchFishingLicense(fishingLicenseDidPatch, this.state.licenseIdOriginal, data)
    }

    saveBtnClicked(){
        if(this.state.licenseIdModified===true){
            this.patchFishingLicenseId()
        }    
    }

    discardButtonClicked(){

        // license was modified, change it back
        if(this.state.licenseIdModified){
            this.setState({
                licenseId: this.state.licenseIdOriginal,
                licenseIdModified: false,
                licenseIdLookupRefreshCounter: this.state.licenseIdLookupRefreshCounter + 1,
            })
        }
        // season summary region was modified - discard all the changes
        else if(this.state.seasonSummaryRegionModified || this.state.seasonSummaryRegionLineModified ||
            this.state.seasonSummaryRegionLineNewRecords > 0){
                this.seasonSummaryRegionLinesNotCreated = []
                this.setState({
                    seasonSummaries:this.state.seasonSummariesOriginal,
                    seasonSummaryRegionModified: false,
                    seasonSummaryRegionLineModified:false,
                    seasonSummaryPathErrors: {},
                    seasonSummaryLinesPatchList: {},
                    seasonSummaryRegionLineNewRecords:0,
                    seasonSummaryLineCreateErrors:[],
                    seasonSummaryRegionLineCreationFailed: false,
                    seasonSummaryCreateErrors:{},
                    newSeasonSummaryRegionCreated:false,
                })
        }
    }

    seasonSummaryRegionNewOnClick(){
        const seasonSummaryRegionNewRecord = {
            fishing_license:this.state.licenseId,
            region:-1,
            attendances:0,
            season_summary_region_line:[] 
        }

        const nesSeasonSummaries = [seasonSummaryRegionNewRecord].concat(this.state.seasonSummaries)

        this.setState({
            seasonSummaries:nesSeasonSummaries,
            newSeasonSummaryRegionCreated:true,
            currentSummaryRegionIdx:0,
        })
    }

    seasonSummaryRegionCreateOnClick(){
        let seasonSummaryDidCreated = (response, status) => {
            if(status===201){
                this.loadLicenseDetails()
            }else{
                this.setState({
                    seasonSummaryCreateErrors:response
                })
            }
        }

        apiCreateSeasonSummary(seasonSummaryDidCreated, this.state.seasonSummaries[0])
    }

    /**
     *  Creates new record in the season summary region lines related to the current season summary
     */
    seasonSummaryRegionLineNewOnClick(){
        var currentSeasonSummaries = JSON.parse(JSON.stringify(this.state.seasonSummaries)); 
        var currentSeasonSummary = currentSeasonSummaries[this.state.currentSummaryRegionIdx]
        var resultSeasonSummaryLines = []

        // create new records
        const newRecord = {
            spiecies:-1,
            amount:0,
            weight:0.0,
            season_summary_region:this.state.seasonSummaries[this.state.currentSummaryRegionIdx].id
        }

        // gather records already stored in db and append them after new record
        if(currentSeasonSummary.season_summary_region_line){
            resultSeasonSummaryLines = [newRecord].concat([...currentSeasonSummary.season_summary_region_line])
        }else{
            resultSeasonSummaryLines = [newRecord]
        }

        // update the current summary with the result
        currentSeasonSummary.season_summary_region_line = resultSeasonSummaryLines
        currentSeasonSummaries[this.state.currentSummaryRegionIdx] = currentSeasonSummary

        // set state
        this.setState({
            seasonSummaries: currentSeasonSummaries,
            seasonSummaryRegionLineNewRecords: this.state.seasonSummaryRegionLineNewRecords + 1,
        })
    }


    /**
     * 
     * @param {Array} newLines - list of new Season Summary Region lines to be created 
     * @param {Array} existingLines - list of already existing Season Summary Region Lines in db
     * @returns {Boolean} true if all new lines are ok, false otherwise
     */
    validateNewSeasonSummaryRegionLines(newLines, existingLines){
        var spieciesMissing = false
        var spieciesUsedTwice = false
        var weightIsZero = false
        var amountIsZero = false
        var usedSpieces = new Set()
        var errorList = []

        const allLines = newLines.concat(existingLines)

        for(let i = 0; i < allLines.length; i++){
            const line = allLines[i]

            // Spiecies is mandatory
            if(!line.spiecies || line.spiecies === -1)
            {
                spieciesMissing = true
            }else{
                // Spiecies can be used just once per region
                if(usedSpieces.has(line.spiecies)){
                    spieciesUsedTwice = true
                }else{
                    usedSpieces.add(line.spiecies)
                }
            }
            
            // weight cannot be zero
            if(line.weight === 0.00 || line.weight === '0'){
                weightIsZero = true 
            }

            // amount cannot be zero
            if(line.amount === 0 || line.amount === '0'){
                amountIsZero = true
            }
        }

        if(spieciesMissing){
            errorList.push("Druh musí být vyplněn.")
        }

        if(spieciesUsedTwice){
            errorList.push("Druh může být uveden pouze jednou.")
        }
        
        if(weightIsZero){
            errorList.push("Váha nesmí být nula.")
        }

        if(amountIsZero){
            errorList.push("Počet nesmí být nula.")
        }

        this.setState({
            seasonSummaryLineCreateErrors:errorList
        })

        return (!spieciesMissing && !spieciesUsedTwice && !weightIsZero && !amountIsZero);   
    }

    /**
     * Creates new season summary region lines
     */
    createNewSeasonSummaryRegionLines(){
        const allLines = this.state.seasonSummaries[this.state.currentSummaryRegionIdx].season_summary_region_line
        const newLines = allLines.slice(0, this.state.seasonSummaryRegionLineNewRecords).reverse()
        var errorLines = []
        var idx = 0

        // record created callback will execute insertion of another records
        let seasonSummaryRegionLineDidCreate = (response, status) => {

            // gather lines which has not been processed
            if(status !== 201){
                errorLines.push(newLines[idx])
            }
            
            if(idx + 1 === newLines.length){
                // end the process
                this.seasonSummaryRegionLinesNotCreated = errorLines
                this.loadLicenseDetails()
            }else{
                // process another line
                idx += 1
                apiCreateSeasonSummaryRegionLine(seasonSummaryRegionLineDidCreate, newLines[idx])  
            }       
        }
        
        // start processing only if all is validated
        if(idx < newLines.length){
            apiCreateSeasonSummaryRegionLine(seasonSummaryRegionLineDidCreate, newLines[idx])
        }   
    }   


    /**
     * Save action for season summary region
     * determines whenever to save new record or patch the modified ones
     */
    seasonSummaryRegionSaveAction(){
        if(this.state.newSeasonSummaryRegionCreated){
            this.seasonSummaryRegionCreateOnClick()
        }else if(this.seasonSummaryRegionsToPatch.size > 0){
            this.patchSeasonSummaryRegions()
        }
    }

    /**
     * Cancell action for the season summary region grid
     */
    seasonSummaryRegionCancelAction(){
        if(this.state.seasonSummaryRegionModified || this.state.newSeasonSummaryRegionCreated){
            this.setState({
                seasonSummaries:this.state.seasonSummariesOriginal,
                seasonSummaryRegionModified: false,
                seasonSummaryPathErrors: {},
                seasonSummaryCreateErrors:{},
                newSeasonSummaryRegionCreated:false,
            })
        }
    }

    /**
     * Handles the editability of controls related to season summary region grid view
     * @param {string} areaId - unique undicatator if the control
     */
    isAreEditable_SeasonSummaryRegion(areaId){
        const officersChecksUnTouched = (!this.state.officerChecksCreateNew
            && !this.state.officerChecksModified)

        if(areaId==='seasonSummaryRegion'){
            return ((this.state.createMode)||(!this.state.licenseIdModified && !this.state.seasonSummaryRegionLineModified 
            && this.state.seasonSummaryRegionLineNewRecords === 0
            && officersChecksUnTouched))
        }else if(areaId==='seasonSummaryRegion_Selection'){
            return (!this.state.seasonSummaryRegionLineModified && this.state.seasonSummaryRegionLineNewRecords === 0 
                && !this.state.newSeasonSummaryRegionCreated)
        }else if(areaId==='seasonSummaryRegion_Save'){
            return (this.state.licenseId && this.state.createMode && (this.state.newSeasonSummaryRegionCreated || this.state.seasonSummaryRegionModified)) || (this.state.licenseId 
                && !this.state.licenseIdModified
                && (this.state.newSeasonSummaryRegionCreated || this.state.seasonSummaryRegionModified)
                && !this.state.seasonSummaryRegionLineModified
                && this.state.seasonSummaryRegionLineNewRecords === 0
                && officersChecksUnTouched)
        }else if(areaId==='seasonSummaryRegion_New'){
            return (this.state.createMode && this.state.licenseId && !this.state.newSeasonSummaryRegionCreated) || (this.state.licenseId && 
                !this.state.newSeasonSummaryRegionCreated 
                && !this.state.licenseIdModified 
                && !this.state.seasonSummaryRegionModified && !this.state.seasonSummaryRegionLineModified
                && this.state.seasonSummaryRegionLineNewRecords === 0
                && officersChecksUnTouched)
        }else if(areaId==='seasonSummaryRegion_Delete'){
            return (this.state.seasonSummaries.length > 0 && (!this.state.licenseIdModified 
                && !this.state.seasonSummaryRegionModified
                && !this.state.newSeasonSummaryRegionCreated 
                && !this.state.seasonSummaryRegionLineModified
                && this.state.seasonSummaryRegionLineNewRecords === 0
                && officersChecksUnTouched))
        }else if(areaId==='seasonSummaryRegion_Cancel'){
            return (this.state.licenseId 
            && !this.state.licenseIdModified
            && (this.state.seasonSummaryRegionModified || this.state.newSeasonSummaryRegionCreated) 
            && this.state.seasonSummaries.length > 0
            && !this.state.seasonSummaryRegionLineModified
            && this.state.seasonSummaryRegionLineNewRecords === 0
            && officersChecksUnTouched)
        }
        else{
            return false;
        }
    }

    /**
     * Save action for season summary region lines data grid
     * Decide whenever create new line or to patch the modified ones
     */
    seasonSummaryRegionLineSaveAction(){
        if(this.state.seasonSummaryRegionLineNewRecords > 0){
            this.createNewSeasonSummaryRegionLines()
        }else if(this.seasonSummaryRegionLinesToPatch.size > 0){
            this.patchSeasonSummaryRegionLines()
        }
    }

    /**
     * Cancell action for the season summary region lines data grid
     */
    seasonSummaryRegionLineCancelAction(){
        // season summary region line was modified - discard all the changes
        if(this.state.seasonSummaryRegionLineModified || this.state.seasonSummaryRegionLineNewRecords > 0){
            this.seasonSummaryRegionLinesNotCreated = []
            this.setState({
                seasonSummaries:this.state.seasonSummariesOriginal,
                seasonSummaryLinesPatchList: {},
                seasonSummaryRegionLineNewRecords:0,
                seasonSummaryLineCreateErrors:[],
                seasonSummaryRegionLineCreationFailed: false,
                seasonSummaryRegionLineModified:false,
            })
        }
    }

    /**
     * Handles the editability of controls related to season summary region line grid view
     * @param {string} areaId - unique undicatator if the control
     */
    isAreaEditable_SeasonSummaryRegionLine(areaId){
        const currentSeasonSummaries = this.state.seasonSummaries[this.state.currentSummaryRegionIdx]
        const officersChecksUnTouched = (!this.state.officerChecksCreateNew
            && !this.state.officerChecksModified)

        if(areaId==='seasonSummaryRegionLines'){
            return (!this.state.licenseIdModified
            && !this.state.seasonSummaryRegionModified 
            && officersChecksUnTouched)
        }else if(areaId==='seasonSummaryRegionLine_Save'){
            return (this.state.seasonSummaryRegionLineModified ||
                this.state.seasonSummaryRegionLineNewRecords > 0) 
                && !this.state.seasonSummaryRegionModified
                && !this.state.newSeasonSummaryRegionCreated
                && !this.state.licenseIdModified
                && officersChecksUnTouched
        }else if(areaId==='seasonSummaryRegionLine_New'){
            return (currentSeasonSummaries && currentSeasonSummaries.id) &&
                !this.state.seasonSummaryRegionModified &&
                !this.state.licenseIdModified &&
                officersChecksUnTouched &&
                (!this.state.seasonSummaryRegionLineModified || this.state.currentSummaryRegionLineIdx < this.state.seasonSummaryRegionLineNewRecords)
        }else if(areaId==='seasonSummaryRegionLine_Delete'){
            return (currentSeasonSummaries &&
                currentSeasonSummaries.season_summary_region_line.length > 0 &&
                (!this.state.seasonSummaryRegionLineModified || this.state.currentSummaryRegionLineIdx < this.state.seasonSummaryRegionLineNewRecords) &&
                !this.state.seasonSummaryRegionModified &&
                officersChecksUnTouched &&
                !this.state.licenseIdModified)
        }else if(areaId==='seasonSummaryRegionLine_Cancel'){
            return (this.state.seasonSummaryRegionLineModified ||
                this.state.seasonSummaryRegionLineNewRecords > 0) 
                && !this.state.seasonSummaryRegionModified
                && !this.state.newSeasonSummaryRegionCreated
                && !this.state.licenseIdModified
                && officersChecksUnTouched
        }else{
            return false;
        }
    }

    /**
     * Delete method for the whole season summary for current fishing license
     */
    deleteSeasonSummary(){
        const deleteFishingLicenseSummary = `${this.state.licenseId}`

        const fishingLicenseSummaryDidDelete = (_response, status) => {
            if(status === 200){
                // to-do
                window.location.href = `/fish-admin/season-summaries/year/${new Date().getFullYear()}/`
            }else{
                this.setState({
                    deleteModalShow:false,
                })
                alert("Něcko se pokazilo, opakujte později")
            } 
        }
        
        apiDeleteFishingLicenseSeasonSummaries(fishingLicenseSummaryDidDelete, deleteFishingLicenseSummary)
    }

    render(){
        return(
            <div className="container licenses-container-wrapper">
                <div className='container mb-3'>
                    <a href={`/fish-admin/season-summaries/year/${new Date().getFullYear()}/`}>{"< všechny sumáře"}</a>
                </div>
                <div className="d-flex justify-content-between mb-3">
                    <span/>
                    <h3>Sumář úlovků</h3>
                    <div className='btn-group'>
                        {!this.state.createMode && 
                        <button 
                            type='button' 
                            className='btn btn-outline-dark person-btn'
                            onClick={(_e) => {
                                this.setState({deleteModalShow:true})
                                }}>
                                <i className="bi bi-trash"></i>Smazat</button>}
                    </div>         
                </div>
                <form method="POST">
                    {/* POVOLENKA */}
                    <div className="row mb-2">
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-2">
                            <b>Povolenka:</b>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-3">
                            <LookupComponent
                                key={`${this.state.fishingLicensesSimple.length}-${this.state.licenseIdLookupRefreshCounter}`}
                                onSelectCallback={this.summaryFishingLicenseSelected}
                                defaultSelected={this.state.fishingLicenseDefaultSelected}
                                data={this.state.fishingLicensesSimple}
                                readOnly={!this.state.createMode}
                                placeholder="Povolenka - držitel"
                                width="200px"/>
                        </div>
                    </div>
        
        
                    {/* REVIRY */}
                    <div className="row">

                        {/* SEASON SUMMARY REGION*/}

                        <div className="col-6">
                            <div className="d-flex justify-content-left">
                                <h5>Navštívené revíry</h5>
                            </div>

                            {/* Menu for season summary regions */}
                            <ActionMenu
                                saveCallback={this.seasonSummaryRegionSaveAction}
                                saveBtnName='seasonSummaryRegion_Save'
                                newCallback={this.seasonSummaryRegionNewOnClick}
                                newBtnName={'seasonSummaryRegion_New'}
                                deleteCallback={(_e) => {this.setState({showDeleteSeasonSummaryRegionModal:true})}}
                                deleteBtnName={'seasonSummaryRegion_Delete'}
                                cancelCallback={this.seasonSummaryRegionCancelAction}
                                cancelBtnName={'seasonSummaryRegion_Cancel'}
                                enabledCallback={this.isAreEditable_SeasonSummaryRegion}/>
              
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="row">#</th>   
                                        <th scope="col">Revír</th>
                                        <th scope="col">Docházek</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.seasonSummaries.map((seasonSummary, rowIdx) => (
                                        // Season summary region rows retrieved from server
                                        <tr key={`${seasonSummary.id}-${rowIdx}-${this.state.regionsSimpleMap.length}-${this.state.seasonSummaries.length}-${seasonSummary.region}-${this.state.regionsSimpleLoadedCount}`}>
                                            <th style={{width:"5%"}}>
                                                <input 
                                                    className="form-check-input checkbox-circle" type="checkbox" 
                                                    checked={this.state.currentSummaryRegionIdx === rowIdx}
                                                    disabled={(!this.isAreEditable_SeasonSummaryRegion('seasonSummaryRegion_Selection'))}
                                                    onChange={(_e) => {
                                                        this.setState({
                                                            currentSummaryRegionIdx:rowIdx,
                                                            currentSummaryRegionLineIdx:0,
                                                        })
                                                    }}/>
                                            </th>
                                            <td style={{width:"70%"}}>
                                                <LookupComponent
                                                    key={`${rowIdx}-${seasonSummary.region}-${this.state.regionsSimpleMap.length}`}
                                                    rowIdx={rowIdx}
                                                    onSelectCallback={this.seasonSummaryRegionSelected}
                                                    defaultSelected={{
                                                        id:seasonSummary.region, 
                                                        value:this.state.regionsSimpleMap[seasonSummary.region] ? this.state.regionsSimpleMap[seasonSummary.region].number_name : ""
                                                    }}
                                                    readOnly={(!this.isAreEditable_SeasonSummaryRegion('seasonSummaryRegion')) || (this.state.newSeasonSummaryRegionCreated && rowIdx > 0)}
                                                    data={this.state.regionsSimple}
                                                    placeholder="Revír"/>
                                            </td>
                                            <td style={{width:"17%"}}>
                                                <input
                                                    type="number" 
                                                    className="form-control" 
                                                    disabled={!this.isAreEditable_SeasonSummaryRegion('seasonSummaryRegion') || (this.state.newSeasonSummaryRegionCreated && rowIdx > 0)}
                                                    value={seasonSummary.attendances ? seasonSummary.attendances: 0}
                                                    onChange={(e) => this.seasonSummaryRegionFieldChange(rowIdx, 'attendances', e.target.value)}
                                                    /> 
                                            </td>
                                        </tr> 
                                    ))}
                                </tbody>
                            </table>

                            {/* Errors of patching season summaries records */}
                            <MultiLineDictErrorListComponent 
                                data={this.state.seasonSummaries}
                                keyColumns={["region"]}
                                errorDict={this.state.seasonSummaryPathErrors}
                                labels={{
                                    "region": "Revír",
                                    "__all__": "Obecné",
                                    "non_field_errors": "Obecné",
                                }}
                                searchMap={Object.assign({}, ...this.state.seasonSummaries.map((summary, idx) => ({[summary.id]: idx})))}
                                foreignColumns={{
                                    "region":{
                                        "sourceMap":this.state.regionsSimpleMap,
                                        "keys":['number_name']
                                    }
                                }}
                            />
                            
                            {/* errors for the new season summary region */}
                            <DictErrorListComponent
                                labels={{
                                    'region': "Revír",
                                    'attendances': "Docházek",
                                    "__all__": "Obecné",
                                    "non_field_errors": "Obecné",
                                }}
                                errorDict={this.state.seasonSummaryCreateErrors}
                            />

                            {/* Remove season summary modal */}
                            <ModalDialog
                                show={this.state.showDeleteSeasonSummaryRegionModal}
                                message="Smazat?"
                                subMessage="Opravdu smazat údaje o revíru v sumáři úlovků?"
                                onBtn1={(e) => {
                                    if(this.state.newSeasonSummaryRegionCreated===true){
                                        const newSeasonSummaries = this.state.seasonSummaries
                                        newSeasonSummaries.shift()

                                        this.setState({
                                            seasonSummaries:newSeasonSummaries,
                                            newSeasonSummaryRegionCreated:false,
                                            showDeleteSeasonSummaryRegionModal:false,
                                            currentSummaryRegionIdx:0,
                                            seasonSummaryCreateErrors:[],
                                        })
                                        
                                    }else{
                                        this.setState({
                                            showDeleteSeasonSummaryRegionModal:false
                                        })

                                        let seasonSummaryRegionDidDelete = (_response, status) => {
                                            if(status === 204){
                                                // do not keep position after deletion
                                                this.keepPosition = false
                                                this.loadLicenseDetails()
                                            }else{
                                                alert("Něco se pokazilo, opakujte později.")
                                            }
                                        }

                                        apiDeleteSeasonSummary(seasonSummaryRegionDidDelete, this.state.seasonSummaries[this.state.currentSummaryRegionIdx].id)
                                    }

                                }}
                                onBtn2={(e) => {
                                    this.setState({
                                        showDeleteSeasonSummaryRegionModal:false
                                    })
                                }}/>
                        </div>

                        {/* SEASON SUMMARY REGION LINES*/}
                        <div className="col-6">
                            <div className="d-flex justify-content-left">
                                <h5>Úlovky na revíru</h5>
                            </div>

                            <ActionMenu 
                                saveCallback={this.seasonSummaryRegionLineSaveAction}
                                saveBtnName='seasonSummaryRegionLine_Save'
                                newCallback={this.seasonSummaryRegionLineNewOnClick}
                                newBtnName={'seasonSummaryRegionLine_New'}
                                deleteCallback={(_e) => {this.setState({showDeleteSeasonSummaryRegionLineModal:true})}}
                                deleteBtnName={'seasonSummaryRegionLine_Delete'}
                                cancelCallback={this.seasonSummaryRegionLineCancelAction}
                                cancelBtnName={'seasonSummaryRegionLine_Cancel'}
                                enabledCallback={this.isAreaEditable_SeasonSummaryRegionLine}
                            />

                            {/* Season summary region lines grid*/}
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="row">#</th>   
                                        <th scope="row">Druh</th>   
                                        <th scope="col">Počet</th>
                                        <th scope="col">Váha(kg)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.currentSummaryRegionIdx >= 0 && this.state.seasonSummaries.length > 0 && this.state.seasonSummaries[this.state.currentSummaryRegionIdx].season_summary_region_line.map((seasonSummaryRegionLine, idx) => (
                                        <tr key={`${idx}-${this.state.seasonSummaries.length}-${seasonSummaryRegionLine.spiecies}-${this.state.spiecies.length}`}>
                                            <th style={{width:"5%"}}>
                                                <input 
                                                    className={this.state.seasonSummaryRegionLineCreationFailed && idx < this.state.seasonSummaryRegionLineNewRecords ? "form-check-input checkbox-circle border-danger" : "form-check-input checkbox-circle"}
                                                    type="checkbox" 
                                                    checked={this.state.currentSummaryRegionLineIdx === idx}
                                                    onChange={(_e) => {
                                                        this.setState({
                                                            currentSummaryRegionLineIdx:idx    
                                                        })
                                                    }}/>
                                            </th>
                                            <td style={{width:"58%"}}>
                                                <LookupComponent
                                                    key={`${idx}-${this.state.spiecies.length}-${seasonSummaryRegionLine.spiecies}`}
                                                    rowIdx={idx}
                                                    onSelectCallback={this.seasonSummaryRegionLineSpieciesSelected}
                                                    error={this.state.seasonSummaryRegionLineCreationFailed && idx < this.state.seasonSummaryRegionLineNewRecords}
                                                    defaultSelected={{
                                                        id:seasonSummaryRegionLine.spiecies, 
                                                        value:this.state.spieciesMap[seasonSummaryRegionLine.spiecies] ? this.state.spieciesMap[seasonSummaryRegionLine.spiecies].name : ""
                                                    }}
                                                    readOnly={!this.isAreaEditable_SeasonSummaryRegionLine('seasonSummaryRegionLines') 
                                                                || (idx >= this.state.seasonSummaryRegionLineNewRecords 
                                                                    && this.state.seasonSummaryRegionLineNewRecords > 0)}
                                                    data={this.state.spiecies}
                                                    placeholder="Druh"/>
                                            </td>
                                            <td style={{width:"17%"}}>
                                                <input
                                                    type="number" 
                                                    className={this.state.seasonSummaryRegionLineCreationFailed && idx < this.state.seasonSummaryRegionLineNewRecords ? "form-control border-danger" : "form-control"}
                                                    disabled={!this.isAreaEditable_SeasonSummaryRegionLine('seasonSummaryRegionLines') 
                                                                || (idx >= this.state.seasonSummaryRegionLineNewRecords 
                                                                    && this.state.seasonSummaryRegionLineNewRecords > 0)}
                                                    value={seasonSummaryRegionLine.amount ? seasonSummaryRegionLine.amount: 0}
                                                    onChange={(e) => this.seasonSummaryRegionLineFieldChange(idx, 'amount', e.target.value)}
                                                    /> 
                                            </td>
                                            <td style={{width:"17%"}}>
                                                <input
                                                    type="number" 
                                                    className={this.state.seasonSummaryRegionLineCreationFailed && idx < this.state.seasonSummaryRegionLineNewRecords ? "form-control border-danger" : "form-control"}
                                                    step='0.01'
                                                    disabled={!this.isAreaEditable_SeasonSummaryRegionLine('seasonSummaryRegionLines') 
                                                                || (idx >= this.state.seasonSummaryRegionLineNewRecords 
                                                                    && this.state.seasonSummaryRegionLineNewRecords > 0)}
                                                    value={seasonSummaryRegionLine.weight ? seasonSummaryRegionLine.weight: 0}
                                                    onChange={(e) => this.seasonSummaryRegionLineFieldChange(idx, 'weight', e.target.value)}
                                                    /> 
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        
                            {/* Errors for the new lines */}
                            <ErrorListComponent
                                errors={this.state.seasonSummaryLineCreateErrors}/>

                            {/* Remove season summary line modal */}
                            <ModalDialog
                                show={this.state.showDeleteSeasonSummaryRegionLineModal}
                                message="Smazat?"
                                subMessage="Opravdu smazat údaje o úlovku v sumáři úlovků?"
                                onBtn1={(e) => {
                                    if(this.state.seasonSummaryRegionLineNewRecords > 0){
                                        const currentSeasonSummaries = JSON.parse(JSON.stringify(this.state.seasonSummaries))
                                        const newSeasonSummaries = currentSeasonSummaries[this.state.currentSummaryRegionIdx]
                                        newSeasonSummaries.season_summary_region_line.splice(this.state.currentSummaryRegionLineIdx, 1)
                                        const regionLineFailedExists = this.state.seasonSummaryRegionLineCreationFailed && this.state.seasonSummaryRegionLineNewRecords - 1 > 0
                                        
                                        this.setState({
                                            seasonSummaries:currentSeasonSummaries,
                                            showDeleteSeasonSummaryRegionLineModal:false,
                                            currentSummaryRegionLineIdx:0,
                                            seasonSummaryRegionLineNewRecords: this.state.seasonSummaryRegionLineNewRecords - 1,
                                            seasonSummaryRegionLineCreationFailed: regionLineFailedExists,
                                            seasonSummaryLineCreateErrors: regionLineFailedExists ? this.state.seasonSummaryLineCreateErrors : []
                                        })
                                        
                                    }else{
                                        this.setState({
                                            showDeleteSeasonSummaryRegionLineModal:false
                                        })

                                        let seasonSummaryRegionLineDidDelete = (_response, status) => {
                                            if(status === 204){
                                                // do not keep position after deletion
                                                this.keepPosition = false
                                                this.loadLicenseDetails()
                                            }else{
                                                alert("Něco se pokazilo, opakujte později.")
                                            }
                                        }
                                        
                                        // delete just when exists
                                        const currentSeasonSummaries = this.state.seasonSummaries[this.state.currentSummaryRegionIdx]
                                        
                                        if(currentSeasonSummaries && currentSeasonSummaries.season_summary_region_line.length > 0){
                                            const currentSeasonSummaryRegionLine = currentSeasonSummaries.season_summary_region_line[this.state.currentSummaryRegionLineIdx]

                                            if(currentSeasonSummaryRegionLine){
                                                apiDeleteSeasonSummaryRegionLine(seasonSummaryRegionLineDidDelete, currentSeasonSummaryRegionLine.id)
                                            }
                                        }
                                    }

                                }}
                                onBtn2={(e) => {
                                    this.setState({
                                        showDeleteSeasonSummaryRegionLineModal:false
                                    })
                                }}/>

                        </div>
                    </div>         
                    
                    {/* dialog to remove whole season summary*/}
                    <ModalDialog
                        show={this.state.deleteModalShow}
                        message="Smazat?"
                        subMessage="Opravdu smazat celý sumář úlovků a odpovídající kontroly ryb. stráží?"
                        onBtn1={(_e) => this.deleteSeasonSummary()}
                        onBtn2={(_e) => {
                            this.setState({
                                deleteModalShow:false
                            })
                        }}/>

                </form>
            </div>
        )
    }


}
import GridViewComponent from "../../gridview/GridViewComponent"

import { 
    apiLookupPersons, 
    apiDeletePersons,

} from "./actions";

export default class PersonGridViewComponent extends GridViewComponent{
    
    constructor(props){
        super(props)

        this.todaysDate = new Date()

        this.personFishingTicketContraintMet = this.personFishingTicketContraintMet.bind(this)
        this.loadData = this.loadData.bind(this)
    }

    personFishingTicketContraintMet(toDateStr){
        if(!toDateStr){
            return false
        }
        const strDateSplit = toDateStr.split("-")
        if(strDateSplit.length > 0){
            const endDate = new Date(parseInt(strDateSplit[0]), parseInt(strDateSplit[1]) -1, parseInt(strDateSplit[2]))
            return !(endDate.getFullYear() === this.todaysDate.getFullYear() || endDate.getFullYear() < this.todaysDate.getFullYear())
        }
        return false
    }

    gridDeleteAction(){
        let personsDidDeleted = (response, status) => {
            if(status === 200){
                this.loadData()
            }else if(status === 400){
                alert(response.message)
            }else if(status === 500){
                alert("Něco se pokazilo, opakujte později.")
            }
        }
        
        var personsIds = new Set()
        for(let idx of this.state.selectedDataIdxs){
            personsIds.add(this.state.data[idx].id)
        }
        const personsToDelte = [...personsIds].join(",")

        this.setState({
            showDeleteModal:false
        })

        apiDeletePersons(personsDidDeleted, personsToDelte)
    }

    gridNewAction(){
        window.location.href = "/fish-admin/persons/create/"
    }

    gridRowHref(row){
        return `/fish-admin/persons/${row.id}`
    }

    loadData(){
        let personsDidFetch = (response, status) => {
            if(status === 200){
                this.state.selectedDataIdxs.clear()
                this.setState({
                    data:response,
                    columns: this.state.columns.length > 0 ? this.state.columns : [
                        {
                            "name": "full_name",
                            "label": "Jméno",
                            "type": this.SEARCHTYPETEXT
                        },
                        {
                            "name": "born",
                            "label": "Datum narození",
                            "alias": "born_formated",
                            "type": this.SEARCHTYPEDATE
                        },
                        {
                            "name": "age_category_verbose",
                            "label": "Věková kategorie",
                            "type": this.SEARCHTYPETEXT
                        },
                        {
                            "name": "email",
                            "label": "E-mail",
                            "type": this.SEARCHTYPETEXT
                        },
                        {
                            "name": "fishing_ticket_end_date",
                            "label": "Ryb. lístek do",
                            "alias": "fishing_ticket_end_date_formated",
                            "contraintFnc": this.personFishingTicketContraintMet,
                            "type": this.SEARCHTYPEDATE
                        },
                    ],
                    header: this.state.header ? this.state.header : "Všichni členové",
                    hrefColumn: this.state.hrefColumn ? this.state.hrefColumn : "full_name",
                    searchColumn: this.state.searchColumn === "" ? 0 : this.state.searchColumn,
                })
            }
        }

        apiLookupPersons(personsDidFetch)
    }

    componentDidMount(){
        this.loadData()
    }
}
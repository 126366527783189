import {backendRequest} from '../../backend'

export function apiLookupRegions(callback){
    return backendRequest("GET", '/regions/', callback, {})
}

export function apiLookupRegionsTrout(callback){
    return backendRequest("GET", '/regions/trout/', callback, {})
}

export function apiLookupRegionsNonTrout(callback){
    return backendRequest("GET", '/regions/ntrout/', callback, {})
}

export function apiDeleteMultipleRegions(callback, data){
    return backendRequest("POST", '/regions/delete/', callback, data)
}
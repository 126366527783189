import {backendRequest} from '../backend/'

export function apiQuestionsLookup(callback){
    return backendRequest("GET", '/questions/questions/', callback, {})
}

export function apiQuestionsPageLookup(callback, endpoint){
    backendRequest("GET", endpoint.replace('http://127.0.0.1:8000/api', ''), callback, {})    
}

export function apiQuestionLookup(callback, questionId){
    return backendRequest("GET", `/questions/questions/${questionId}/`, callback, {})
}

export function apiQuestionCreate(callback, data){
    return backendRequest("POST", '/questions/questions/', callback, data)
}

export function apiQuestionDelete(callback, questionId){
    return backendRequest("DELETE", `/questions/questions/${questionId}/`, callback, {})
}

export function apiQuestionPatch(callback, questionId, data){
    return backendRequest("PATCH", `/questions/questions/${questionId}/`, callback, data)
}

export function apiAnswerDelete(callback, answerId){
    return backendRequest("DELETE", `/questions/answers/${answerId}/`, callback, {})
}

export function apiAnswerCreate(callback, data, questionId){
    return backendRequest("POST", `/questions/questions/${questionId}/respond/`, callback, data)
}

export function apiQuestionsPageCount(callback){
    return backendRequest("GET", '/questions/questions/page_count/', callback)
}


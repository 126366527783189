import React from 'react'

import {
    apiLicensesSellYearLookup,
    apiLicensesSellDatesLookup,
    apiLicensesSellMemberTaxesLookup,
    apiLicensesSellPricesLookup
} from '../actions'

export default class LicensesSellMaintainComponent extends React.Component{
    constructor(props){
        super(props)

        this.state = ({
            sellYear: 0,
            sellDates: [],
            sellPrices: [],
            memberTaxes: []
        })

        // binding
        this.lookupSellYear = this.lookupSellYear.bind(this)
        this.lookupSellDates = this.lookupSellDates.bind(this)
        this.lookupMemberTaxes = this.lookupMemberTaxes.bind(this)
        this.lookupSellPrices = this.lookupSellPrices.bind(this)
    }

    lookupSellYear(){
        const sellYearDidLoad = (response) => {
            if(response !== null){
                this.setState({
                    sellYear: response[0]
                })
            }
        }
        apiLicensesSellYearLookup(sellYearDidLoad)
    }

    lookupSellDates(){
        const sellDatesDidLoad = (response) => {
            if(response !== null){
                this.setState({
                    sellDates:response
                })
            }
        }
        apiLicensesSellDatesLookup(sellDatesDidLoad)
    }

    lookupMemberTaxes(){
        const memberTaxesDidLoad = (response) => {
            if(response !== null){
                this.setState({
                    memberTaxes: response
                })
            }
        }
        apiLicensesSellMemberTaxesLookup(memberTaxesDidLoad)
    }

    lookupSellPrices(){
        const sellPricesDidLoad = (response) => {
            if(response !== null){
                this.setState({
                    sellPrices:response
                })
            }
        }
        apiLicensesSellPricesLookup(sellPricesDidLoad)
    }

    render(){
        return(
            <div>
                hello
            </div>
        )
    }
}
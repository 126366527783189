import React from "react"
import '../../css/modal.css'

export default class ModalDialog extends React.Component {

    constructor(props){
        super(props)

        this.state = ({
            showError:false
        })

        this.sellYear = this.props.sellYear ? this.props.sellYear : 0
        this.closeOk = this.closeOk.bind(this)
        this.closeCancell = this.closeCancell.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
    }

    closeOk(){
        if(this.validateDateInput() === true){
            this.setState({
                showError:false 
            })
            this.props.onBtn1(this.sellYear)
        }else{
            this.setState({
                showError:true
            })
        }
    }

    closeCancell(){
        this.setState({
            showError:false
        })
        this.props.onBtn2()
    }

    handleDateChange(sender){
        this.sellYear = sender.target.value
    }

    validateDateInput(){
        return this.sellYear !== "" && this.sellYear > 0
    }

    render() {
        if(!this.props.show){
            return null
        }else{
            return (
                <div className="my-modal">
                    <div className="modal-content-small">
                        <div className='d-flex justify-content-center'>
                            <h3>{this.props.title ? this.props.title : "Prodejní rok"}</h3>
                        </div>
                        <form>
                            <div className="form-group mt-3">
                                <div className="d-flex justify-content-center">            
                                    <input 
                                        className="form-control sell-date-input" 
                                        type="number" 
                                        min="0" 
                                        defaultValue={this.props.sellYear ? this.props.sellYear : 0}
                                        onChange={this.handleDateChange} />    
                                </div>     
                            </div>
                        </form>
                        {this.state.showError &&
                        <div className="d-flex justify-content-center mt-3" role="alert">
                            <p className="alert alert-danger">Neplatný rok!</p>
                        </div>
                        }
                        <div className='d-flex justify-content-center py-2'>
                            <div className="btn-group" role="group">
                                <button 
                                    className='btn btn-success'
                                    type='button'
                                    onClick={this.closeOk}>Uložit</button>
                                <button 
                                    className={this.props.btn2ClassName ? this.props.btn2ClassName : 'btn btn-outline-secondary'}
                                    type='button' 
                                    onClick={this.closeCancell}>Zrušit</button>
                            </div>
                        </div>
                    </div>                    
              </div>)
        }
    }
}
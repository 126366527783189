import React, { useEffect, useState } from 'react';
import {
    apiLookupSpeciesDetail,
    apiCreateSpecies,
    apiPatchSpecies,
    apiDeleteSpecies,
} from './actions'
import DictErrorListComponent from "../../global/components/error_components/DictErrorListComponent";
import ModalDialog from "../../modal/components/ModalDialog";

/*
    Component to show list of all fishing spiecies
    when spiecies id is not found, create new species, when it's found, adjust existing
*/
export function SpieciesDetail(props){
   
    // Setting the state variables
    const [spieciesId, setSpieciesId] = useState(props.spieciesId)
    const [showDelete, setShowDelete] = useState(false)
    const [modified, setModified] = useState(false)
    const [speciesObject, setSpeciesObject] = useState({})
    const [speciesObjectOriginal, setSpeciesObjectOriginal] = useState({})
    const [errors, setErrors] = useState({})

    // useEffect hook - same as component did mount / component did modify
    // I want to this be called when species id is changed
    useEffect(() => {
        if(spieciesId){
            apiLookupSpeciesDetail(speciesDetailDidLoaded, spieciesId)
        }
    },[spieciesId])

    /**
     * Callback function called when response with species details is retrieved from server
     * @param {*} response 
     * @param {*} status 
     */
    const speciesDetailDidLoaded = (response, status) => {
        if(status===200){
            setSpeciesObject(response)
            setSpeciesObjectOriginal(JSON.parse(JSON.stringify(response)))
        }else if(status===404){
            setSpeciesObject({})
            setSpeciesObjectOriginal({})
            setModified(false)
        }else{
            alert("Něco se pokazilo, opakujte později")
        }
    }

    /**
     * Called when cancell button is clicked
     */
    const handleCancel = () => {
        setSpeciesObject(speciesObjectOriginal)
        setModified(false)
        setErrors({})
    }

    /**
     * Called when save button is clicked
     */
    const handleSave = () => {

        const speciesDidPatched = (response, status) => {
            if(status===200){
                setSpeciesObject(response)
                setModified(false)
                setSpeciesObjectOriginal(JSON.parse(JSON.stringify(response)))
            }else if(status===400){
                setErrors(response)
            }else{
                alert("Něco se pokazilo, opakujte později")
            }
        }

        const speciesDidCreated = (response, status) => {
            if(status===201){
                setSpeciesObject(response)
                setSpeciesObjectOriginal(JSON.parse(JSON.stringify(response)))
                setSpieciesId(response.id)
                setModified(false)
                setErrors({})
            }else{
                setErrors(response)
            }
        }

        if(spieciesId){
            apiPatchSpecies(speciesDidPatched, spieciesId, speciesObject)
        }else{
            apiCreateSpecies(speciesDidCreated, speciesObject)
        }
    }

    /**
     * Callen when some value in the species object is modified
     * @param {*} fieldName - name of the modified field
     * @param {*} value  - new field value
     */
    const speciesValueModified = (fieldName, value) => {
        const currSpeciesObject = JSON.parse(JSON.stringify(speciesObject))
        currSpeciesObject[fieldName] = value
        setSpeciesObject(currSpeciesObject)
        setModified(true)
    }

    /**
     * Performed when delete modal dialog is confirmed
     */
    const handleDeleteAction = () => {
        const speciesDidDeleted = (response, status) => {
            if(status === 204){
                setSpieciesId(null)
                setSpeciesObject({})
                setModified(false)
                setSpeciesObjectOriginal({})
                window.location.href = '/fish-admin/spiecies/'    
            }else{
                alert("Něco se pokazilo, opakujte později")
            }
        }

        setShowDelete(false)
        apiDeleteSpecies(speciesDidDeleted, spieciesId)
    }
    
    return(
        <div className="container licenses-container-wrapper">
            <div className='container mb-3'>
                <a href='/fish-admin/spiecies/'>{"< všechny druhy"}</a>
            </div>
            <div className="d-flex justify-content-between mb-3">
                <span/>
                {spieciesId && <h3>Druh</h3>}
                {!spieciesId && <h3>Nový druh</h3>}
                
                <div className="person-header-btn-group">
                    <div className='btn-group'>
                        {spieciesId && 
                        <button type='button' className='btn btn-outline-danger person-btn'
                            onClick={(_e)=>(setShowDelete(true))}>Smazat</button>}
                        {modified &&
                        <button type='button' className='btn btn-outline-danger person-btn'
                            onClick={(_e)=>(handleCancel())}>Zrušit</button>}
                        {(modified || (modified && !spieciesId)) &&
                        <button type='button' className='btn btn-outline-dark person-btn'
                            onClick={(_e)=>(handleSave())}>Uložit</button>}
                    </div>
                </div>
            </div>

            <form method="POST">
                <div className="container officers-form">
                    <div className="mb-3">
                        <b>Název druhu</b>
                        <input type="text" className="form-control"
                            value={speciesObject.name ? speciesObject.name : ''}
                            onChange={(e) => (speciesValueModified('name', e.target.value))}/>
                        <DictErrorListComponent
                            labels={{
                                'name': "Název druhu",
                                "__all__": "Obecné",
                                "non_field_errors": "Obecné",
                            }}
                            errorDict={errors}
                        />
                    </div>
                </div>
            </form>

            <ModalDialog
                show={showDelete}
                message="Smazat"
                subMessage="Opravdu chcete smazat tento druh?"
                onBtn1={handleDeleteAction}
                onBtn2={() => {
                    setShowDelete(false)
                }}
                />

        </div>
    )
}
import {backendRequest} from '../backend/'

export function apiLicensesSellMemberTaxesAgeCategoriesLookup(callback){
    return backendRequest("GET", '/global/member-taxes-age-categories/', callback, {})
}

export function apiCurrencyLookup(callback){
    return backendRequest("GET", '/global/currency/', callback, {})
}

export function apiLicensesTypesLookup(callback){
    return backendRequest("GET", '/global/licenses-types/', callback, {})
}

export function apiLicensesAgeCategoriesLookup(callback){
    return backendRequest("GET", '/global/licenses-age-categories/', callback, {})
}

export function apiLookupAgeCategories(callback){
    return backendRequest("GET", '/global/age-categories/', callback, {})
}

export function apiLookupSexOptions(callback){
    return backendRequest("GET", '/global/sex-options/', callback, {})
}

export function apiLookupIssuingOrganizations(callback){
    return backendRequest("GET", '/fish-admin/issuing-organizations/', callback, {})
}

export function apiGetFishingLicenseMap(callback){
    return backendRequest("GET", '/global/license-type-map/', callback, {})
}
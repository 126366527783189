import React from 'react'
import {NewsElement} from './NewsElement'

export function NewsListV2(props){
    const {news, isAuth, onDelete, onModify} = props

    if(news)
    {
        return (
            <div>  
                {news.map((newsElement, index) => (
                    <NewsElement 
                        newsElement={newsElement}
                        key={`${index}-${newsElement.id}`}
                        isAuth={isAuth}
                        onDelete={onDelete}
                        onModify={onModify}/>))}
            </div>
        )
    }
    else
    {
        return null
    }
}